import React,{useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
// import { useNavigate } from 'react-router-dom';

const  CybersecurityForm = () => {
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [RigistrationForm , setRigistrationFrom] =useState(false)
    const [enquiryPay ,setenquiryPay] =useState(false)
   
        // const navigate = useNavigate();
      
    // const moveToNextPage = () => {
    //     navigate('/cyberpage');
    //   };
      
    const [formData, setFormData] = React.useState(
        {
            first_name: "",
            email:"",
            phone_no:"",
            institute:"",
            city:"",
            states:"",
            role:"student",
            branch:"",
            session:""
            
        }
    )

    const [enquiryData, setenquiryData] = React.useState({
        name: '',
        mobile:'',
        email: '',
        institute:'',
        city:'',
        state:'',
      });
   
    //form data end 
    const dataChange = (event) => {
        setenquiryData((prevenquiryForm) => {
          return {
            ...prevenquiryForm ,
            [event.target.name]: event.target.value
          };
        });
    };
    //enquiry API 
    const dataSubmiting = async (e) => {
        e.preventDefault();
      
        try {
          const response = await fetch(`https://erp.campusbeez.com/api/webservice/contactus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(enquiryData), // Send the form data as JSON
          });
      
          console.log('Response:', response); // Debugging statement
      
          if (response.ok) {
            // API request was successful, you can handle the response here
            const data = await response.json();
            console.log('API Response Data:', data); // Debugging statement
      
            if (data.status === "1") {
              // Display a success toast notification
              toast('Thank you ! Enquiry Form submitted'); // You can customize the toast message
              setenquiryData(
                {
                    name: '',
        mobile:'',
        email: '',
        institute:'',
        city:'',
        state:'',
                    
                }
            );
        
             
            } else {
              // Display an error toast notification
              toast('Something went wrong'); // You can customize the toast message
            }
          } else {
            // Handle API request error (e.g., display an error message)
            console.error('API request error:', response.statusText);
          }
        } catch (error) {
          // Handle any network errors (e.g., connection issues)
          console.error('Network error:', error);
        }
      };
      
      
    //end enquiry API
   


  
    const formPage = (event) => {
       
        const selectedValue = event.target.value;
        if (selectedValue !== 'select') {
            setRigistrationFrom(true);
          }
          else{
            setRigistrationFrom(false);
            setFormData(
                {
                    first_name: "",
                    email:"",
                    phone_no:"",
                    institute:"",
                    city:"",
                    states:"",
                    role:"student",
                    branch:"",
                    session:""
                    
                }
            )
          }

      };
    //   const toggleRegForm = () => {
    //     setRegFrom(false);
    //   
    const handleChange = (event) => {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            [event.target.name]: event.target.value
          };
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          const response = await fetch(`${process.env.REACT_APP_RESUME_URL}/register`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data as JSON
          });
      
          if (response.ok) {
            // API request was successful, you can handle the response here
            const data = await response.json();
            if (data.status === 1) {
                 setenquiryPay(true)
                // Display a success toast notification
                toast('ThankYou For Your Registrastion'); // You can customize the toast message
                setFormData(
                    {
                        first_name: "",
                        email:"",
                        phone_no:"",
                        institute:"",
                        city:"",
                        states:"",
                        role:"student",
                        branch:"",
                        session:""
                        
                    }
                );
            
              }
            else {
                // Display an error toast notification
                toast('Something Else Wrong'); // You can customize the toast message
                }
          }
          
          
          
          else {
            // Handle API request error (e.g., display an error message)
            console.error('API request error:', response.statusText);
          }
        } catch (error) {
          // Handle any network errors (e.g., connection issues)
          console.error('Network error:', error);
        }
      };
      
      

  return (
    <div className='md:mt-32'>
       <Helmet>
        
        <meta charset="utf-8" />
        <title>Campusbeez | Programs, Cyber Security, Ethical Hacking Workshop,Cyber safety,Cyber safety tips,Ethical hacking tutorials</title>
        <meta name="description" content="Are you looking for best institute in cyber security? Cyber crime awareness society providing the best course in cyber security in jaipur and delhi. cyber security course, cyber security training, learn cyber security."/>
        <meta name="keywords" content="cyber institute, cyber course, cyber training, cyber coaching, cyber center, cyber learn, cyber  tuition, security institute, security course, security training, security coaching, security center, security learn, security tuition"/>
        <meta name="robots" content="index,follow"/>
        <meta name="copyright" content="Livelake Education"/>
        <meta name="email" content="support@campusbeez.com"/>
        <meta name="language" content="EN"/>
        <meta name="DC.Publisher" content="Campusbeez"/>
        <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
        <meta name="DC.Language" content="en"/>
        <meta name="Distribution" content="Global"/>
        <meta name="geo.region" content="IN"/>
        <link rel="canonical" href="https://campusbeez.com/"/>
        <meta name="geo.placename" content="India"/>
        <meta name="geo.position" content="Gurgaon"/>
        <meta name="geo.region" content="usa"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>

    </Helmet>
     <div className='grid grid-cols-2'>
        <div className=' p-6 h-auto'>
        <div className=' bg-slate-100 p-5 rounded-lg'>    
        <form>

    <h1 className=' text-center text-2xl font-bold pb-6'>
       Enquiry Form (Institution Only)
    </h1>
    <div className="mb-6">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
    <input type="text" id="fullName" name='name' value={enquiryData?.name}  onChange={dataChange}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name" required/>
  </div>
   
  <div className="mb-6">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
    <input type="email" id="email" name='email' value={enquiryData?.email} onChange={dataChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@flowbite.com" required/>
  </div>
  <div className="mb-6">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile No.</label>
    <input type="number" id="number" name='mobile'  value={enquiryData?.mobile} onChange={dataChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Number" required/>
  </div>
  <div className="mb-6">
    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Institutes</label>
    <input type="text" id="institute" name='institute' value={enquiryData?.institute} onChange={dataChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your College/Schools" required/>
  </div>
  <div className='grid grid-cols-2 gap-3'>
  <div className="mb-6">
    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">City</label>
    <input type="text" id="city" name='city'  value={enquiryData?.city} onChange={dataChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="Your City" />
  </div>
  <div className="mb-6">
    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">States</label>
    <input type="text" id="state" name='state'  value={enquiryData?.state} onChange={dataChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="Your States" />
  </div>
  </div>

     <div className="flex items-start">
     </div>
     <button type="submit" name='submit' onClick={dataSubmiting }   className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
        </div>
        </div>



       
      <div className='p-6'>
    
        <div className='bg-slate-100 rounded-lg'>
        <h1 className=' text-2xl font-bold text-center pt-4'> Registration Form (For Students Only)</h1>
       <div className='flex gap-8 justify-center h-32 '>  
       <div className='pt-10 text-2xl font-bold '>
       Select Colleges &rarr;
       </div>
       

       <div className="mt-8">
      <select className='w-72 p-3 rounded-xl' onChange={formPage}>
        <option value="select">Select Here</option>
        <option value="formPage">Registration</option>
        <option value="option1">CampusBeez</option>
        <option value="option2">Schools Duniya</option>
        <option value="option3">LiveLake Education</option>
      </select>
      </div>
      </div>
    
        
        </div>
   


        {RigistrationForm && (
        <div className=' bg-slate-100 p-5'>
       <form>
    {/* <h1 className=' text-center text-2xl font-bold pb-6 rounded-lg'>
     Registration Form
    </h1> */}
    <div className="mb-6">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
    <input type="text" id="email" name='first_name' value={formData?.first_name}  onChange={handleChange}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name" required/>
  </div>
   
  <div className="mb-6">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
    <input type="email" id="email" name='email' value={formData?.email} onChange={handleChange}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@Compusbeez.com" required/>
  </div>
  <div className="mb-6">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile No.</label>
    <input type="number" id="number"  name='phone_no' value={formData?.phone_no} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Number" required/>
  </div>
  <div className="mb-6">
    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Branch</label>
    <input type="text" id="branch"  name='branch'  value={formData?.branch} onChange={handleChange}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your College/Schools" required/>
  </div>
  <div className="mb-6">
    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Session</label>
    <input type="text" id="session"  name='session' value={formData?.session} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required/>
  </div>
  
  <div className="flex items-start">
   </div>
  <button type="submit"  onClick={handleSubmit} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
  {
    enquiryPay && (
       <>
     <div className='text-center '>
     <div className='container bg-slate-100 pt-8 flex'>
        <div className='text-gray-900 text-xl font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>Payment</div>
        <h1 className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>2999</h1> 
     </div>
     <div className=''>
     <button type="submit"  className="text-white mt-4 mr-72 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">PayNow</button>
     </div>
     </div>
     </>
    )  
    
  }
      </form>
             </div>
              )}
        </div>

        </div>
        <ToastContainer />
     </div>
       

  )
}

export default  CybersecurityForm