import React from 'react';
import { FaGraduationCap, FaCogs, FaLaptop, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import {BiPhoneCall } from 'react-icons/bi';
import { Link } from "react-router-dom";
import './herosection.css';

const Herosection = () => {
  return (
    <>
       {/* Left Fixed Column */}
       <div className="fixed left-0 top-0 flex flex-col justify-end h-screen text-white p-4 pb-[3rem] ">
        <div className="mb-4 hover:scale-125 transition duration-300">
          <a href="https://wa.me/+918860014004" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={35}  className='bg-green-500'/>
          </a>
        </div>
        <div className="mb-4 hover:scale-125 transition duration-300">
          <a href="tel:8860014004">
            <BiPhoneCall size={35}  className='bg-blue-600'/>
          </a>
        </div>
        <div className=' hover:scale-125 transition duration-300'>
          <a href="mailto:support@campusbeez.com">
            <FaEnvelope size={35} className='bg-yellow-500 p-1'/>
          </a>
        </div>
      </div>

       {/* righgt Fixed Column */}
       {/* <div className="fixed right-0 top-0 flex flex-col justify-end h-screen text-white p-4 animate-custom-bounce">
        <div className="mb-4 flex flex-col">
          <div>
            <p>Need Help</p>
          </div>
          <img src="/assets/chatbot.png" alt='chatbot' className='rounded-full w-[4rem]'/>
        </div>
       
      </div> */}
       
      <div className=" mainhero-container bg-cover bg-center lg:bg-center md:bg-center flex items-center md:mt-28  banner-container ">
      {/*   style={{ backgroundImage: 'url("/assets/135.gif")' }}> */}
         {/* add relative in banner container <div className="absolute inset-0 bg-gradient-to-b from-black opacity-20"></div> */}
        
       
        <div className="text-white lg:ml-28 px-6 md:px-0 md:w-full lg:w-[82%] md:ml-8">
        <h1 className="text-2xl md:text-4xl font-semibold mb-4 font-opensans lg:text-5xl fadeInHeading text-black">Empowering Educational Institutions with Comprehensive Campus Management Solutions</h1>
          <div className="mb-6 flex flex-col gap-4 mt-10">
            <div className='flex gap-2 '>
              <FaGraduationCap size={30} style={{ color: 'black' }}/>
              <h2 className="text-base font-semibold lg:text-xl md:text-xl text-black">Solutions to Elevate Education – Serving Colleges, Universities with Unique Offerings.</h2>
            </div>

            <div className='flex gap-2' style={{ color: 'black' }}>
              <FaCogs size={30} />
              <h2 className="text-base font-semibold lg:text-xl md:text-xl text-black">Effortless Streamlining of Student, Faculty, and Campus Administration through Automation.</h2>
            </div>
            <div className='flex gap-2 '>
              <FaLaptop size={30} style={{ color: 'black' }}/>
              <h2 className="text-base font-semibold lg:text-xl md:text-xl text-black">Enhancing Student Success through Learning Outcome Management and In-Depth Analysis.</h2>
            </div>
          </div>
          <Link to="/contact">
        <button className="bg-blue-900 text-white px-6 py-3 rounded-full text-base md:text-lg font-semibold hover:bg-yellow-600 transition duration-300">
          Request demo
        </button>
      </Link>
        </div>
        <div className="lg:w-1/3 hidden lg:block mt-2 ml-4 hover:scale-110 transition duration-300 ">
          <img src="/assets/girl.png" alt="Image" className='w-[80%]' />
        </div>
      </div>

      <div className="flex flex-col items-center md:flex-row justify-around p-8 mt-10 w-4/5 lg:ml-32 md:ml-20 ml-10 rounded-lg md:rounded-full " style={{ backgroundImage: 'url("/assets/shade.jpg")' }}>
       
          <div className="text-left  md:mr-4">
              <h1 className="text-2xl lg:text-3xl md:text-xl font-semibold text-white">Is Your Institute Prepared For Transformative NEP 2023? </h1>
              <p className="text-base lg:text-base md:text-sm font-semibold mt-2 text-white">CampusBeez Innovative ERP Solutions: Leading the Way to Competitive Excellence.</p>
            </div>
            <button className="bg-blue-900 text-white px-3 py-3 rounded-full text-xl lg:w-1/5  md:w-1/3 font-semibold mt-4 md:mt-0 hover:bg-yellow-600 transition duration-300">
              Let's Begin
            </button>
      </div>
    </>
  );
};

export default Herosection;

