import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
  // define state
  const [user, setUser] = useState({ email: "", password: "" });
  const [error,setError] = useState('')

  // call navigate function
  const navigate = useNavigate()
  
  // fetch localdata
  const localData = JSON.parse(localStorage.getItem('Login'))

  // set new title
  useEffect(() => {
    document.title = "Resume Admin | Login";
  }, []);
  
  // if localdata available navigate to admin
  useEffect(()=>{
      if(localData&&localData?.role==='admin'){
          navigate('/adminpanel/admindashboard')
      }else{
        navigate('/adminpanel')
      }
  },[localData,navigate])

  

  // handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        const formData = new FormData();
        formData.append("email", user.email);
        formData.append("password", user.password);
        const request = await axios.postForm(
          `${process.env.REACT_APP_RESUME_URL}/login`,
          formData
        );
        const response = await request?.data;
        if (response?.code === 200) {
          setError('')
          localStorage.setItem(
            "LoginToken",
            JSON.stringify(response?.access_token)
          );
          localStorage.setItem("Login", JSON.stringify(response?.data?.data));
          response.data?.data&&response.data?.data?.role!=="admin"?
          navigate("/dashboard")
          :
          navigate("/adminpanel/admindashboard")
        }
      } catch (error) {
        console.log("error", error);
        if (error?.response?.data?.error) {
          console.log("errors", error);
          setError(`Please Recheck Email/Password`)
          toast.error(`You're not authorized`);
        }
        if (error?.response?.data?.error.length < 0) {
          const errors = Object.values(error?.response?.data?.error);
          console.log("Errors", errors);
          errors.map((x) =>
            // console.log("Error",x)
            toast.error(`${x}`)
          );
        }
        if (error?.response?.data?.message) {
          if (error?.response?.data?.error) {
            const errors = Object.values(error?.response?.data?.error);
            console.log("Errors", errors);
            errors.map((x) =>
              // console.log("Error",x)
              toast.error(`${x}`)
            );
          }
          if (error?.response?.data?.message) {
            toast.error(`${error?.response?.data?.message}`);
          }
        }
      }
    } else {
      toast.error("Please Enter Full Details");
    }
  };

  return (
    <div className="bg-white p-20  rounded-3xl border-1 border-gray-200">
      <ToastContainer/>
      <h1 className=" text-4xl font-semibold ">ADMIN PANEL</h1>
      <p className=" font-medium text-lg text-gray-500 mt-4 ">
        Welcome back! Please enter your details
      </p>
      <form onSubmit={handleLogin} className="mt-8 ">
        <div className=" mt-2 ">
          <label className="text-lg font-medium">Email/EmployeeID</label>
          <div className=" border  border-primary rounded mt-1">
          <input
            required
            autoComplete="username"
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
            type="text"
            name="email"
            placeholder="Email"
            className="w-full p-1 "
          />
          </div>
        </div>
        <div className="mt-2">
          <label className="text-lg font-medium">Password</label>
          <div className="border border-primary rounded mt-1">
          <input
            required
            autoComplete="current-password"
            type="password"
            name="password"
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
            placeholder="Password"
            className="w-full p-1"
          />
          </div>
        </div>
          {
            error !== ""?
          <p className="p-2 text-pink-500">{error}</p>:null
          }
        <div className=" mt-6  flex flex-col gap-y-4">
          <button type="submit" className=" bg-gray-700 text-white hover:bg-green-600 border border-primary transition-all text-lg rounded-sm px-6 py-2 ease-out duration-75 delay-75">
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
