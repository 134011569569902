import React, { useState } from 'react';
import { FaGraduationCap, FaCogs, FaLaptop, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import { BiPhoneCall } from 'react-icons/bi';
import TextField from '@mui/material/TextField';
import './heroCollege.css'

const HeroCollege = () => {
  const [demoData, setDemoData] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDemoData({
      ...demoData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can access form data in the `demoData` state object here
    console.log(demoData);
    // Add your logic to submit the form data to a server or perform other actions
  };

  return (
    <>
      {/* Left Fixed Column */}
      <div className="fixed left-0 top-0 flex flex-col justify-end h-screen text-white p-4 ">
        <div className="mb-4  hover:scale-125 transition duration-300">
          <a href="https://wa.me/8860014004" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={35} className="bg-green-500" />
          </a>
        </div>
        <div className="mb-4 hover:scale-125 transition duration-300">
          <a href="tel:8860014004">
            <BiPhoneCall size={35} className="bg-blue-600" />
          </a>
        </div>
        <div className='mb-8 hover:scale-125 transition duration-300'>
          <a href="mailto:support@campusbeez.com">
            <FaEnvelope size={35} className="bg-yellow-500 p-1" />
          </a>
        </div>
      </div>

      <div
        className="bg-cover bg-center lg:bg-center md:bg-center flex items-center md:mt-28  banner-container "
        style={{ backgroundImage: 'url("/assets/collegebanner.png")' }}
      >
        <div className="text-white lg:ml-28 px-6 md:px-0 md:w-full lg:w-2/3 md:ml-8">
          <h1 className="text-2xl md:text-4xl font-semibold mb-4 font-opensans text-black lg:text-5xl fadeInHeading">
            College Management System Software
          </h1>
          <div className="mb-6 flex flex-col gap-4 mt-10">
            <div className="flex gap-2 ">
              <FaGraduationCap size={35} style={{ color: '#36454F' }} />
              <h2 className="text-base font-semibold lg:text-xl md:text-xl text-gray-700">
                Our College Management System Software ERP streamlines administrative tasks, saving colleges valuable time
                and resources.
              </h2>
            </div>

            <div className="flex gap-2">
              <FaCogs size={35} style={{ color: '#36454F' }} />
              <h2 className="text-base font-semibold lg:text-xl md:text-xl text-gray-700">
                Elevate the student experience through online registration, real-time academic access, and enhanced
                communication within our College Management System Software ERP.
              </h2>
            </div>
            <div className="flex gap-2">
              <FaLaptop size={35} style={{ color: '#36454F' }} />
              <h2 className="text-base font-semibold lg:text-xl md:text-xl text-gray-700">
                Empower your college with data-driven insights from our ERP to optimize resource allocation and ensure a
                high-quality educational environment.
              </h2>
            </div>
          </div>
          <button className="bg-blue-900 text-white px-6 py-3 rounded-full text-base md:text-lg font-semibold hover:bg-yellow-600 transition duration-300">
            Request demo
          </button>
        </div>
      </div>

      <div
        className="flex flex-col bg-blue-100 items-center lg:flex-row justify-around p-8 mt-10 w-4/5 lg:ml-32 md:ml-20 ml-10 rounded-lg lg:rounded-full mb-10 "
        // style={{ backgroundImage: 'url("/assets/shade.jpg")' }}
      >
        <div className="text-left  md:mr-4">
          <form onSubmit={handleSubmit} className="lg:flex lg:flex-row flex flex-col lg:space-x-6 gap-4">
            <TextField
              type="text"
              id="name"
              name="name"
              label="Name"
              variant="filled"
              value={demoData.name}
              onChange={handleChange}
              size="small"
              required
              
              className="custom-textfield"
            />
            <TextField
              type="email"
              id="email"
              name="email"
              label="Email"
              variant="filled"
              value={demoData.email}
              onChange={handleChange}
              size="small"
              required
              className="custom-textfield"
            />
            <TextField
              type="tel"
              id="mobile"
              name="mobile"
              label="Mobile No."
              variant="filled"
              value={demoData.mobile}
              onChange={handleChange}
              size="small"
              required
              className="custom-textfield"
            />
            <button
              type="submit"
              className="bg-orange-300 hover:bg-text-black text-white px-3 py-3 rounded-full text-xl lg:w-1/4  md:w-full font-semibold mt-4 md:mt-0 hover:bg-orange-400 transition duration-300"
            >
              Request Free Demo
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default HeroCollege;
