import React from 'react'
import HeroCollege from './HeroCollege'
import CollegeAbout from './CollegeAbout'
import CollegeFeatures from './CollegeFeatures'
import CollegeModule from './CollegeModule'
import { Helmet } from 'react-helmet';

const College = () => {
  return (
    <div className='md:mt-32'>
     <Helmet>
        
        <meta charset="utf-8" />
        <title>College Management System Software - College ERP Software</title>
        <meta name="description" content="college erp, higher education erp software, college management system, college erp software, college management software, Campuseez."/>
        <meta name="keywords" content="College ERP: College management system helps education institutions to manage their academic, administrative, attendance, library, finance system very efficiently. Campusbbeez college ERP has 25+ pro modules and 30+ inbuilt modules."/>
        <meta name="robots" content="index,follow"/>
        <meta name="copyright" content="Livelake Education"/>
        <meta name="email" content="support@campusbeez.com"/>
        <meta name="language" content="EN"/>
        <meta name="DC.Publisher" content="Campusbeez"/>
        <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
        <meta name="DC.Language" content="en"/>
        <meta name="Distribution" content="Global"/>
        <meta name="geo.region" content="IN"/>
        <link rel="canonical" href="https://campusbeez.com/"/>
        <meta name="geo.placename" content="India"/>
        <meta name="geo.position" content="Gurgaon"/>
        <meta name="geo.region" content="usa"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>

    </Helmet>
      <HeroCollege />
      <CollegeAbout />
      <CollegeFeatures />
      <CollegeModule />
    </div>
  )
}

export default College