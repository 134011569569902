import React, { useState } from 'react';

const Automate = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    regard: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    
    console.log(formData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="bg-black flex items-center justify-center">
      <div className="md:bg-gray-800 md:p-6 rounded-lg w-full mx-20 my-8">
      <h1 className="text-2xl font-semibold md:text-3xl mb-2 text-center text-white font-opensans mt-4 ">
          CampusBeez Education ERP Offers
        </h1>
        <img src='/assets/whitecap.png' alt='cap image' className='md:w-1/3 mx-auto mb-8' />
        <p className="text-white mx-[-3.5rem] mb-2 lg:mx-2 md:mx-0 text-xl font-opensans font-semibold my-6 flex justify-center">
        We promise to offer you the best Education ERP, smooth implementation and constant support!</p>
        <form onSubmit={handleSubmit}>
        <div className="flex flex-col mb-4 mt-8 lg:ml-20">
            <div className="lg:w-1/2 lg:ml-[12rem] w-full mb-4">
              <label htmlFor="name" className="text-white block mb-1">Enter Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 rounded bg-white text-black"
              />
            </div>
            <div className=" lg:w-1/2 lg:ml-[12rem] w-full mb-4">
              <label htmlFor="email" className="text-white block mb-1">Enter Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 rounded bg-white text-black"
              />
            </div>
          </div>

          <div className="flex flex-col mb-4 lg:ml-20 lg:flex lg:flex-col md:flex-row ">
            <div className="md:w-1/2 lg:ml-[12rem] w-full mr-2">
              <label htmlFor="mobile" className="text-white block mb-1">Enter Mobile No. :</label>
              <input
                type="number"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                className=" md:w-full w-full py-2 rounded bg-white text-black"
              />
            </div>
            <div className="md:w-1/2 lg:ml-[12rem] lg:mt-6 w-full md:ml-2">
              <label htmlFor="regard" className="text-white block mb-1">Regarding :</label>
              <input
                type="text"
                id="regard"
                name="regard"
                value={formData.regard}
                onChange={handleInputChange}
                className=" md:w-full w-full py-2 rounded bg-white text-black"
              />
            </div>
          </div>
          <div className='flex justify-center items-center'>
              <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 mt-4 rounded">Submit</button>
          </div>
          
        </form>
      </div>
    </div>
  );
};

export default Automate;
