const review = [
  {
    id: 1,
    name: 'Ajay Chauhan Founder & M.D',
    image: '/assets/ajaysir.jpg', 
    designation: 'CEO, LiveLake Educaton',
    content: 'As a CEO, our College ERP system is the cornerstone of our institutios digital transformation, streamlining operations, enhancing efficiency, and ensuring that our students receive the best possible education in this digital age.',
  },
  {
    id: 2,
    name: 'G.C. Tripathi Director',
    image: '/assets/girish-chand.jpg', 
    designation: 'Robotic Program',
    content: 'College ERP streamlines our institutions operations and data management, enhancing efficiency, and providing actionable insights for informed decision-making.',
  },

];

export default review;