import React, { useState } from 'react';
import Mission from './Mission';
import Features from './Features';
import Tools from './Tools';
// import { Link } from 'react-router-dom';
import Enquiry from './Enquiry';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// const openPDFInNewWindow = () => {
//   const newWindow = window.open('', '_blank');
//   newWindow.document.write(
//     `<iframe width="100%" height="100%" src="/assets/Cybersecurity/workshop.pdf"></iframe>`
//   );
// };

const Cybersecurity = () => {
  const navigate = useNavigate();

  const moveToNextPage = () => {
    navigate('/cybersecurityfrom');
  };
  const [showModal, setShowModal] = React.useState(false);
  console.log('model',showModal)

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const [isPopupOpen, setIsPopupOpen] = useState(false);

  // const togglePopup = () => {
  //   setIsPopupOpen(!isPopupOpen);
  // };


  return (
    <div className='md:mt-32'>
            <Helmet>
        
        <meta charset="utf-8" />
        <title>Campusbeez | Ethical hacking courses,Cyber security tools,Ethical hacking certifications,Cyber security threats,Cyber safety education</title>
        <meta name="description" content="Are you looking for best institute in cyber security? Cyber crime awareness society providing the best course in cyber security in jaipur and delhi. cyber security course, cyber security training, learn cyber security."/>
        <meta name="keywords" content="cyber institute, cyber course, cyber training, cyber coaching, cyber center, cyber learn, cyber  tuition, security institute, security course, security training, security coaching, security center, security learn, security tuition"/>
        <meta name="robots" content="index,follow"/>
        <meta name="copyright" content="Livelake Education"/>
        <meta name="email" content="support@campusbeez.com"/>
        <meta name="language" content="EN"/>
        <meta name="DC.Publisher" content="Campusbeez"/>
        <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
        <meta name="DC.Language" content="en"/>
        <meta name="Distribution" content="Global"/>
        <meta name="geo.region" content="IN"/>
        <link rel="canonical" href="https://campusbeez.com/"/>
        <meta name="geo.placename" content="India"/>
        <meta name="geo.position" content="Gurgaon"/>
        <meta name="geo.region" content="usa"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>

    </Helmet>
      
      <div
        className="bg-cover lg:bg-center bg-right h-96 md:mt-32"
        style={{ backgroundImage: 'url("/assets/cyberbanner.jpg")' }}
      >
        <div className="flex flex-col justify-center h-full lg:ml-[45rem] mr-4">
          <h2 className="text-4xl text-black font-semibold mb-4 md:ml-20 ml-10">Cyber Security & Ethical Hacking</h2>
          <p className='text-black font-opensans font-semibold text-xl  md:ml-20 ml-10'>CampusBeez (A LiveLake Education Brand) presents the opportunity for every student and faculty to get hands-on knowledge of Cyber Security and Ethical Hacking, one of the most demanding skills in the market, as well as for their own Cyber safety. 
          
          <button
        className=" font-bold text-xl pl-2 text-red-600"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Learn More 
      </button>




      {showModal ? (
        
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
               
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-3xl font-semibold">
                Cyber Security & Ethical Hacking

                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-red-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/QNSpo81hibU?si=MlscbiivEh0xF1Pn"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
               
                </div>
              </div>
            </div>
          </div>

         
        
      ) : null}
   





















      {/* {isPopupOpen && (
              <div className='popup-overlay'>
                <div className='popup'>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/QNSpo81hibU?si=MlscbiivEh0xF1Pn"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                  <button onClick={togglePopup} className='close-button'>
                    Close
                  </button>
                </div>
              </div>
            )} */}
      </p>
          <div className='text-center mt-4'>
          {/* <button onClick={openPDFInNewWindow} className="bg-blue-500 w-[9rem] text-white font-semibold font-opensans rounded-md py-2 px-3 mt-4 hover:bg-blue-600 cursor-pointer">
              Open PDF
            </button> */}
            {/* <Link to="/assets/Cybersecurity/workshop.pdf" target='_blank' className="bg-blue-500 w-[9rem] text-white mr-4 font-semibold font-opensans rounded-md py-2 px-3 mt-4 hover:bg-blue-600 cursor-pointer">
              Know More
            </Link> */}
            <button
        className="bg-blue-500 w-[9rem] text-white font-semibold font-opensans rounded-md py-2 px-3 mt-4 hover:bg-blue-600 cursor-pointer"
         onClick={moveToNextPage}
      > Enquiry/Register
        </button>
          </div>
        </div>
        
      </div>
      <Mission />
      {/* <Enquiry /> */}
      <Features />
      <Tools />
      </div>
  );
};

export default Cybersecurity;



