import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Header from "../layout/Header";
import SideBar from "../layout/SideBar";

const DashboardLayout = () => {
  const [showSideBar, setShowSideBar] = useState(true);
  const navigate = useNavigate()
  const location = useLocation()
  const localData = JSON.parse(localStorage.getItem('Login'))
  useEffect(()=>{
    if(!localData){
      navigate('/')
    }
  },[location])

  // const data = JSON.parse(localStorage.getItem('data'))

  return (
    <div className="flex flex-row bg-neutral-100 min-h-screen max-h-full">
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div className="flex flex-col flex-1 w-[95%]">
        <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        <div className="flex-1 lg:p-4 p-1 min-h-0 overflow-x-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
