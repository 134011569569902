import React from 'react';

const Comingsoon = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-slate-200 ">
      <div className="text-7xl font-semibold text-yellow-500 text-center">Coming Soon ...</div>
    </div>
  );
}

export default Comingsoon;
