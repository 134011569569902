import React from 'react';

const Leadership = () => {
  return (
    <div className="py-10 px-8 bg-slate-300">
      <div className="max-w-6xl lg:mx-auto md:mx-8">
           <div className='flex justify-center flex-col items-center'>
              <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Leadership</h1>
              <img src='/assets/cap.png' alt='cap image' className='md:w-1/5  '/>
            </div>

      <div className="md:flex space-x-8 mt-8">
        <div className="lg:w-3/12 md:w-1/3 w-full md:mx-0 mx-10 ">
          <img
            src="/assets/ajaysir.jpg"
            alt="Leadership"
            className="w-[85%] h-auto lg:ml-4 ml-[-1rem] shadow-2xl"
          />
        </div>
        <div className="w-9/12">
          <h1 className="text-2xl font-semibold text-blue-800 font-opensans md:text-left text-center md:mt-0 mt-6">Mr.Ajay Kumar Chauhan</h1>
          <h2 className="text-xl font-semibold font-opensans md:text-left text-center">Founder & CEO</h2>
          <p className="text-black mt-2 font-opensans font-semibold text-justify ">
          “An Egghead can Break all the Records” and he proved that by breaking his own records in studies. He has done B.Tech in Electronics & Communication, Studied business management & marketing from IIM(Kozhikode), Masters in Business Analytics from Arizona State University, Entrepreneur Professional from London Business School. He always proved himself a top scorer in school days, college and in university. After completing all his studies, he started his career as a Managing Director in a multi-national company. He was doing very well in his professional career but that was not enough for him. He is passionate about his work, the passion pushed him hard and he build his own company.Hence, his stars began shining and he gave birth to <a href='https://livelakeeducation.com/' target='_blank' className='text-blue-600'>LiveLake. </a></p> 
          <p className="text-black mt-2 font-opensans font-semibold text-justify">Ajay Chauhan is just not a young Entrepreneur; he is a self-made person and always works hard to make his dreams and wishes come true! He is a true Leader who always walks with his team. He is always ready to fight for all type of odds and future.</p>

        </div>
      </div>

      <div className="mt-12">
      <h2 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Our Advisors</h2>
      <div className="md:flex justify-around md:space-x-4 mt-12">
        {/* Member 1 */}
        <div className="md:w-1/4 w-full ">
          <img src="/assets/ajay_sir.jpg" alt="Member 1" className="w-full h-auto rounded-xl" />
          <h3 className="text-lg font-semibold mt-2 text-center">    Ajay Chauhan Founder & M.D</h3>
          <p className="text-gray-600 text-center">B.Tech MBA(Stanford University)</p>
        </div>
        {/* Member 2 */}
        <div className="md:w-1/4 w-full md:mt-0 mt-6">
          <img src="/assets/girish-chand.jpg" alt="Member 2" className="w-full h-auto rounded-xl" />
          <h3 className="text-lg font-semibold mt-2 text-center">G.C. Tripathi Director (Robotic Program)</h3>
          <p className="text-gray-600 text-center">M.Tech Ph.D(IIT Roorkee)</p>
        </div>
        
        
        {/* <div className="md:w-1/4 w-full md:mt-0 mt-6">
          <img src="/assets/image2.avif" alt="Member 3" className="w-full h-auto rounded-xl" />
          <h3 className="text-lg font-semibold mt-2 text-center">Alex Johnson</h3>
          <p className="text-gray-600 text-center">Role 3</p>
        </div>
        
        <div className="md:w-1/4 w-full md:mt-0 mt-6">
          <img src="/assets/image2.avif" alt="Member 4" className="w-full h-auto rounded-xl" />
          <h3 className="text-lg font-semibold mt-2 text-center">Emily Wilson</h3>
          <p className="text-gray-600 text-center">Role 4</p>
        </div> */}
      </div>
    </div>

    </div>
    </div>
  );
};

export default Leadership;
