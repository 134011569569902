import React from 'react'
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import {BiPhoneCall } from 'react-icons/bi';
import { HiLocationMarker } from 'react-icons/hi';
import { Helmet } from 'react-helmet';

const Terms = () => {
  return (
    <div className='md:mt-32 mb-12'>
    <Helmet>
    <meta charset="utf-8" />
    <title>Campusbeez |Campus Management Solutions,ERP Software,Resume Builder,Online Assesment software,University ERP,College ERP</title>
    <meta name="description" content="Elevate your institution's efficiency with CampusBeez,a comprehensive education management solution by LiveLake Education."/>
    <meta name="keywords" content="School Management,College Management,University Management,Education Technology,CampusBeez App,Resume Builder,Learning Management System,Biometric Attendance System,RFID Attendance System,Library Management,HR Management,LiveLake Education"/>
    <meta name="robots" content="index,follow"/>
    <meta name="copyright" content="Livelake Education"/>
    <meta name="email" content="support@campusbeez.com"/>
    <meta name="language" content="EN"/>
    <meta name="DC.Publisher" content="Campusbeez"/>
    <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
    <meta name="DC.Language" content="en"/>
    <meta name="Distribution" content="Global"/>
    <meta name="geo.region" content="IN"/>
    <link rel="canonical" href="https://campusbeez.com/"/>
    <meta name="geo.placename" content="India"/>
    <meta name="geo.position" content="Gurgaon"/>
    <meta name="geo.region" content="usa"/>
    
    {/* Asynchronous script loading */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>
       </Helmet>
    
    <h1 className='text-2xl md:text-4xl font-semibold mb-4 font-opensans lg:text-5xl text-center py-8'>Terms and Conditions</h1>
    <div className='text-base lg:text-lg md:text-md md:px-24 px-12  text-justify'>
    <p className='mb-6 font-opensans'>Last updated: March 01, 2023</p>
    <p className='mb-6 font-opensans'>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the <a href='https://erp.campusbeez.com/' target='_blank' className='text-blue-600'> erp.campusbeez.com</a> website (the "Service") operated by LiveLake Education Pvt. Ltd ("LiveLake Education"). This Site provides, among other things, information about our people, organization, partners, products offered, forums for discussions, and profiles of founders and institutions/ universities who have subscribed to and deployed the product(s) offered by LiveLake Education Private Limited and its affiliates. The terms laid down herein are complaint with the region of the origin of the website and the user shall be governed under the laws of the said region.</p>
    <p className='mb-6 font-opensans'>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
    <p className='mb-6 font-opensans'>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
    <p className='mb-6 font-opensans'>THESE TERMS OF USE CONTAIN AN ARBITRATION AGREEMENT, WHICH WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION. UNDER THE ARBITRATION AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST LiveLake Education ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS</p>
    <p className='mb-6 font-opensans'>We reserve the right, at our sole discretion, to change or modify portions of these Terms of Use at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these terms were last revised. Your continued use of the Site after the date any such changes become effective constitutes your acceptance of the new Terms of Use.</p>
    <p className='mb-6 font-opensans'>In addition, when using certain components of the Site, you will be subject to any additional terms, policies, rules or guidelines applicable to the Site or such components of the Site that may be posted on the Site from time to time, including, without limitation, the Privacy Policy and any Guidelines applicable to any components of the Site. All such terms are hereby incorporated by reference into these Terms of Use.</p>
    <p className='mb-6 font-opensans'>All aspects in the Terms of Use are in coherence with the Information Technology Act, 2000 ("IT Act") and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 ("IT Rules").</p>
    <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Access and Use of the Site</h2>
        <p className='mb-6 font-opensans'>Your Registration Obligations: You may be required to register with LiveLake Education in order to access and use certain features of the Site. If you choose to register, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Site's registration form. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 15 years of age, you are not authorized to register to use the Site.</p>
        <p className='mb-6 font-opensans'>Member Account, Password and Security: You are responsible for maintaining the confidentiality of your password and account, if any, and are fully responsible for any and all activities that occur under your password or account. You agree to (a) immediately notify LiveLake Education of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the Site. LiveLake Education will not be liable for any loss or damage arising from your failure to comply with this Section.</p>
        <p className='mb-6 font-opensans'>Modifications to Site: LiveLake Education reserves the right to modify or discontinue, temporarily or permanently, the Site (or any part thereof) with or without notice. You agree that LiveLake Education will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Site.</p>
        <p className='mb-6 font-opensans'>General Practices for Use and Storage: You acknowledge that LiveLake Education may establish general practices and limits concerning use of the Site, including but not limited to the maximum period of time that data or other content will be retained by the Site and the maximum storage space that will be allotted on LiveLake Education's servers on your behalf. You agree that LiveLake Education has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded by the Site. You acknowledge that LiveLake Education reserves the right to terminate accounts that are inactive for an extended period of time. You further acknowledge that LiveLake Education reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</p>
        <p className='mb-6 font-opensans'>User Behaviour: In addition to any applicable guidelines or policies that may govern specific components of the Site, you agree to comply with the following conditions in using the Site. You are solely responsible for all code, video, images, information, data, text, software, music, sound, photographs, graphics, messages or other materials ("content") that you upload, post, publish or display (hereinafter, "upload") or email or otherwise transmit via the Site. LiveLake Education reserves the right to investigate and take appropriate legal action against anyone who, in LiveLake Education's sole discretion, violates this provision, including without limitation, removing the offending content from the Site, suspending or terminating the account of such violators and reporting you to the law enforcement authorities..</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>User is expressly prohibited against:</h2>
        <p className='mb-6 font-opensans'>E-mail or otherwise upload any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, "junk mail", "spam", "chain letters", "pyramid schemes", "contests", "sweepstakes," or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libellous, invasive of anotherâ€™s privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in the sole judgment of LiveLake Education, is objectionable or which restricts or inhibits any other person from using or enjoying the Site, or which may expose LiveLake Education or its users to any harm or liability of any</p>
        <p className='mb-6 font-opensans'>Interfere with or disrupt the Site or servers or networks connected to the Site, or disobey any requirements, procedures, policies or regulations of networks connected to the Site;</p>
        <p className='mb-6 font-opensans'>Violate any applicable local, state, national or international law, or any regulations having the force of law;</p>
        <p className='mb-6 font-opensans'>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;Solicit personal information from anyone under the age of 18;</p>
        <p className='mb-6 font-opensans'>Harvest or collect email addresses or other contact information of other users from the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;</p>
        <p className='mb-6 font-opensans'>Advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;</p>
        <p className='mb-6 font-opensans'>Further or promote any criminal activity or enterprise or provide instructional information about illegal activities; or Obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
        <p className='mb-6 font-opensans'>Commercial Use: Unless otherwise expressly authorized herein or in the Site, you agree not to display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works from, modify, sell, resell, exploit, transfer or upload for any commercial purposes, any portion of the Site, use of the Site, or access to the Site</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Intellectual Property Rights</h2>
        <p className='mb-6 font-opensans'>Site Content, Software and Trademarks: You acknowledge and agree that the Site may contain content or features ("Site Content") that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorized by LiveLake Education, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the Site or the Site Content, in whole or in part, except that the foregoing does not apply to your own User Content (as defined below) that you legally upload to the Site. In connection with your use of the Site you will not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are blocked by LiveLake Education from accessing the Site (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Site or the Site Content other than as specifically authorized herein is strictly prohibited. Any rights not expressly granted herein are reserved by LiveLake Education.</p>
        <p className='mb-6 font-opensans'>The LiveLake Education name and logos are trademarks of LiveLake Education Private Limited. Other trademarks used and displayed via the Site may be trademarks of their respective owners who may or may not endorse or be affiliated with or connected to LiveLake Education. Nothing in this Terms of Use or the Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of LiveLake Education Trademarks displayed on the Site, without our prior written permission in each instance. All goodwill generated from the use of LiveLake Education Trademarks will inure to our exclusive benefit.</p>
        <p className='mb-6 font-opensans'>Third Party Material: Under no circumstances will LiveLake Education be liable in any way for any content or materials of any third parties (including founders, investors or other users), including, but not limited to, for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge that LiveLake Education does not pre-screen content, but that LiveLake Education and its designees will have the right (but not the obligation) in their sole discretion to refuse or remove any content that is available via the Site. Without limiting the foregoing, LiveLake Education and its designees will have the right to remove any content that violates these Terms of Use or is deemed by LiveLake Education, in its sole discretion, to be otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any content, including any reliance on the accuracy, completeness, or usefulness of such content.</p>
        <p className='mb-6 font-opensans'>User Content Transmitted Through the Site: With respect to the content or other materials you upload through the Site or share with other users or recipients (collectively, â€œUser Contentâ€), you represent and warrant that you own all right, title and interest in and to such User Content, including, without limitation, all copyrights and rights of publicity contained therein. By uploading any User Content you hereby grant and will grant LiveLake Education and its affiliated companies a nonexclusive, worldwide, royalty free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, store, modify and otherwise use your User Content for any LiveLake Education related purpose in any form, medium or technology now known or later developed.</p>
        <p className='mb-6 font-opensans'>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Site (â€œSubmissionsâ€) provided by you to LiveLake Education are non-confidential and LiveLake Education will be entitled to the unrestricted use and dissemination of these Submissions for any purpose, without acknowledgment or compensation to you.</p>
        <p className='mb-6 font-opensans'>Without limiting the foregoing, you acknowledge and agree that LiveLake Education may preserve content and may also disclose content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these Terms of Use; (c) respond to claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of LiveLake Education, its users and the public. You understand that the technical processing and transmission of the Site, including your content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</p>
        <p className='mb-6 font-opensans'>Copyright Complaints: LiveLake Education respects the intellectual property of others, and we ask our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, you should notify LiveLake Education of your infringement claim in accordance with the procedure set forth below. LiveLake Education will process and investigate notices of alleged infringement and will take appropriate actions under the Copyright Act, 1957 and as amended by Copyright (Amendment) Act, 2012 ("Copyright Act") and other applicable intellectual property laws with respect to any alleged or actual infringement. A written notification of claimed copyright infringement should be faxed or mailed LiveLake Education</p>
        <p className='mb-6 font-opensans'>To be effective, the notification must be in writing and contain the following information:</p>
        <ul className='list-disc pl-6 mb-4'>
            <li> an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
            <li> a description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
            <li> a description of where the material that you claim is infringing is located on the Site, with enough detail that we may find it on the Site;</li>
            <li>your address, telephone number, and email address;</li>
            <li> a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law;</li>
            <li> a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</li>
        </ul>
        <p className='mb-6 font-opensans'>Counter-Notice: If you believe that your User Content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright ownerâ€™s agent, or pursuant to the law, to upload and use the content in your User Content, you may send a written counter-notice containing the following information to the Copyright Agent: + your physical or electronic signature; + identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled; + a statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content; and + your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the courts of New Delhi and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</p>
        <p className='mb-6 font-opensans'>Repeat Infringer Policy: In accordance with the Copyright Act and other applicable law, LiveLake Education has adopted a policy of terminating, in appropriate circumstances and at LiveLake Education's sole discretion, users who are deemed to be repeat infringers. LiveLake Education may also at its sole discretion limit access to the Site and/or terminate the registrations of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Third Party Websites</h2>
        <p className='mb-6 font-opensans'>The Site may provide, or third parties may provide, links or other access to other sites and resources on the Internet or to third party applications. LiveLake Education has no control over such sites, resources or applications and LiveLake Education is not responsible for and does not endorse such sites, resources or applications. You further acknowledge and agree that LiveLake Education will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through any such sites, resources or applications. Any dealings you have with third parties found while using the Site are between you and the third party, and you agree that LiveLake Education is not liable for any loss or claim that you may have against any such third party.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Indemnity and Release</h2>
        <p className='mb-6 font-opensans'>You agree to release, indemnify and hold LiveLake Education and its affiliates and their officers, employees, directors and agents harmless from any from any and all losses, damages, expenses, including reasonable attorneysâ€™ fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Site or any related information, any User Content, your violation of these Terms of Use or your violation of any rights of another.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Disclaimer of Warranties</h2>
        <p className='mb-6 font-opensans'>YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. LiveLake Education EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. LiveLake Education MAKES NO WARRANTY THAT (I) THE SITE WILL MEET YOUR REQUIREMENTS, (II) THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE WILL BE ACCURATE OR RELIABLE.</p>
        <p className='mb-6 font-opensans'>Limitation of Liability: YOU EXPRESSLY UNDERSTAND AND AGREE THAT LiveLake Education WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF LiveLake Education HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SITE OR ANY RELATED INFORMATION; (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF ANY THIRD PARTY (INCLUDING USERS) ON THE SITE; OR (IV) ANY OTHER MATTER RELATING TO THE SITE. IN NO EVENT WILL LiveLake Education's TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED INR 5,000 (INDIAN RUPEES FIVE THOUSAND).</p>
        <p className='mb-6 font-opensans'>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE OR WITH THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SITE.</p>
        <p className='mb-6 font-opensans'>Dispute Resolution By Binding Arbitration: PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</p>
       </div>

       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Agreement to Arbitrate</h2>
        <p className='mb-6 font-opensans'>This Dispute Resolution by Binding Arbitration section is referred to in this Terms of Use as the "Arbitration Agreement." You agree that any and all disputes or claims that have arisen or may arise between you and LiveLake Education, whether arising out of or relating to this Terms of Use (including any alleged breach thereof), the Site, any advertising, any aspect of the relationship or transactions between us, shall be resolved exclusively through final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement, except that you may assert individual claims in small claims court, if your claims qualify. Further, this Arbitration Agreement does not preclude you from bringing issues to the attention of any court of law, or local agencies, and such agencies can, if the law allows, seek relief against us on your behalf. You agree that, by entering into this Terms of Use, you and LiveLake Education are each waiving the right to participate in a claim in any court of law. Your rights will be determined by a neutral arbitrator, nor a judge. The Arbitration and Conciliation Act ,1996 governs the interpretation and enforcement of this Arbitration Agreement.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Prohibition of Direct and Representative Claims in any Court of Law and Non-Individualized Relief</h2>
        <p className='mb-6 font-opensans'>YOU AND LiveLake Education AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY DIRECT OR REPRESENTATIVE CLAIM OR PROCEEDING. UNLESS BOTH YOU AND LiveLake Education AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLAIM. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S). SUCH RELIEF SHALL BE BINDING ON ALL ACCOUNTS.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Pre-Arbitration Dispute Resolution</h2>
        <p className='mb-6 font-opensans'>LiveLake Education is always interested in resolving disputes amicably and efficiently, and most user concerns can be resolved quickly and to the user's satisfaction by emailing us at ______________.com If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute ("Notice"). The Notice to LiveLake Education should be sent to ________________________________ ("Notice Address"). The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set forth the specific relief sought. If LiveLake Education and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or LiveLake Education may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by LiveLake Education or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or LiveLake Education is entitled.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Arbitration Procedures</h2>
        <p className='mb-6 font-opensans'>Arbitration will be conducted by a neutral arbitrator in accordance with the Arbitration and Conciliation Act 1996 and any rules and procedures thereof (collectively, the "Arbitration Act"), as modified by this Arbitration Agreement. If there is any inconsistency between any term of the Arbitration Act or Rules and any term of this Arbitration Agreement, the applicable terms of this Arbitration Agreement will preside, provided that the application of this Arbitration Agreement does not expressly contradict the Arbitration Act. The arbitrator must also follow the provisions of these Terms of Use as a court would. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of this Arbitration Agreement. Although arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator can award the same damages and relief on an individual basis that a court can award to an individual under the Terms of Use and applicable law. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons.</p>
        <p className='mb-6 font-opensans'>Unless LiveLake Education and you agree otherwise, any arbitration hearings will take place in a reasonably convenient location for both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, the determination shall be made by arbitrator. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Costs of Arbitration</h2>
        <p className='mb-6 font-opensans'>All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all parties.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Confidentiality</h2>
        <p className='mb-6 font-opensans'>LiveLake Education is always interested in resolving disputes amicably and efficiently, and most user concerns can be resolved quickly and to the user's satisfaction by emailing us at ______________.com If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute ("Notice"). The Notice to LiveLake Education should be sent to ________________________________ ("Notice Address"). The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set forth the specific relief sought. If LiveLake Education and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or LiveLake Education may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by LiveLake Education or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or LiveLake Education is entitled.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Severability</h2>
        <p className='mb-6 font-opensans'>If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other than the subsection (b) titled "Prohibition of Direct and Representative Claims in any Court of Law and Non-Individualized Relief" above) is invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable term or provision, and this Arbitration Agreement shall be enforceable as so modified. If a court or the arbitrator decides that any of the provisions of subsection (b) above titled "Prohibition of Direct and Representative Claims in any Court of Law and Non-Individualized Relief" are invalid or unenforceable, then the entirety of this Arbitration Agreement shall be null and void. The remainder of the Terms of Use will continue to apply.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Future Changes to Arbitration Agreement</h2>
        <p className='mb-6 font-opensans'>Notwithstanding any provision in this Terms of Use to the contrary, LiveLake Education agrees that if it makes any future change to this Arbitration Agreement (other than a change to the Notice Address) while you are a user of the Site, you may reject any such change by sending LiveLake Education written notice within thirty (30) calendar days of the change to the Notice Address provided above. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this Arbitration Agreement as of the date you first accepted these Terms of Use (or accepted any subsequent changes to these Terms of Use).</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Termination</h2>
        <p className='mb-6 font-opensans'>You agree that you are solely responsible for your interactions with any other user in connection with the Site and LiveLake Education will have no liability or responsibility with respect thereto. LiveLake Education reserves the right, but has no obligation, to become involved in any way with disputes between you and any other user of the Site.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>User Disputes</h2>
        <p className='mb-6 font-opensans'>You agree that you are solely responsible for your interactions with any other user in connection with the Site and LiveLake Education will have no liability or responsibility with respect thereto. LiveLake Education reserves the right, but has no obligation, to become involved in any way with disputes between you and any other user of the Site.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>General</h2>
        <p className='mb-6 font-opensans'>These Terms of Use constitute the entire agreement between you and LiveLake Education and govern your use of the Site, superseding any prior agreements between you and LiveLake Education with respect to the Site. These Terms of Use will be governed by the laws of India without regard to its conflict of law provisions. With respect to any disputes or claims not subject to arbitration, as set forth above, you and LiveLake Education agree to submit to the personal and exclusive jurisdiction of the courts located within Delhi NCR. The failure of LiveLake Education to exercise or enforce any right or provision of these Terms of Use will not constitute a waiver of such right or provision. If any provision of these Terms of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the partiesâ€™ intentions as reflected in the provision, and the other provisions of these Terms of Use remain in full force and effect.</p>
        <p className='mb-6 font-opensans'>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Site or these Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred. A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. You may not assign this Terms of Use without the prior written consent of LiveLake Education, but LiveLake Education may assign or transfer this Terms of Use, in whole or in part, without restriction. The section titles in these Terms of Use are for convenience only and have no legal or contractual effect. Notices to you may be made via either email or regular mail. The Site may also provide notices to you of changes to these Terms of Use or other matters by displaying notices or links to notices generally on the Site.</p>
       </div>
       
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Your Privacy</h2>
        <p className='mb-6 font-opensans'>At LiveLake Education, we respect the privacy of our users. For details please see our Privacy Policy. By using the Site, you consent to our collection and use of personal data as outlined therein.</p>
       </div>

       <div>
       <h2 className='mb-4 text-2xl font-semibold font-opensans'>Contact Us</h2>
       <p className='mb-6 font-opensans'>If you have any questions about this Terms And Conditions, please contact us:</p>
       <div className='flex space-x-2 mb-2'>
       <HiLocationMarker size={35} className='bg-yellow-500'/>
       <p>Epic Corporations Pvt Ltd Ansal API Palam Corporate Plaza,
               TF-427, A-Block, Palam Vihar, Gurugram, Haryana 122017 (India)</p>
       </div>
       
       {/* <div className='flex space-x-2 mb-2'>
       <FaWhatsapp size={35}  className='bg-green-500'/>
       <p> (+91) 7827135297</p>
       </div> */}
       <div className='flex space-x-2 mb-2'>
       <BiPhoneCall size={35}  className='bg-blue-600'/>
       <p>+91 8860014004</p>
       </div>
       
       <div className='flex space-x-2 mb-2'>
       <FaEnvelope size={35} className='bg-yellow-500 p-1'/>
       <p>support@campusbeez.com</p>
       </div>
       
       </div>

    </div>
    
    </div>
  )
}

export default Terms