import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MaterialReactTable from 'material-react-table';
import { AiFillDelete } from 'react-icons/ai'
import { FaEye} from 'react-icons/fa'
import { FaFileImport } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import Swal from 'sweetalert2'


import { toast, ToastContainer } from 'react-toastify';
import QuestionAddForm from './QuestionAddForm';



const Question = () => {
   
    
    const [data,setData] = useState('')
    const loginToken = JSON.parse(localStorage.getItem('LoginToken'))
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false); 
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [showImportModal,setShowImportModal] = useState(false)
    const [file,setFile]= useState("")

    const [editQuestionData, setEditQuestionData] = useState({});

    const [editQuestionId, setEditQuestionId] = useState(null);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setNewQuestion(''); 
    };


    const handleAddQuestion = async () => {
        // try {
        //   const formData = new FormData();
        //   formData.append('name', newQuestion); 
        //   const response = await axios.postForm(
        //     `${process.env.REACT_APP_RESUME_URL}/certification/store`,
        //     formData,
        //     {
        //       headers: {
        //          'Authorization':`Bearer  ${loginToken}`,
        //         'Accept': 'application/json'
        //       }
        //     }
        //   );
    
        //   if (response?.data?.code === 200) {
        //     // Update your data state with the new certificate data
        //     setData([...data, response.data.data]); 
        //     toast.success('Certificate Added Successfull')
    
        //     // Close the popup
        //     handleClosePopup();
        //   }
        // } catch (error) {
        //   console.error('Error adding certificate:', error);
        // }
      };


	

  
    const getQuestion = async()=>{
        // const getTemplate = await axios.get(`${process.env.REACT_APP_RESUME_URL}/certification`,{
        //     headers:{
        //         'Authorization':`Bearer  ${loginToken}`,
        //         'Accept':"application/json"
        //     }
        // })
        // const response = await getTemplate?.data
        // console.log('response',response)
        // if(response?.data){
        //     setData(response?.data)
        // }
    }
    useEffect(()=>{
        getQuestion()
    },[])

    const handleOpenEditPopup = (cell) => {
    //   console.log('row' ,cell?.row?.original?.id);
    //   setEditQuestionId(cell?.row?.original?.id);
    //   setIsEditPopupOpen(true);
    //   setEditQuestionData(cell?.row?.original);
    };
  
    // Add a function to close the edit popup form
    const handleCloseEditPopup = () => {
      setIsEditPopupOpen(false);
      setEditQuestionData({});
    };
   

    const handleDelete = (cell)=>{
      console.log("Dealer",cell?.row?.original)
      Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async(result) => {
          if (result.isConfirmed) {
              try {
                  const deleteUser = await fetch(`${process.env.REACT_APP_RESUME_URL}/certification/delete/${cell?.row?.original?.id}`,{
                    method:"post",  
                    headers:{
                          'Authorization':`Bearer  ${loginToken}`,
                          'Accept':"application/json"
                      }
                  })
                  const response = await deleteUser?.json()
                  // console.log("response",response)
                  toast.success(`${response?.message}`)
                  getQuestion()
                  // Swal.fire(
                  //   'Deleted!',
                  //   'Your file has been deleted.',
                  //   'success'
                  // )          
              } catch (error) {
                  if(error?.response?.data?.errors)
                  error?.response?.data?.errors?.map((x)=>(
                      toast.error(`${x}`)
                  ))
              }
      
  }})
    }
    
    const handleEditQuestion = async () => {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_RESUME_URL}/certification/update/${editQuestionId}`, // Use the certificate ID in the API URL
    //       editQuestionData, // Use the updated certificate data
    //       {
    //         headers: {
    //           'Authorization': `Bearer  ${loginToken}`,
    //           'Accept': 'application/json'
    //         }
    //       }
    //     );
  
    //     if (response?.data?.code === 200) {
    //       // Find the index of the edited certificate in the data state
    //       const editedCourseIndex = data.findIndex((certificate) => certificate.id === editQuestionId);
        
    //       if (editedCourseIndex !== -1) {
    //         // Update the certificate data in the state
    //         const updatedData = [...data];
    //         updatedData[editedCourseIndex] = response.data.data;
    //         setData(updatedData);
    //         toast.success('Course Updated');
    //       }
  
    //       // Close the edit popup
    //       handleCloseEditPopup();
    //       getQuestion();
    //     }
    //   } catch (error) {
    //     console.error('Error editing certificate:', error);
    //   }
    };
  
    
    const heading = [
        {
            accessorKey: 's_no',
            header: "S.No",
            size: 110,
            Cell: ({ cell, row }) => <div>{parseInt(row?.id) + 1}</div>,
           
        },
        {
            accessorKey: 'subject',
            header: 'Subject',
            size: 80,
        },
        {
            accessorKey: 'type',
            header: 'Question Type',
            size: 80,
        },
        {
            accessorKey: 'level',
            header: 'Level',
            size: 80,
        },
        {
            accessorKey: 'question',
            header: 'Question',
            size: 150,
        },
      
       
        {
            header:"Action",
            size: 100,
            Cell:({cell})=>
              <>
              {/* <button onClick={(e)=>handleProfileView(e,cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'><FaEye size={20}/></button> */}
              <button onClick={() => handleOpenEditPopup(cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'>
                <FaEye size={20} />
              </button>
              <button onClick={()=>handleDelete(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><AiFillDelete size={20}/></button>
              </>  
        },
       
    ]



  return (

  <div className='w-full'>
   <ToastContainer 
      position='top-center'
      autoClose={3000}/>
       {
        showImportModal?
    <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
   	<div className="absolute bg-black opacity-90 inset-0 z-0" onClick={()=>setShowImportModal(false)}></div>
    <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      
    <div>
        <div className='flex justify-end' onClick={()=>setShowImportModal(false)}>
            <span  className='text-neutral-50 hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
        </div>
      
        <div  className="text-center p-3">
            <div className='flex justify-center'>
            <FaFileImport className='text-neutral-900' size={30}/>
            </div>
            <h2 className="text-xl font-bold py-4 ">Import File</h2>
            <div htmlFor="dropzone-file" className='border-2 p-4 cursor-pointer border-dashed border-neutral-900'>
                <div className='flex justify-center p-1'>
                    <input id="dropzone-file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" className="text-last-center" onChange={(e)=>setFile(e.target.files[0])} />  
                </div>
            <p className="text-sm text-neutral-800 px-8 ">Upload CSV or XLSX file only. </p>
            </div>
        </div>
        {/* <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button onClick={async()=>{
                if(file){
                    const formData= new FormData()
                    formData.append('import_file',file)
                    try {
                        const sendFile = await axios.post(`${process.env.REACT_APP_API_URL}/user/import`,formData,{
                            headers:{
                                'Authorization':`Bearer ${localToken}`,
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            }
                        })
                        const responseData = await sendFile?.data
                        // console.log('response',responseData)
                        toast.done("Data Inserted Succesfully")
                        setShowImportModal(false)
                        getData()
                        
                    } catch (error) {
                        console.log("error",error)
                        if(error?.response?.data?.error){
                          const errors = Object.values(error?.response?.data?.error)
                          console.log('Errors 1',errors)
                          errors.map((x)=>(
                              // console.log("Error",x)
                              toast.error(`${x}`)
                          ))
                        }
                        if(error?.response?.data?.message){
                            if(error?.response?.data?.error){
                                const errors = Object.values(error?.response?.data?.error)
                                console.log('Errors 2',errors)
                                errors.map((x)=>(
                                    // console.log("Error",x)
                                    toast.error(`${x}`)
                                ))
                            }
                            if(error?.response?.data?.message){
                                toast.error(`${error?.response?.data?.message}`)
                            }
                        }
                    }
                }
                else{
                  toast.error('Please select file.!')
                }
                // setShowImportModal(false)
                }} className="mb-2 md:mb-0 bg-neutral-900 border border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white  rounded-sm hover:shadow-lg hover:bg-neutral-800">
                Submit
            </button>
            <a href={require('../../assets/student.xlsx')} download={"sample-sheet"+JSON.stringify(date)} >
                <button className="mb-2 md:mb-0 bg-neutral-900 border border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white  rounded-sm hover:shadow-lg hover:bg-neutral-800">Download Demo</button>
            </a>
        </div>*/}
      </div> 
    </div>
    </div>
:null
    }
   
    <div className='mx-2'>
      <div className='flex justify-between items-center'>
        <div>
          <h2 className='text-2xl font-bold capitalize'>Question Bank</h2>
        </div>
        <div className='space-x-3 flex'>
          <button onClick={handleOpenPopup} className="flex  bg-black text-white py-2 px-4 rounded">
            <span className='text-2xl text-white'>
				<AiFillDelete />	
			</span>
            Bulk Delete
          </button>
          <button onClick={()=> {setShowImportModal(true)}} className=" flex bg-black text-white py-2 px-4 rounded">
            <span className='text-2xl mr-2 text-white'>
				<FaFileImport />	
			</span>
            Import
          </button>
          <button onClick={handleOpenPopup} className="flex bg-black text-white py-2 px-4 rounded">
            <span className='text-2xl text-white'>
				<IoMdAdd />	
			</span>
            Add Question
          </button>
        </div>
      </div>
      <div className='w-full mt-4 relative'>
        <MaterialReactTable enableFullScreenToggle={false} columns={heading} data={data} />
        {isPopupOpen && (
            
          <div className="absolute top-24 left-0 w-full h-full flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md mt-[9rem]" style={{ width:'90%', zIndex: 10 }}>
                <div className='flex justify-end' onClick={handleClosePopup}>
                  <span  className='text-neutral-50  hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
                </div>
              <QuestionAddForm />
             
            </div>
          </div>
        )}
        {isEditPopupOpen && (
          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
            <div className='bg-white p-4 rounded shadow-md' style={{ width: '40%', marginTop: '-90px', zIndex: 10 }}>
              <h3 className='text-xl font-semibold mb-2'>Edit Certification</h3>
              <form>
                {/* Add form fields to edit the certificate data */}
                <div className={`bg-white my-4 p-1 flex border border-gray-200 rounded`}>
                  <input
                    name='editcertificate'
                    placeholder='Edit Course'
                    value={editQuestionData.name || ''}
                    onChange={(e) => setEditQuestionData({ ...editQuestionData, name: e.target.value })}
                    className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}
                  />
                </div>
                <button
                  type='button'
                  onClick={handleEditQuestion}
                  className='bg-green-600 text-white py-2 px-4 rounded'
                >
                  Update
                </button>
                <button
                  type='button'
                  onClick={handleCloseEditPopup}
                  className='bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded'
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  )
}

export default Question