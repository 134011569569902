import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MaterialReactTable from 'material-react-table';
import { AiFillDelete } from 'react-icons/ai'
import { FaEye} from 'react-icons/fa'
import Swal from 'sweetalert2'
import Select from 'react-select';

import { toast, ToastContainer } from 'react-toastify';


const Skill = () => {
   
    
    const [data,setData] = useState('')
    const loginToken = JSON.parse(localStorage.getItem('LoginToken'))
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false); 
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newSkill, setNewSkill] = useState('');
    const [editSkillData, setEditSkillData] = useState({});
    const [editSkillId, setEditSkillId] = useState(null);
    const [jobList, setJobList] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState('null');

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setNewSkill(''); 
    };

    const getjobs = async () => {
      const request = await axios.get(`${process.env.REACT_APP_RESUME_URL}/jobcategories`,{
        headers:{
            'Authorization':`Bearer  ${loginToken}`,
            'Accept':"application/json"
        }
    })
      const response = await request?.data
      console.log('response certification',response)
      // console.log("iddd" , response.)
      if (response?.code === 200) {
        setJobList(response?.data.map((x) => ({
          'label': x?.name,
          'value': x?.id
        })))
      }
    }

    useEffect(() => {
      getjobs();
    }, []);

    const handleAddSkill = async () => {
        try {
          const formData = new FormData();
          formData.append('name', newSkill); 
         formData.append('category_id', selectedSkill.value)
          const response = await axios.postForm(
            `${process.env.REACT_APP_RESUME_URL}/skill/add`,
            formData,
            {
              headers: {
                 'Authorization':`Bearer  ${loginToken}`,
                'Accept': 'application/json'
              }
            }
          );
    
          if (response?.data?.code === 200) {
            // Update your data state with the new course data
            // setData([...data, response.data.data]); 
            toast.success('Course Added Successfull')
            getSkill()
    
            // Close the popup
            handleClosePopup();
          }
        } catch (error) {
          console.error('Error adding course:', error);
        }
      };


	

  
    const getSkill = async()=>{
        const getTemplate = await axios.get(`${process.env.REACT_APP_RESUME_URL}/skill`,{
            headers:{
                'Authorization':`Bearer  ${loginToken}`,
                'Accept':"application/json"
            }
        })
        const response = await getTemplate?.data
        console.log('response',response)
        if(response?.data){
            setData(response?.data)
        }
    }
    useEffect(()=>{
        getSkill()
    },[])

    const handleOpenEditPopup = (cell) => {
      console.log('row' ,cell?.row?.original?.id);
      setEditSkillId(cell?.row?.original?.id);
      setIsEditPopupOpen(true);
      setSelectedSkill({
        label: cell?.row?.original?.category.name,
        value: cell?.row?.original?.category.id
      });
      setEditSkillData(cell?.row?.original);
    };
  
    // Add a function to close the edit popup form
    const handleCloseEditPopup = () => {
      setIsEditPopupOpen(false);
      setEditSkillData({});
    };
   

    const handleDelete = (cell)=>{
      console.log("Dealer",cell?.row?.original)
      Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async(result) => {
          if (result.isConfirmed) {
              try {
                  const deleteUser = await fetch(`${process.env.REACT_APP_RESUME_URL}/skill/delete/${cell?.row?.original?.id}`,{
                    method:"post",  
                    headers:{
                          'Authorization':`Bearer  ${loginToken}`,
                          'Accept':"application/json"
                      }
                  })
                  const response = await deleteUser?.json()
                  // console.log("response",response)
                  toast.success(`${response?.message}`)
                  getSkill()
                  // Swal.fire(
                  //   'Deleted!',
                  //   'Your file has been deleted.',
                  //   'success'
                  //    )          
              } catch (error) {
                  if(error?.response?.data?.errors)
                  error?.response?.data?.errors?.map((x)=>(
                      toast.error(`${x}`)
                  ))
              }
      
  }})
    }
    
    const handleEditSkill = async () => {
      try {
      const formData = new FormData();
      formData.append('name', editSkillData.name);
      formData.append('category_id', selectedSkill.value);
        const response = await axios.post(
          `${process.env.REACT_APP_RESUME_URL}/skill/update/${editSkillId}`, 
          formData, 
          {
            headers: {
              'Authorization': `Bearer  ${loginToken}`,
              'Accept': 'application/json'
            }
          }
        );
  
        if (response?.data?.code === 200) {
          const editedCourseIndex = data.findIndex((course) => course.id === editSkillId);
  
          if (editedCourseIndex !== -1) {
            const updatedData = [...data];
            updatedData[editedCourseIndex] = response.data.data;
            getSkill()
            toast.success('Skill Updated');
          }
          handleCloseEditPopup();
          getSkill();
        }
      } catch (error) {
        console.error('Error editing course:', error);
      }
    };
  
    
    const heading = [
        {
            accessorKey: 's_no',
            header: "S.No",
            size: 110,
            Cell: ({ cell, row }) => <div>{parseInt(row?.id) + 1}</div>,
           
        },
        {
            accessorKey: 'category.name',
            header: 'Category',
            size: 120,
        },
        {
            accessorKey: 'name',
            header: 'Skills',
            size: 150,
        },
        {
            header:"Action",
            size: 100,
            Cell:({cell})=>
              <>
              {/* <button onClick={(e)=>handleProfileView(e,cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'><FaEye size={20}/></button> */}
              <button onClick={() => handleOpenEditPopup(cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'>
                <FaEye size={20} />
              </button>
              <button onClick={()=>handleDelete(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><AiFillDelete size={20}/></button>
              </>  
        },
       
    ]



  return (

  <div className='w-full'>
   <ToastContainer 
      position='top-center'
      autoClose={3000}/>
   
    <div className='mx-2'>
      <div className='flex justify-between items-center'>
        <div>
          <h2 className='text-2xl font-bold capitalize'>Skills</h2>
        </div>
        <div>
          <button onClick={handleOpenPopup} className="bg-black text-white py-2 px-4 rounded">
            Add Skill
          </button>
        </div>
      </div>
      <div className='w-full mt-4 relative'>
        <MaterialReactTable enableFullScreenToggle={false} columns={heading} data={data} />



        {isPopupOpen && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md" style={{ width:'40%', marginTop: '-90px' , zIndex: 10 }}>
              <h3 className="text-xl font-semibold mb-2">Add Skill</h3>
              <form>
              <div className="bg-white my-4 flex border border-gray-200 rounded">
                <Select
                  name="skill"
                  value={selectedSkill}
                  onChange={setSelectedSkill}
                  options={jobList}
                  placeholder="Select Discipline"
                  className='w-full'
                />
              </div>

                  <div className={`bg-white my-4 p-1 flex border border-gray-200 rounded`}>
                
                   <input
                      name='addskill'
                      placeholder='Add Skill'
                      value={newSkill}
                      onChange={(e) => setNewSkill(e.target.value)}
                      className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}
                    />
                  </div>
                  <button
                    type='button'
                    onClick={handleAddSkill}
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                  >
                    Add
                  </button>
                  <button
                    type='button'
                    onClick={handleClosePopup}
                    className="bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded"
                  >
                    Cancel
                  </button>
                </form>
            </div>
          </div>
        )}





        {isEditPopupOpen && (
          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
            <div className='bg-white p-4 rounded shadow-md' style={{ width: '40%', marginTop: '-90px', zIndex: 10 }}>
              <h3 className='text-xl font-semibold mb-2'>Edit Skill</h3>
              <form>
                {/* Add form fields to edit the course data */}
                <div className="bg-white my-4 flex border border-gray-200 rounded">
                <Select
                  name="category"
                  value={selectedSkill}
                  onChange={setSelectedSkill}
                  options={jobList}
                  placeholder="Select Qualification"
                  className="w-full"
                />
              </div>
                <div className={`bg-white my-4 p-1 flex border border-gray-200 rounded`}>
                  <input
                    name='editskill'
                    placeholder='Edit Skill'
                    value={editSkillData.name || ''}
                    onChange={(e) => setEditSkillData({ ...editSkillData, name: e.target.value })}
                    className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}
                  />
                </div>
                <button
                  type='button'
                  onClick={handleEditSkill}
                  className='bg-green-600 text-white py-2 px-4 rounded'
                >
                  Update
                </button>
                <button
                  type='button'
                  onClick={handleCloseEditPopup}
                  className='bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded'
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}




      </div>
    </div>
  </div>
  )
}

export default Skill



