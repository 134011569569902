import React from 'react'
import {
  HiOutlineViewGrid,
  HiUserGroup,
  HiUserAdd
} from 'react-icons/hi'
import {FaFileInvoice} from 'react-icons/fa'
import {ImInsertTemplate} from 'react-icons/im'
import {BsFillBookFill} from 'react-icons/bs'
import { RiAdvertisementLine } from 'react-icons/ri'
import { TbTruckDelivery } from 'react-icons/tb'
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { HiDocumentDuplicate } from "react-icons/hi2";
import { PiExam } from "react-icons/pi";

const localData = JSON.parse(localStorage.getItem('Login'))

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/adminpanel/admindashboard',
    role: ["admin", "employee", "other"],
    icon: <HiOutlineViewGrid />
  },
  {
    key: 'certification',
    label: 'Certification',
    path:'/adminpanel/admindashboard/certification',
    role: ["admin"],
    icon: <BsFillBookFill />
  },
  {
    key: 'qualification',
    role: ["admin"],
    label: 'Qualification',
    path: '/adminpanel/admindashboard/qualification',
    icon: <HiUserAdd />
  },
  {
    key: 'discipline',
    label: 'Discipline',
    path: '/adminpanel/admindashboard/discipline',
    role: ["admin"],
    icon: <HiUserGroup />
  },

  {
    key: 'category',
    role: ["admin"],
    label: 'Category',
    path: '/adminpanel/admindashboard/category',
    icon: <FaFileInvoice />
  },
  {
    key: 'skill',
    role: ["admin"],
    label: 'Skill',
    path: '/adminpanel/admindashboard/skill',
    icon: <FaFileInvoice />
  },
  
  {
    key: 'template',
    role: ["admin"],
    label: 'Template',
    path: '/adminpanel/admindashboard/templates',
    icon: <ImInsertTemplate />
  },
  {
    key: 'advertisement',
    role: ["admin"],
    label: 'Advertisement',
    path: '/adminpanel/admindashboard/advertisement',
    icon: <RiAdvertisementLine />
  },
  {
    key: 'purchaseorder',
    role: ["admin"],
    label: 'Purchaseorder',
    path: '/adminpanel/admindashboard/purchaseorder',
    icon: <TbTruckDelivery />
  },
  // {
  //   key: 'question',
  //   role: ["admin"],
  //   label: 'Question',
  //   path: '/adminpanel/admindashboard/questions',
  //   icon: <BsFillQuestionSquareFill  />
  // },
  {
    key: 'question',
    role: ["admin"],
    label: 'Quiz Test',
    // path: '/adminpanel/admindashboard/questions',
    icon: <BsFillQuestionSquareFill />,
    subLinks: [
      {
        key: 'category',
        label: 'Category',
        path: '/adminpanel/admindashboard/question/examcategory',
        icon: <HiDocumentDuplicate />
      },
      {
        key: 'subcategory',
        label: 'SubCategory',
        path: '/adminpanel/admindashboard/question/examsubcategory',
        icon: <HiDocumentDuplicate />
      },
      {
        key: 'questions',
        label: 'Questions Bank',
        path: '/adminpanel/admindashboard/question/questionbank',
        icon: <HiDocumentDuplicate />
      },
      {
        key: 'answers',
        label: 'Online Exams',
        path: '/adminpanel/admindashboard/question/onlineexam',
        icon: <PiExam />,
      },
      
    ],
  },
]


export const DASHBOARD_SIDEBAR_SETTING_LINKS = [
  {
    id: 1,
    name: "My Profile",
    link: "myprofile",
    areaHidden: "",
  },
  {
    id: 2,
    name: "Roles & Permission",
    link: "rolesandpermission",
    areaHidden: "",
  },
]