import React from 'react';

const Mission = () => {
  return (
    <div className="lg:flex lg:flex-row flex flex-col justify-center items-center my-12 lg:space-x-12 ">
      <div className="lg:w-1/3 w-4/5 h-[22rem] md:h-[20rem]  p-6 border-2 border-blue-400 rounded-md shadow-2xl transition-transform transform hover:scale-105">
        <h2 className="text-3xl font-opensans font-semibold mb-3 text-center text-yellow-500">OUR VISION</h2>
        <p className="text-gray-800 font-opensans font-semibold text-justify">
        Our Vision is to Empower every educational
        institute with affordable technology solutions
        and train students with the latest technology
        and tools to achieve their goals as well as help
        them to succeed in the future.
        We create the future of the country and shape
        it with the help of technology empower them
        for life’s goals and train them to be ready for
        coming challenges.
        </p>
      </div>
      <div className="lg:w-1/3 w-4/5 lg:mt-0 mt-8 md:h-[20rem] h-[25rem] mx-4 p-6 border-2 border-blue-400 rounded-md shadow-2xl transition-transform transform hover:scale-105">
        <h2 className="text-3xl font-semibold mb-3 text-center text-yellow-500">OUR MISSION</h2>
        <p className="text-gray-800 font-opensans font-semibold text-justify">
        We provide 360-degree end-to-end solutions
        for every educational institution and help them
        to grow and enhance the study base as per
        the needs of corporates, we also help the
        educational institution's students to get
        placed in their dream company. We provide
        training in the latest technology to every
        student and get them ready for their dreams.
        We are on a mission to empower every
        student who studies technology and who has
        a bond with us.
        </p>
      </div>
    </div>
  );
};

export default Mission;
