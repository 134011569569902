import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MaterialReactTable from 'material-react-table';
import { FaDownload, FaEye, FaFileImport } from 'react-icons/fa';
//import {AiFillEye} from 'react-icons/ai'
import {AiTwotoneDelete} from 'react-icons/ai'
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
//import pdf from '../../components/AUTOMATED_RESUME_BUILDE1.pdf'
import { BsFillPencilFill } from 'react-icons/bs';

const  Purchaseorder = () => {
    const [showEditModal,setShowEditModal] = useState(false)
    const [editAdsTitle,setEditAdsTitle] = useState('')
    const [editDescription,setEditDescription] = useState('')
    const [editType,setEditType] = useState('')
    const [editURL,setEditURL] = useState('')
    const [advertiseId,setAdvertiseId] = useState('')
    const [updateFile,setUpdateFile] = useState('')
    const [selectedOrder, setSelectedOrder] = useState(null); // Initialize selectedOrder state

    
  
    const openDetailsPopup = (order) => {
      setSelectedOrder(order);
    };
  
    const closeDetailPopup = () => {
      setSelectedOrder(null);
    };
  
   

    useEffect(()=>{
		document.title="Purchaseoder"
	},[])
	

    const loginToken = JSON.parse(localStorage.getItem('LoginToken'))
   
    const handleDelete = async(cell)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                    const getAdvertise = await axios.get(`${process.env.REACT_APP_RESUME_URL}/order-detail/${cell?.row?.original?.id}`,{
                        headers:{
                            'Authorization':`Bearer ${loginToken}`,
                            'Accept':"application/json"
                        }
                    })
                    const response = await getAdvertise?.data
                    setShowApiErrorModal(true)
                    toast.error(`${response?.message}`)
                    getAdvertiseList()     
                } catch (error) {
                    setShowApiErrorModal(true)
                    console.log('error',error)
                    if(error?.response?.data?.error){
                        const errors = Object.values(error?.response?.data?.error)
                        console.log('Errors',errors)
                        errors.map((x)=>(
                            // console.log("Error",x)
                            toast.error(`${x}`)
                        ))
                    }
                    if(error?.response?.data?.message){
                        if(error?.response?.data?.error){
                            const errors = Object.values(error?.response?.data?.error)
                            console.log('Errors',errors)
                            errors.map((x)=>(
                                // console.log("Error",x)
                                toast.error(`${x}`)
                            ))
                        }
                        if(error?.response?.data?.message){
                            toast.error(`${error?.response?.data?.message}`)
                        }
                    }
                }        
            }}
            )
        }
    const handleEdit = (cell)=>{
        setShowEditModal(true)
        setEditAdsTitle(cell?.row?.original?.id)
        setEditDescription(cell?.row?.original?.order_id)
        setEditType(cell?.row?.original?.order_amount)
        setEditURL(cell?.row?.original?.order_status)
        setAdvertiseId(cell?.row?.original?.payment_time)
    }



    // const handleView = (cell)=>{
    //     // window.open(` https://cr.livelakeeducation.com/public/template_name/${cell?.row?.original?.file_name}`, '_blank', 'noreferrer')
    //     window.open(`${process.env.REACT_APP_RESUME_BASE_URL}/public/addons_image/${cell?.row?.original?.image}`, '_blank', 'noreferrer')
    //     // window.open(` https://arbbacakend.ciplcrm.org.in/public/template_name/${cell?.row?.original?.file_name}`, '_blank', 'noreferrer')
    // }
    
    const heading = [
        {
            accessorKey: 'id',
            header: "S.No",
            size: 50,
            Cell: ({ cell, row }) => <div>{parseInt(row?.id) + 1}</div>,
           
        },
        {
            accessorKey: 'order_id',
            header: 'Order Id',
            size: 100,
        },
        {
            accessorKey: 'template.template_type',
            header: 'Template Type',
            size: 100,
        },
      
        {
            accessorKey: 'order_amount',
            header: 'Amount',
            size: 100,
        },
       
        {
            accessorKey: 'order_status',
            header: 'Order Status',
            size: 100,
        },
        {
            accessorKey: 'payment_time',
            header: 'Time',
            size: 100,
        },
        // {
        //     accessorKey: 'order_currency',
        //     header: 'INR',
        //     size: 100,
        // },
        // {
        //     accessorKey: 'created_at',
        //     header: 'Created',
        //     size: 100,
        // },
        // {
        //     accessorKey: 'updated_at',
        //     header: 'Updated Time',
        //     size: 100,
        // },
        
        {
            header:"Action",
            Cell:({cell})=>
                <div className='flex'>
                <button onClick={()=>handleEdit(cell)} className='px-4 hover:shadow-lg border rounded-sm border-neutral-900 justify-center delay-75 duration-75 bg-neutral-900 mr-1 hover:bg-neutral-800 text-neutral-50 py-2 flex items-center'><span className='px-1'><FaEye size={16}/></span></button>
                {/* <button onClick={()=>handleView(cell)} className='px-4 flex rounded-sm hover:shadow-lg border border-blue-900 items-center justify-center ml-1 delay-75 duration-75 bg-blue-900 hover:bg-blue-800 text-neutral-50 py-2'><span className='px-1'><FaDownload size={20}/></span></button> */}
                <button onClick={()=>handleDelete(cell)} className='px-4 flex rounded-sm hover:shadow-lg border border-red-900 items-center justify-center ml-1 delay-75 duration-75 bg-red-900 hover:bg-red-800 text-neutral-50 py-2'><span className='px-1'><AiTwotoneDelete size={20}/></span></button>
                </div>
        },
    ]

    const [showImportModal,setShowImportModal] = useState(false)
    const [showApiErrorModal,setShowApiErrorModal] = useState(false)
   
    const [data,setData] = useState('')
    // const [adsName,setAdsName] = useState('')
    // const [adsDiscription , setAdsDescription] = useState('')
    // const [adsImageURL , setAdsImageURL] = useState('')
    // const [imageFile , setImageFile] = useState('')
    // const [addType , setAddType] = useState('')

    const getAdvertiseList = async()=>{
        const getAdvertise = await axios.get(`${process.env.REACT_APP_RESUME_URL}/order-detail`,{
            headers:{
                'Authorization':`Bearer  ${loginToken}`,
                'Accept':"application/json"
            }
        })
        const response = await getAdvertise?.data
        console.log('response',response)
        if(response?.data){
            setData(response?.data)
            console.log(data)
        }
    }

    useEffect(()=>{
        getAdvertiseList()
    },[])

    
    const navigate = useNavigate()

    useEffect(()=>{
      const data = JSON.parse(localStorage.getItem('Login'))
      if(data?.role !=="admin"){
        navigate('/admin')
      }
    },[])


  return (
    <>
    {
    showApiErrorModal?
    <ToastContainer autoClose={8000}/>
    :null
    }
    {
        showEditModal?
        <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
        <div className="absolute bg-black opacity-90 inset-0 z-0" onClick={()=>setShowEditModal(false)}></div>
            <div className="p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
            
            <form>
                <div className='flex justify-end' onClick={()=>setShowEditModal(false)}>
                    <span  className='text-neutral-50 hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
                </div>
            
                <div  className="text-center w-[40rem]  mt-[-3rem]">
                    <div className='flex justify-center'>
                    <FaFileImport className='text-neutral-900' size={30}/>
                    </div>
                    <h2 className="text-xl font-bold py-4 ">Purchase Order</h2>
                    <div htmlFor="dropzone-file" className='border p-4 cursor-pointer border-neutral-900'>
                   
                        
<table>
  <tr className="bg-gray-200">
    <td className="font-bold px-4 py-2">Order ID</td>
    <td className="font-bold px-4 py-2">Template Name</td>
    <td className="font-bold px-4 py-2">Price Type</td>
    <td className="font-bold px-4 py-2">Discription</td>
  </tr>

  {data.map((headi, index) => (
    <tr className="hover:bg-gray-100" key={index}>
      <td className="border px-4 py-2 font-opensans font-semibold">
        {headi.order_id}
      </td>
      <td className="border px-4 py-2 font-opensans font-semibold">
        {headi.template.template_name}
      </td>
      <td className="border px-4 py-2 font-opensans font-semibold">
        {headi.template.price}
      </td>
      <td className="border px-4 py-2 font-opensans font-semibold">
        {headi.template.discription}
      </td>
     
    </tr>
  ))}
</table>

      
                 </div>
                    </div>
            </form>
        </div>
        </div>
        :null
    }

    {
    showImportModal?
    <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
   	<div className="absolute bg-black opacity-90 inset-0 z-0" onClick={()=>setShowImportModal(false)}></div>
    <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      
    {/* <form>
        <div className='flex justify-end' onClick={()=>setShowImportModal(false)}>
            <span  className='text-neutral-50 hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
        </div>
      
        <div  className="text-center p-3 mt-[-3rem]">
            <div className='flex justify-center'>
            <FaFileImport className='text-neutral-900' size={30}/>
            </div>
            <h2 className="text-xl font-bold py-4 ">Import File</h2>
            <div htmlFor="dropzone-file" className='border-2 p-4 cursor-pointer border-dashed border-neutral-900'>
                <div className='text-left px-1 py-1 flext justify-center'>
                    <label className='block'>Image Name<span className='text-red-500'>*</span></label>
                    <input required placeholder='Image Name' onChange={(e)=>setAdsName(e.target.value)} className="border w-full border-neutral-800 p-2" />
                </div>
                <div className='text-left px-1 py-2 flext justify-center'>
                    <label className='block'>Image Description<span className='text-red-500'>*</span></label>
                    <input required placeholder='Image Description' onChange={(e)=>setAdsDescription(e.target.value)} className="border w-full border-neutral-800 p-2" />
                </div>
                <div className="text-left px-1 py-2 flext justify-center">
                        <label className="block">Price Type<span className="text-red-500">*</span></label>
                        <select
                            required
                            onChange={(e) => setAddType(e.target.value)}
                            className="border w-full border-neutral-800 p-2 mt-1"
                        >
                            <option value="" disabled selected className="text-gray-400">Ads Type</option>
                            <option value="ad_top">Top Ads</option>
                            <option value="ad_bottom">Bottom Ads</option>
                        </select>
                    </div>
                <div className='text-left px-1 py-2 flext justify-center'>
                    <label className='block'>Ads URL<span className='text-red-500'>*</span></label>
                    <input required placeholder='Image URL' onChange={(e)=>setAdsImageURL(e.target.value)} className="border w-full border-neutral-800 p-2" />
                </div>
                 
             
               
                <div className='flex flex-col justify-center p-1 '>
                    <label className='block ml-[-17rem] font-opensans'>Ads Image<span className='text-red-500'>*</span></label>
                    <input required id="dropzone-file"  accept="image/jpeg, image/jpg, image/png" type="file" className="border file:text-neutral-50 w-full file:bg-neutral-800 border-neutral-800 mt-1" onChange={(e)=>setImageFile(e.target.files[0])} />  
                    <p className="text-sm text-neutral-800 ">Choose your JPEG/JPG or PNG Image. </p>
                </div>
            
            </div>
        </div>
        <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button type='submit' onClick={async(e)=>{
                e.preventDefault()
                if(imageFile){
                    const formData= new FormData()
                    formData.append('description',adsDiscription)
                    formData.append('site_url',adsImageURL)
                    formData.append('image',imageFile)
                    formData.append('type',addType)
                    formData.append('title',adsName)
                    try {
                        console.log(imageFile);
                        const sendFile = await axios.post(`${process.env.REACT_APP_RESUME_URL}/addons/store`,formData,{
                            headers:{
                                'Authorization':`Bearer  ${loginToken}`,
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            }
                        })
                        const responseData = await sendFile?.data
                        if(responseData){
                            setShowApiErrorModal(true)
                            toast.success(`${responseData?.message}`)
                            setShowImportModal(false)
                            getAdvertiseList()
                        }if(responseData?.code === 404){
                            toast.error(`${responseData?.data}`)
                        }
                        
                    } catch (error) {
                        setShowApiErrorModal(true)
                       
                        if(error?.response?.data?.error){
                            const errors = Object.values(error?.response?.data?.error)
                            console.log('Errors 1',errors)
                            errors.map((x)=>(
                               
                                toast.error(`${x}`)
                            ))
                        }
                        if(error?.response?.data?.message){
                            if(error?.response?.data?.error){
                                const errors = Object.values(error?.response?.data?.error)
                                console.log('Errors 2',errors)
                                errors.map((x)=>(
                                  
                                    toast.error(`${x}`)
                                ))
                            }
                            if(error?.response?.data?.message){
                                toast.error(`${error?.response?.data?.message}`)
                            }
                        }
                    }
                }
                }} className="mb-2 md:mb-0 bg-neutral-900 border rounded-sm border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white hover:shadow-lg hover:bg-neutral-800">
                Submit
            </button>
         
        </div>
      </form> */}
    </div>
    </div>
:null
    }
    <div className='w-full'>
    <div className='mx-2'>
        <div className='flex  justify-between items-center'>
            <div>
                <h2 className='text-2xl font-bold capitalize'> PurchaseOrder</h2>   
            </div>
        </div>
        <div className='w-full mt-4'>
            <MaterialReactTable enableFullScreenToggle={false} columns={heading} data={data} />
        </div>
    </div>
</div>
    </>
  )
}

export default Purchaseorder




