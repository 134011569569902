import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles

const CyberCarousel = () => {
  return (
    <div className="carousel-container"  style={{ height: '200px' }}>
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000} // Time in milliseconds between slides
        
      >
        <div>
          <img src="/assets/Cybersecurity/crousel11.png" alt="Image 1" />
        </div>
        <div>
          <img src="/assets/Cybersecurity/crousel22.png" alt="Image 2" />
        </div>
        <div>
          <img src="/assets/Cybersecurity/crousel33.png" alt="Image 3" />
        </div>
      </Carousel>
    </div>
  );
};

export default CyberCarousel;
