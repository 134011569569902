const question = [
    {
        id: 1,
		question: "What Is College Management Software?",
        answer: "College Management Software is a comprehensive computer-based system designed to streamline and automate various administrative, academic, and management tasks within a college or educational institution, including student records, admissions, attendance, scheduling, and financial management.",
    },
    {
        id: 2,
		question: "What Is Your Data Safety Policy?",
        answer: "CampusBezz offers you complete data ownership. No one has direct access to your data, ensuring your information is secure. To further safeguard your data, CampusBezz provides a data protection agreement, assuring you the ability to access and download your data in Excel format at any time.",
    },
    {
        id: 3,
		question: "How Online Admission System Ease Institute Admission?",
        answer: "Online admission software eliminates the hassle of standing in long queues, enables immediate online transactions, and provides real time stats of admission-related tasks by streamlining the entire student admission procedure.",
    },
    {
        id: 4,
		question: "Does Online Fee Payment Provides EMI Facility For Students?",
        answer: "Online fee payment options offer students the flexibility of paying their educational institution's fees in convenient installments and EMIs, in accordance with the guidelines set by higher management authorities. This eases the financial burden and ensures affordability for students.",
    },
    {
        id: 5,
		question: "How Can Online Assessments Software Be Helpful For Students?",
        answer: "Online assessment software provides students with valuable resources like e-content, question banks, interactive quizzes, engaging video tutorials, and assignments. This empowers them to participate in and successfully complete online assessments and exams with ease, enhancing their learning experience.",
    }

];

export default question;