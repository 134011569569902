import React ,{useState} from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const QuestionAddForm = () => {

    const [editorData, setEditorData] = useState('');
    const [subject , setSubject] = useState('');
    const [questionType , setQuestionType] = useState('');
    const [questionlevel , setQuestionlevel] = useState('');
    const [studentClass , setStudentClass] = useState('');
    const [studebtSection , setStudentSection] = useState('');

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
      };
   
    const handlesubmit = () =>{
       console.log(editorData)
    }

    

  return (
   <>
     
        <h3 className="text-2xl font-bold mb-2 -mt-8">Add Question</h3>
         <form >
        <div className='flex space-x-2'>
          <div className=" text-left px-1 py-2 flex flex-col w-1/2 justify-center">
                       <label className="block font-opensans font-semibold">Subject<span className="text-red-500">*</span></label>
                       <select
                           required
                           // value={edittemplateType}
                           onChange={(e) => setSubject(e.target.value)}
                           className="border w-full border-neutral-800 p-2 mt-1"
                       >
                           <option value="" disabled selected className="text-gray-400 font-opensans">Select</option>
                           <option value="hindi">Hindi</option>
                           <option value="english">English</option>
                           
                       </select>
                   </div>
               
                   <div className="text-left px-1 py-2 flex flex-col w-1/2 justify-center">
                       <label className="block font-opensans font-semibold">Question Type<span className="text-red-500 ">*</span></label>
                       <select
                           required
                           // value={editPriceType}
                           onChange={(e) => setQuestionType(e.target.value)}
                           className="border w-full border-neutral-800 p-2 mt-1"
                       >
                           <option value="" disabled selected className="text-gray-400 font-opensans">Select</option>
                           <option value="single">Single Choice</option>
                           <option value="multiple">Multiple Choice</option>
                           <option value="condition">True/False</option>
                           <option value="descriptive">Descriptive</option>
                       </select>
                   </div>

            </div>
            <div className='flex space-x-2'>
            <div className=" text-left px-1 py-2 flex flex-col w-1/2 justify-center">
                       <label className="block font-opensans font-semibold">Question level<span className="text-red-500">*</span></label>
                       <select
                           required
                           // value={edittemplateType}
                           onChange={(e) => setQuestionlevel(e.target.value)}
                           className="border w-full border-neutral-800 p-2 mt-1"
                       >
                           <option value="" disabled selected className="text-gray-400 font-opensans">Select</option>
                           <option value="low">Low</option>
                           <option value="medium">Medium</option>
                           <option value="high">High</option>
                       </select>
                   </div>
               
                   <div className="text-left px-1 py-2 flex flex-col w-1/2 justify-center">
                       <label className="block font-opensans font-semibold">Class <span className="text-red-500 ">*</span></label>
                       <select
                           required
                           // value={editPriceType}
                           onChange={(e) => setStudentClass(e.target.value)}
                           className="border w-full border-neutral-800 p-2 mt-1"
                       >
                           <option value="" disabled selected className="text-gray-400 font-opensans">Select</option>
                           <option value="one">1</option>
                           <option value="two">2</option>
                       </select>
                   </div>

           </div>
           <div className=" text-left px-1 py-2 flex flex-col w-1/2 justify-center">
                       <label className="block font-opensans font-semibold">Section<span className="text-red-500">*</span></label>
                       <select
                           required
                           // value={edittemplateType}
                           onChange={(e) => setStudentSection(e.target.value)}
                           className="border w-full border-neutral-800 p-2 mt-1"
                       >
                           <option value="" disabled selected className="text-gray-400 font-opensans">Select</option>
                           <option value="aa">A</option>
                           <option value="bb">B</option>
                       </select>
                   </div>
                   <div className=" text-left px-1 py-2 flex flex-col justify-center">
                       <label className="block font-opensans font-semibold mb-2">Questions<span className="text-red-500">*</span></label>
                    

                            <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onChange={handleEditorChange}
                                />
                      
                   </div>
                   <button
                    type='button'
                    onClick={handlesubmit}
                    className="bg-blue-500 mt-4 text-white py-2 px-4 rounded"
                  >
                    submit
                  </button>
            </form>
     
   </>
  )
}

export default QuestionAddForm