import React from 'react';

const CollegeFeatures = () => {


    const cardData = [
        {
          title: 'Permission-Based Access',
          description: 'Permission-based access provides various academic and administrative roles, streamlining college operations, enhancing security, transparency, and compliance while reducing audit costs.',
          imageUrl: '/assets/feature/Rolebased.png', 
        },
        {
          title: 'Comprehensive Solution',
          description: 'The software offers an Comprehensive solution for college student enrollment, attendance, assessment, and online result generation, boosting campus operational efficiency.',
          imageUrl: '/assets/feature/onestepsolution.png', 
        },
        {
          title: 'Exceptionally Versatile',
          description: 'The ERP is tailored for colleges evolving needs, boasting high adaptability to handle complex tasks and staying current with edTech trends through technological flexibility and versatile for your college.',
          imageUrl: '/assets/feature/highly.png', 
        },
        {
          title: 'Decision System',
          description: 'The ERP enhances college decision-making by integrating departments, analyzing data, promoting efficiency through inventory reduction, increased productivity, and data processing optimization.',
          imageUrl: '/assets/feature/decision.png', 
        },
        {
          title: 'Integrated Processes and Validations',
          description: 'Inbuilt workflows standardize college operations, enhancing accountability,saving time and resources with tracking and reporting features, ultimately boosting overall performance of your college.',
          imageUrl: '/assets/feature/workflow.png', 
        },
        {
          title: ' Continuous 24*7 Availability',
          description: 'The college ERP provides seamless flexibility, accommodating various accounting standards and operations without disruption, while offering 24/7 access to student data from anywhere.',
          imageUrl: '/assets/feature/247.png', 
        },
      ];

      
  return (
    <div className="flex justify-between items-center p-4 mb-4">
      <div>
         <div className='flex justify-center flex-col items-center md:mb-16 mb-8'>
              <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Features of College Management System Software</h1>
              <img src='/assets/cap.png' alt='cap image' className='w-1/2 md:w-1/5' />
        </div>
        <div className="lg:flex flex-row-reverse md:space-x-6 md:mx-16 mx-8 ">
          <div className="lg:w-1/2 flex justify-center">
            <img
              src="/assets/collegefeatures.jpg"
              alt="ERP CollegeFeatures"
              className="max-w-[100%] h-[70%] rounded-xl hover:scale-125 transition duration-300"
            />
          </div>
          <div className="lg:w-1/2 lg:mt-0 mt-8">
            <p className="text-gray-600 font-opensans font-semibold text-justify mb-8">
               CampusBeez cloud-based college management software is a versatile solution tailored for colleges worldwide. With a user base exceeding more than 2000 schools, colleges, and universities in India, our system offers centralized campus management for a range of institutions, including arts, science, commerce, and law colleges affiliated with universities. Additionally, we provide specialized ERP solutions for engineering, pharmacy, medical, management, and autonomous colleges, ensuring comprehensive support across diverse educational domains.
            </p>
            <button className="bg-blue-900 text-white mb-5 px-6 py-3 rounded-full text-base md:text-lg font-semibold hover:bg-yellow-600 transition duration-300">
            Request demo
          </button>
            
          </div>
        </div>
        <div className="md:flex w-full flex-wrap gap-6 md:mx-16 mx-4 mb-8">
          {cardData.map((card, index) => (
            <div key={index} className="lg:w-[29%] md:w-[38%] w-[91%] mb-8">
              <div className="bg-white rounded-lg p-4 shadow-md hover:bg-blue-200 hover:scale-110 transition duration-300">
                <img
                  src={card.imageUrl}
                  alt={card.title}
                  
                  className="w-[8rem] h-32 object-cover mx-auto rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mb-2 text-center">{card.title}</h3>
                <p className="text-gray-600 font-opensans font-semibold ">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default CollegeFeatures;
