import React from 'react';
import { BiSolidRightArrow } from 'react-icons/bi';

const Implement = () => {
  return (
    <div className="flex justify-between items-center p-4 mb-4">
      <div>
         <div className='flex justify-center flex-col items-center md:mb-16 mb-8'>
              <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Cloud ERP Implementation</h1>
              <img src='/assets/cap.png' alt='cap image' className='w-1/2 md:w-1/5' />
        </div>
        <div className="lg:flex md:space-x-6 md:mx-16 mx-8 ">
          <div className="lg:w-1/2 md:mt-24">
            <img
              src="/assets/erpimplememnt.png"
              alt="ERP Implement"
              className="max-w-[96%] h-[70%] rounded-xl hover:scale-105 transition duration-300"
            />
          </div>
          <div className="lg:w-1/2 lg:mt-0 mt-8">
            <p className="text-gray-600 font-opensans text-justify">
            Implementing a Cloud ERP system in the educational sector offers a transformative approach to optimize and productivity enhancement through comprehensive campus automation. This technology suite not only streamlines operations but also provides a robust foundation for data management, security, and accessibility without the need for significant upfront investments.
            </p>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify'><span className='font-opensans font-semibold'>Optimization: </span>Cloud ERP streamlines various processes, helping educational institutions make the most of their resources. It optimizes staff time, reduces manual work, and enhances overall efficiency.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify'><span className='font-opensans font-semibold'>Data Accessibility and Storage:</span> With a centralized data repository, institutions ensure that essential information is readily accessible to authorized users. This centralized storage simplifies data management and retrieval.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify'><span  className='font-opensans font-semibold'>Security and Compliance:</span> Role-based access and automated data backup ensure the security of institutional data.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify'><span className='font-opensans font-semibold'>Cost-Effective Implementation:</span> Cloud ERP is cost-effective as it eliminates the need for substantial hardware and infrastructure investments. </p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify'><span className='font-opensans font-semibold'>Enhanced Communication: </span>Improved communication within the institution fosters better collaboration among staff and faculty, resulting in heightened operational efficiency.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Implement;
