import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {routes as MapRoutes} from './routes/routes'
import LoginAdmin from './loginadmin/LoginAdmin';
import DashboardLayout from './dashboard/DashboardLayout';

import HomeDashboard from './homedashboard/HomeDashboard';

const AdminPanel = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/adminpanel'; // Check if the current route is /admin

  return (
    <div>
      {isLoginPage && <LoginAdmin />} {/* Render LoginAdmin only for the /admin route */}
      <Routes>
        {/* <Route path="/admindashboard" element={<DashboardLayout />} /> */}
        {/* <Route path="/admindashboard/dashboarddetails" element={<Dashboarddetails />} /> */}

        <Route  path="/admindashboard" element={<DashboardLayout/>}>
            <Route index element={<HomeDashboard/>}/>
            
          {
            MapRoutes&& MapRoutes.map((x,index)=>(
            <Route path={x.path} element={x?.route} key={index}/>))
          }
      </Route>
      </Routes>
    </div>
  );
}

export default AdminPanel;
