import Advertisement from "../advertise/Advertisement"
import Category from "../category/Category"
import Certification from "../certification/Certification"
import Discipline from "../discipline/Discipline"
import Qualification from "../qualification/Qualification"
import Skill from "../skill/Skill"
import Templates from "../templates/Templates"
import Purchaseorder from "../order/purchaseorder"
import Question from "../question/Question"
import OnlineExam from "../question/OnlineExam"
import ExamCategory from "../question/category/ExamCategory"
import ExamSubCategory from "../question/subcategory/ExamSubCategory"


export const routes =[
    {
        route:<Certification/>,
        path:'/admindashboard/certification'
    },
    {
        route:<Qualification/>,
        path:'/admindashboard/qualification'
    },
    {
        route:<Templates/>,
        path:'/admindashboard/templates'
    },
    {
        route:<Discipline/>,
        path:'/admindashboard/discipline'
    },
    {
        route:<Category/>,
        path:'/admindashboard/category'
    },
    {
        route:<Skill/>,
        path:'/admindashboard/skill'
    },
    {
        route:<Advertisement/>,
        path:'/admindashboard/advertisement'
    },
    {
        route:<Purchaseorder/>,
        path:'/admindashboard/purchaseorder'
    },
    {
      route:<Question />,
      path:'/admindashboard/question/questionbank'  
    },
    {
        route: <OnlineExam />,
        path:'/admindashboard/question/onlineexam'  
    },
    {
        route: <ExamCategory />,
        path:'/admindashboard/question/examcategory'  
    },
    {
        route: <ExamSubCategory />,
        path:'/admindashboard/question/examsubcategory'  
    },
    
    
    
]

// export const employeeRoutes = [
//     {
//         route:<ProfileEdit/>,
//         path:'profileview'
//     },
//     {
//         route:<UpdatePassword/>,
//         path:'updatepassword'
//     },
// ]