const dataproduct = [
    // {
    //     id: 1,
	// 	title: 'School ERP',
    //     description: 'CampusBeez Cloud-based School ERP is not just software it is your partner in revolutionizing education. We are here to transform the way you manage your campus and elevate learning outcomes. How do we do it? By automating every aspect of student life, faculty management, and administration. But that is not all. We go a step further—our Smart Analytics provide you with actionable insights, turning data into decisions. Join us in shaping the future of education.',
    //     imageUrl: '/assets/SchoolERP.jpg',
    //     points: [
    //         ' Cutting-Edge Technology and Seamless Integration',
    //         ' Elevate engagement with intelligent tools, including BI, biometrics, mobile apps, automated notifications, and real-time tracking, fostering transparency and collaboration.',
    //         ' Universal Compatibility with Educational Boards including CBSE, ICSE, State boards, IB, and Cambridge.',
    //       ],
    // },
    {
        id: 2,
        title: 'College ERP',
        description: 'Empower colleges with the CampusBeez College Management System, revolutionizing workflows, and boosting operational efficiency through automated Student-Faculty lifecycles and Campus Administration',
        imageUrl: '/assets/campusbeezImage.png',
        points: [
            ' Unlock Accreditation Data Management Software: Empowering Higher Education Institutions (HEIs) to efficiently handle historical institutional data, essential documents, and generate compliance reports for successful accreditation.',
            ' Outcome-Based Education: Improve student learning outcomes by setting prior goals & assessing student on different parameters such as : memory skills, analysing skills etc.Fully compliant with NBA ( National Board of Accreditation )',
            ' Elevate Education Through Outcome-Based Learning: Enhance student performance by setting clear objectives and evaluating various skills, including memory and analysis. NBA compliant.',
          ],
    },
    {
        id: 3,
        title: 'University ERP',
        description: 'CampusBeez University Management Software empowers Universities and Affiliated Colleges with streamlined processes, unified data analysis, and comprehensive operational tracking—all within one integrated system.',
        imageUrl: '/assets/campusbeezImage university.png',
        points: [
            ' The ERP Campus System bridges the crucial link between colleges and universities, fostering data sharing while preserving their distinct autonomy—seamless collaboration with independence.',
            ' Simplify Student Information, Faculty Management & Campus Administration. Experience streamlined operations for efficient and effective management',
            ' Revolutionize College Affiliation Management: Empower colleges to effortlessly submit necessary documents in any format, directly to the server.',
          ],
    },
    {
        id: 4,
        title: 'Trust Management',
        description: 'CampusBeez Trust Management System: A unified solution for schools, colleges, polytechnics, and ITIs across multiple campuses in diverse cities. Centralized data integration, precise analytics, and real-time monitoring for seamless information flow.',
        imageUrl: '/assets/trust.png',
        points: [
            ' Effortless Management of Student Data, Faculty, and Campus Administration, all unified under a single, interactive platform across Multiple Campuses.',
            ' Access Your Institutional Information and Data 24/7, Anytime, Anywhere—an interactive solution for uninterrupted connectivity and convenience.',
            ' Gain Valuable Insights into Core Institutional Operations with Summarized Graphical Reports via Smart Analytics Dashboards. Make Faster, Accurate Decisions Interactively.',
          ],
    }


];

export default dataproduct;