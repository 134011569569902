import React from 'react'
import CyberCrousel from './CyberCrousel'
import Cybercards from './Cybercards'
import { Helmet } from 'react-helmet';

const Program = () => {
  return (
    <div className='md:mt-32'>
          <Helmet>
        
        <meta charset="utf-8" />
        <title>Campusbeez | Programs, Cyber Security, Ethical Hacking Workshop,Cyber safety,Cyber safety tips,Ethical hacking tutorials</title>
        <meta name="description" content="Are you looking for best institute in cyber security? Cyber crime awareness society providing the best course in cyber security in jaipur and delhi. cyber security course, cyber security training, learn cyber security."/>
        <meta name="keywords" content="cyber institute, cyber course, cyber training, cyber coaching, cyber center, cyber learn, cyber  tuition, security institute, security course, security training, security coaching, security center, security learn, security tuition"/>
        <meta name="robots" content="index,follow"/>
        <meta name="copyright" content="Livelake Education"/>
        <meta name="email" content="support@campusbeez.com"/>
        <meta name="language" content="EN"/>
        <meta name="DC.Publisher" content="Campusbeez"/>
        <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
        <meta name="DC.Language" content="en"/>
        <meta name="Distribution" content="Global"/>
        <meta name="geo.region" content="IN"/>
        <link rel="canonical" href="https://campusbeez.com/"/>
        <meta name="geo.placename" content="India"/>
        <meta name="geo.position" content="Gurgaon"/>
        <meta name="geo.region" content="usa"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>

    </Helmet>
     <CyberCrousel />
     <Cybercards />
     
    </div>  
  )
}

export default Program