import React, { useState } from 'react';
import {FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import {BiPhoneCall } from 'react-icons/bi';
// import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './contact.css'
import { Helmet } from 'react-helmet';


const Contact = () => {

  
  const [newData, setNewData] = useState({
    name: '',
    mobile:'',
    email: '',
    institute:'',
    city:'',
    state:'',
    datedemo:'',
    time:'',
    message: '',

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = {
      name: newData.name,
      mobile: newData.mobile,
      email: newData.email,
      institute: newData.institute,
      city: newData.city,
      state: newData.state,
      datedemo: newData.datedemo,
      time: newData.time,
      message: newData.message,
    };
  
    // Convert formData to JSON
    const jsonData = JSON.stringify(formData);
  
    try {
      const response = await fetch('https://erp.campusbeez.com/api/webservice/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
      if (data.status === "1") {
        toast.success('Thank you!'); // Success notification
        setNewData({
          name: '',
          mobile: '',
          email: '',
          institute: '',
          city: '',
          state: '',
          datedemo: '',
          time: '',
          message: '',
        });
      } else {
        toast.error('Something went wrong'); // Error notification
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  
    // Clear the form fields
   
  };
  
  return (
    
    <div>
     <Helmet>
        
        <meta charset="utf-8" />
        <title>Get in Touch with us through Contact us form |Campusbeez</title>
        <meta name="description" content="Write to us for any query and information."/>
        <meta name="keywords" content="Campusbeez ERP Solutions contact details,Campuseez contact details."/>
        <meta name="robots" content="index,follow"/>
        <meta name="copyright" content="Livelake Education"/>
        <meta name="email" content="support@campusbeez.com"/>
        <meta name="language" content="EN"/>
        <meta name="DC.Publisher" content="Campusbeez"/>
        <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
        <meta name="DC.Language" content="en"/>
        <meta name="Distribution" content="Global"/>
        <meta name="geo.region" content="IN"/>
        <link rel="canonical" href="https://campusbeez.com/"/>
        <meta name="geo.placename" content="India"/>
        <meta name="geo.position" content="Gurgaon"/>
        <meta name="geo.region" content="usa"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>

    </Helmet>

     {/* Left Fixed Column */}
     <ToastContainer/>
     <div className="fixed left-0 top-0 flex flex-col justify-end h-screen text-white p-4 ">
        <div className="mb-4">
          <a href="https://wa.me/+918860014004" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={35}  className='bg-green-500'/>
          </a>
        </div>
        <div className="mb-4">
          <a href="tel:8860014004">
            <BiPhoneCall size={35}  className='bg-blue-600'/>
          </a>
        </div>
        <div>
          <a href="mailto:support@campusbeez.com">
            <FaEnvelope size={35} className='bg-yellow-500 p-1'/>
          </a>
        </div>
      </div>


      <div
        className="bg-cover bg-center h-80 md:mt-28  "
        style={{ backgroundImage: 'url("/assets/bannercontact2.png")' }}
      >
        <div className="flex flex-col justify-center h-full bg-black bg-opacity-10 ">
          <h2 className="text-4xl text-white font-semibold mb-4 md:ml-20 ml-10">Get in Touch!</h2>
          <p className='text-white font-opensans font-semibold text-xl  md:ml-20 ml-10'>Want to get in touch? we would love to hear from you .<br /> Here's how you can reach us ....</p>
        </div>
      </div>

      
      <div className="flex md:flex-row flex-wrap justify-center items-center my-6 md:space-x-6">
       {/* Email Card */}
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img
              src="/assets/email.png"
              alt="Email"
              className="w-[9rem] h-[9rem]"
            />
          </div>
          <div className="flip-card-back bg-blue-200 rounded-xl p-4">
            
            <p className='mt-10 font-opensans font-semibold '>support@campusbeez.com</p>
            <p className='mt-6 font-opensans font-semibold '>info@livelakeeducation.com</p>
          </div>
        </div>
      </div>

      {/* Mobile Card */}
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img
              src="/assets/call.png"
              alt="Mobile"
              className="w-[9rem] h-[9rem]"
            />
          </div>
          <div className="flip-card-back bg-yellow-300 rounded-xl p-4">
            <p className='mt-10 ml-4 font-opensans font-semibold '>0124-400-6882</p> 
            <p className='mt-10 ml-4 font-opensans font-semibold '>+91-8860014004</p> 
            <p className='mt-10 ml-4 font-opensans font-semibold '>+91-7827135297</p>
          </div>
        </div>
      </div>

      
      {/* Location Card */}
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img
              src="/assets/location.png"
              alt="Location"
              className="w-[9rem] h-[9rem]"
            />
          </div>
          <div className="flip-card-back bg-blue-200 rounded-xl p-3">
            {/* <span className="text-xl">Location</span> */}
            <p className='text-justify mt-2 font-opensans font-semibold'>Epic Corporations Pvt Ltd ,Ansal API Palam Corporate Plaza, TF-427, A-Block, Palam Vihar,Gurugram,Haryana 122017 (India)</p>
          </div>
        </div>
      </div>
      </div>

      
     

      {/* Contact Form */}
      <div className="bg-blue-200 py-8">
      <h1 className='text-center text-4xl font-semibold font-opensans mb-8 text-yellow-600'>Book a Demo !</h1>
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="grid md:grid-cols-2 gap-4 lg:w-[50rem] md:w-[47rem] md:ml-[-10rem] lg:px-0 px-8">
          {/* Row 1 */}
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={newData.name}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
              Your Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={newData.email}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>

          {/* Row 2 */}
          <div className="mb-4">
            <label htmlFor="mobile" className="block text-gray-700 font-semibold mb-2">
              Mobile No.
            </label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              value={newData.mobile}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="institute" className="block text-gray-700 font-semibold mb-2">
              Name of Institute
            </label>
            <input
              type="text"
              id="institute"
              name="institute"
              value={newData.institute}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>

          {/* Row 3 */}
          <div className="mb-4">
            <label htmlFor="city" className="block text-gray-700 font-semibold mb-2">
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={newData.city}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="block text-gray-700 font-semibold mb-2">
              State
            </label>
            <input
              type="text"
              id="state"
              name="state"
              value={newData.state}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>

          {/* Row 4 */}
          <div className="mb-4">
            <label htmlFor="datedemo" className="block text-gray-700 font-semibold mb-2">
              Book a Demo
            </label>
            <input
              type="date"
              id="datedemo"
              name="datedemo"
              value={newData.datedemo}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="time" className="block text-gray-700 font-semibold mb-2">
              Time
            </label>
            <input
              type="time"
              id="time"
              name="time"
              value={newData.time}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
              // required
            />
          </div>
        </div>

        {/* Message input */}
        <div className="mb-4 md:px-0 px-8">
          <label htmlFor="message" className="block text-gray-700 font-semibold mb-2 ">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={newData.message}
            onChange={handleChange}
            className="w-full border rounded-md py-2 px-3 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
            rows="4"
            // required
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-500"
          >
            Submit
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default Contact;
