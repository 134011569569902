import React from 'react';
import { IoEarthSharp } from 'react-icons/io5';
import { FaHandsHelping } from 'react-icons/fa';
import { HiSpeakerphone } from 'react-icons/hi';
import './appbar.css'
import { Link } from 'react-router-dom';

const Appbar = () => {
  return (
    <div className="bg-blue-800 text-white px-8 flex items-center justify-end md:fixed top-0 w-full z-10 main-container">
      
      <div className="flex justify-center py-2 space-x-4 flex-wrap">
        <div className="flex items-center space-x-2">
          <Link to="/contact">
            <button className="bg-yellow-500 text-white py-1 px-4 rounded flex items-center space-x-2  hover:bg-slate-600 button-container ">
              <IoEarthSharp size={24} />
              Product Enquiry
              </button>
              </Link>
         
          <Link to="/contact">
          <button className="bg-blue-600 text-white py-1 px-4 rounded flex items-center space-x-2  hover:bg-yellow-400 button-container">
            <FaHandsHelping size={24} />
            Become a Partner
          </button>
          </Link>
        </div>
        <Link to="/hiring" >
        <button className="bg-yellow-500 text-white py-1 px-4 rounded flex items-center space-x-2  hover:bg-slate-600 button-container2 ">
          <HiSpeakerphone size={24} />
          We are Hiring
        </button>
        </Link>
      </div>
    </div>
  );
};

export default Appbar;

