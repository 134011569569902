import React, { useState } from 'react';
import { FaAngleRight, FaAngleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MobileNavMenu = ({ isOpen, handleMenuClose }) => {
  const [isCompanyMenuOpen, setIsCompanyMenuOpen] = useState(false);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const [isEducationMenuOpen, setIsEducationMenuOpen] = useState(false);
  const [isSpecialMenuOpen, setIsSpecialMenuOpen] = useState(false);
  const [isResourcesMenuOpen, setIsResourcesMenuOpen] = useState(false);

  const toggleCompanyMenu = () => {
    setIsCompanyMenuOpen(!isCompanyMenuOpen);
  };
  const toggleProductMenu = () => {
    setIsProductMenuOpen(!isProductMenuOpen);
  };
  const toggleEducationMenu = () => {
    setIsEducationMenuOpen(!isEducationMenuOpen);
  };
  const toggleSpecialMenu = () => {
    setIsSpecialMenuOpen(!isSpecialMenuOpen);
  };
  const toggleResourcesMenu = () => {
    setIsResourcesMenuOpen(!isResourcesMenuOpen);
  };

  return (
    <div className={isOpen ? 'block' : 'hidden'}>
      <div className="bg-white border-t border-gray-200">
        {/* Mobile menu items */}
        <Link
          to="/"
          className="block py-2 px-4 text-gray-800 font-opensans text-base font-semibold hover:bg-gray-100"
          onClick={handleMenuClose}
        >
          Home
        </Link>
        <div className={`border-t border-gray-200 ${isCompanyMenuOpen ? 'border-b' : ''}`}>
          <div
            className={`text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group ${
              isCompanyMenuOpen ? 'text-blue-500' : ''
            }`}
            onClick={toggleCompanyMenu}
          >
            Company
            <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
              {isCompanyMenuOpen ? (
                <FaAngleDown className="h-5 w-5" />
              ) : (
                <FaAngleRight className="h-5 w-5" />
              )}
            </span>
          </div>
          {isCompanyMenuOpen && (
            <div className="bg-white border border-gray-200 rounded shadow-lg">
              <div className="p-2 text-center">
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block pb-2  text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  About Us
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Customers
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Testimonials
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Certification
                </a>
               
              </div>
            </div>
          )}
        </div>
        <div className={`border-t border-gray-200 ${isProductMenuOpen ? 'border-b' : ''}`}>
          <div
            className={`text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group ${
              isProductMenuOpen ? 'text-blue-500' : ''
            }`}
            onClick={toggleProductMenu}
          >
            Products
            <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
              {isProductMenuOpen ? (
                <FaAngleDown className="h-5 w-5" />
              ) : (
                <FaAngleRight className="h-5 w-5" />
              )}
            </span>
          </div>
          {isProductMenuOpen && (
            <div className="bg-white border border-gray-200 rounded shadow-lg">
              <div className="p-2 text-center">
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Online Assestment Software
                </a>
                
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block py-2  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Accreditation Data Management (ADMS)
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block py-2  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Digital Evaluation System
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Admission Management
                </a>
                
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Exam Management
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  ERP Campus
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Student Information System
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Technical Institute Management
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Fee Management System
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  CSMS (School Management)
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  CCMS (College Management)
                </a>
                
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  CUMS (University Management)
                </a>
                
              </div>
            </div>
          )}
        </div>
        <div className={`border-t border-gray-200 ${isEducationMenuOpen ? 'border-b' : ''}`}>
          <div
            className={`text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group ${
              isEducationMenuOpen ? 'text-blue-500' : ''
            }`}
            onClick={toggleEducationMenu}
          >
            Education ERP
            <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
              {isEducationMenuOpen ? (
                <FaAngleDown className="h-5 w-5" />
              ) : (
                <FaAngleRight className="h-5 w-5" />
              )}
            </span>
          </div>
          {isEducationMenuOpen && (
            <div className="bg-white border border-gray-200 rounded shadow-lg">
              <div className="p-2 text-center">
                {/* <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Higher Education ERP
                </a> */}
                <Link
                  to="https://erp.campusbeez.com/" target='_blank'
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                    University ERP
                </Link>
                <Link
                  to="/college"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  College ERP
                </Link>
                <Link
                  to="https://erp.schoolsdunia.com/" target='_blank'
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  School ERP
                </Link>
                
              </div>
            </div>
          )}
        </div>
        <div className={`border-t border-gray-200 ${isSpecialMenuOpen ? 'border-b' : ''}`}>
          <div
            className={`text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group ${
              isSpecialMenuOpen ? 'text-blue-500' : ''
            }`}
            onClick={toggleSpecialMenu}
          >
            Special Features
            <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
              { isSpecialMenuOpen? (
                <FaAngleDown className="h-5 w-5" />
              ) : (
                <FaAngleRight className="h-5 w-5" />
              )}
            </span>
          </div>
          {isSpecialMenuOpen && (
            <div className="bg-white border border-gray-200 rounded shadow-lg">
              <div className="p-2 text-center">
              <Link
                  to="/comingsoon"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  
                >
                  CB App
                </Link>
                <Link
                  to="/comingsoon"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Resume Builder
                </Link>
              <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Learning Management Education
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  Biometric & RFID Attendance
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                    Library Management
                </a>
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block  pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  HR Management
                </a>
               
              </div>
            </div>
          )}
        </div>
        <div className={`border-t border-gray-200 ${isResourcesMenuOpen ? 'border-b' : ''}`}>
          <div
            className={`text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group ${
              isResourcesMenuOpen ? 'text-blue-500' : ''
            }`}
            onClick={toggleResourcesMenu}
          >
            Resources
            <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
              {isResourcesMenuOpen ? (
                <FaAngleDown className="h-5 w-5" />
              ) : (
                <FaAngleRight className="h-5 w-5" />
              )}
            </span>
          </div>
          {isResourcesMenuOpen && (
            <div className="bg-white border border-gray-200 rounded shadow-lg">
              <div className="p-2 text-center">
                
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-500 block pb-2 text-base font-opensans font-semibold"
                  onClick={handleMenuClose}
                >
                  FAQ
                </a>
               
              </div>
            </div>
          )}
        </div>
        <div className='border-y border-gray-200'>
        <div
            className='text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group'
            onClick={handleMenuClose}
          >
           
          <Link 
            to="/program">
             Program
          </Link>
           
        </div>
        <hr />
        <div
            className='text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group'
            >
              <Link 
        to="https://cbpro.campusbeez.com/"
        target='blank'>
          CB Pro
        </Link>
            </div>
       
        <hr/>
        <div
            className='text-gray-800 ml-4 py-2 font-opensans text-base font-semibold cursor-pointer relative group'
            onClick={handleMenuClose}
          >
           
          <Link 
            to="/contact">
             Contact Us
          </Link>
           
        </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavMenu;
