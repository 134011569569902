import React from 'react';

const CollegeAbout = () => {
  return (
    <div className="flex justify-between items-center p-4 ">
      <div>
         <div className='flex justify-center flex-col items-center md:mb-16 mb-6'>
              <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">College Management System Software</h1>
              <img src='/assets/cap.png' alt='cap image' className='w-1/2 md:w-1/5' />
        </div>
        <div className="lg:flex md:space-x-6 md:mx-16 mx-8 ">
          <div className="lg:w-1/2 flex justify-center">
            <img
              src="/assets/collegeerp.png"
              alt="ERP CollegeAbout"
              className="max-w-[100%] h-[70%] rounded-xl hover:scale-125 transition duration-300"
            />
          </div>
          <div className="lg:w-1/2 lg:mt-0 mt-8">
            <p className="text-gray-600 font-opensans font-semibold text-justify mb-3">
                CampusBeez college management ERP simplifies tasks like admissions, report generation, ID card creation, online communication, curriculum management, and student progress tracking while offering data analysis and remote teaching capabilities.
            </p>
            <p className="text-gray-600 font-opensans font-semibold text-justify mb-3">CampusBeez College Management System is an end-to-end solution for colleges to improve operational efficiency & institutional outcomes by automating Student-Faculty lifecycle & campus administration.</p>
            <p className="text-gray-600 font-opensans font-semibold text-justify mb-3">This college ERP software is designed keeping in mind the different operations of a college.co</p>
            
            <p className="text-gray-600 font-opensans font-semibold text-justify">CampusBeez College ERP has 25+ pro modules and 30+ inbuilt modules. It helps educators to streamline all the core activities with biometrics, BI tools, and an analytics dashboard that generates precise reports on college admission, scholarship, compliance management, etc.</p>
            
            
         
          </div>
        </div>

      </div>
      
    </div>
  );
};

export default CollegeAbout;
