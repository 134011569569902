import React, { useState, useEffect } from 'react';

const Statistics = () => {
  const [implementations, setImplementations] = useState(0);
  const [experience, setExperience] = useState(0);
  const [users, setUsers] = useState(0);
  const [teamStrength, setTeamStrength] = useState(0);

  useEffect(() => {
    // Function to animate the numbers from 0 to their respective values
    const animateNumbers = () => {
      const targetImplementations = 2000;
      const targetExperience = 12;
      const targetUsers = 10;
      const targetTeamStrength = 400;

      const step = 50; // You can adjust this for the animation speed
      const step2 = 1;
      const step3 = 10;

      // Implementations
      if (implementations < targetImplementations) {
        setImplementations(implementations + step);
      }

      // Experience
      if (experience < targetExperience) {
        setExperience(experience + step2);
      }

      // Users
      if (users < targetUsers) {
        setUsers(users + step2);
      }

      // Team Strength
      if (teamStrength < targetTeamStrength) {
        setTeamStrength(teamStrength + step3);
      }
    };

    // Start the animation
    const intervalId = setInterval(animateNumbers, 100);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [implementations, experience, users, teamStrength]);

  return (
    <div className="bg-cover bg-center py-16 mt-12 text-white" style={{ backgroundColor: '#85afc3' }}>
      <div className="max-w-5xl mx-auto text-center">
        <div className='flex justify-center flex-col items-center'>
          <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Key Statistics</h1>
          <img src='/assets/cap.png' alt='cap image' className='md:w-1/3  '/>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 m-4 mt-8 ">
          <div className="bg-none p-4 -ml-4 ">
            <h1 className="text-7xl font-semibold mb-2 ">{implementations}</h1>
            <hr/>
            <h2 className='text-2xl mt-2'>Successful Implementations</h2>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <h2 className="text-5xl font-semibold mb-2 mt-4 text-black">{experience}+</h2>
            <h2 className='text-2xl mt-5 font-semibold text-black'>Years of Experience</h2>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <h2 className="text-5xl font-semibold mb-2 mt-4 text-black">{users}+</h2>
            <h2 className='text-2xl mt-5 font-semibold text-black'>Million Users across Globe</h2>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <h2 className="text-5xl font-semibold mb-2 mt-4 text-black">{teamStrength}+</h2>
            <h2 className='text-2xl mt-5 font-semibold text-black'>Team Strength</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
