import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const slides = [
 
  // '/assets/client/st-xavier-logo.png',
  // '/assets/client/sidhi-english-medium.webp',
  // '/assets/client/Shri-Vijaya-maharani.webp',
  // '/assets/client/Shiksha-Balika-inter-college.png',
  // '/assets/client/1.png',
  // '/assets/client/4.png',
   '/assets/client/pragya .png',
  '/assets/client/Guru-Gobind-Singh.webp',
  '/assets/client/3.png',
  // '/assets/client/aditya-school.webp',
  // '/assets/client/2.png',
  
];

const Client = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    
  };

  return (
    <>
      
    <div className="client-container md:m-20 m-10">
      <div className='flex justify-center flex-col items-center mb-16'>
              <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Our Esteemed Clients</h1>
              <img src='/assets/cap.png' alt='cap image' className='md:w-1/5  '/>
            </div>
      {/* <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide} alt={`Slide ${index + 1}`} width="180px"/>
          </div>
        ))}
      </Slider> */}
      <div className="image-container flex justify-around">
          {slides.map((slide, index) => (
            <div key={index} className="slide">
              <img src={slide} alt={`Slide ${index + 1}`} width="180px" />
            </div>
          ))}
        </div>

    </div>
    </>
  );
};

export default Client;
