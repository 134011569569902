import React, { useState } from "react";
import question from "./question";
import "./AboutResume.css";
import review from "./review";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const AboutResume = () => {
  const Question = ({ question, answer }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className="mb-6  mx-4 ">
        <div
          className={`flex items-center cursor-pointer border border-black rounded-3xl px-4 md:px-8 py-2 justify-between shadow-md shadow-black ${
            isExpanded ? "text-purple-700" : ""
          }`}
          onClick={toggleExpansion}
        >
          <h3 className="text-xl font-medium font-opensans">{question}</h3>
          <svg
            className={`w-7 h-7 md:w-5 md:h-5 ml-2 transition-transform ${
              isExpanded ? "transform rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        {isExpanded && (
          <p className="mt-2 text-gray-600 bg-gray-200 p-3 rounded-3xl mx-2 md:mx-0 font-opensans">
            {answer}
          </p>
        )}
      </div>
    );
  };

  const ReviewTemplate = ({ review }) => {
    return (
      <div className="flex justify-center items-center gap-6 mb-10 mt-2">
        <div className="w-4/5 h-1/2 p-4 border-2 border-black rounded-lg transition-transform transform hover:scale-105 duration-300">
          <div className="flex items-center justify-center">
            <div className="w-1/3">
              <img
                src={review.image}
                alt={review.name}
                className=" h-42 rounded-full"
              />
            </div>
          </div>
          <h3 className="text-3xl font-semibold mt-4">{review.name}</h3>
          <p className="text-gray-600 mt-2 text-xl font-semibold">
            {review.designation}
          </p>
          <p className="text-gray-800 ">{review.content}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4 md:p-8 text-center custom-mb">
      <h1 className="text-3xl md:text-5xl font-semibold mb-10 md:mb-24 text-yellow-600 py-3 shadow-2xl ">
        Frequently Asked Questions
      </h1>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-1/2 md:pr-4">
          <div className="text-center md:text-left">
            <div className="md:w-full">
              {question.map((faq, index) => (
                <Question
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 md:pl-4 md:mb-0 mb-[35rem]">
          <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop>
            {review.map((review) => (
              <ReviewTemplate key={review.id} review={review} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default AboutResume;
