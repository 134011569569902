import React from 'react'
import { BiSolidRightArrow } from 'react-icons/bi';
import './feature.css'

const Features = () => {
  return (
    <div className="bg-gray-100 mt-12 p-10">
      <div className='flex justify-center flex-col items-center'>
      <h1 className="text-2xl font-semibold md:text-3xl mb-4 text-center">Features of Cyber Security Program</h1>
      <img src='/assets/cap.png' alt='cap image' className='md:w-1/3  '/>
      </div>

      <div className="flex md:flex-row flex-wrap justify-center items-center my-6 space-y-4 md:space-x-8">
       
      <div className="flip-card1">
        <div className="flip-card1-inner">
          <div className="flip-card1-front flex flex-col ">
            <img
              src="/assets/Cybersecurity/cyberimage/feature1.png"
              alt="Email"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Fundamentals</h1>
          </div>
          <div className="flip-card1-back bg-blue-200 rounded-xl p-4">

            <div className='mb-3'>
              <h1 className='font-opensans text-xl font-semibold text-center text-orange-500'>DAY 1</h1>
             
            </div>
          
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Intro to Cyber Security and ethical hacking.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '> Cyber security fundamentals.</p>
              
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Web security fundamentals.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Networking fundamentals.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Linux fundamentals.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Binding a lab for Ethical hacking (Setting up Kali
                 Linux on a VM ware)</p>
            </div>
           
          </div>
        </div>
      </div>

    
      <div className="flip-card1">
        <div className="flip-card1-inner">
          <div className="flip-card1-front flex flex-col h-auto">
            <img
              src="/assets/Cybersecurity/cyberimage/feature2.png"
              alt="Mobile"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Ethical hacking deep dive</h1>
          </div>
          <div className="flip-card1-back bg-yellow-300 rounded-xl p-4">
          <div className='mb-3'>
              <h1 className='font-opensans text-xl font-semibold text-center text-orange-500'>DAY 2</h1>
             
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Stages of Ethical Hacking.</p>
            </div> 
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Information gathering.</p>
                
             
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Scanning, Enumeration, Vulnerability Analysis, Exploitation,
                  Privilege Escalation, Persistence.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Web security.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Networking security and firewall.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Practical approach to Ethical hacking with live demo.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '> Ethical hacking tools and use.</p>
            </div>

          </div>
        </div>
      </div>

      <div className="flip-card1">
        <div className="flip-card1-inner">
          <div className="flip-card1-front flex flex-col">
            <img
              src="/assets/Cybersecurity/cyberimage/feature3.png"
              alt="Mobile"
              className="w-[15rem] h-[14rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center mt-4">Attacking and
                defensive directories</h1>
          </div>
          <div className="flip-card1-back bg-blue-200 rounded-xl p-4 h-auto">
          <div className='mb-3'>
              <h1 className='font-opensans text-xl font-semibold text-center text-orange-500'>DAY 3</h1>
             
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Understanding Active Directory, Structure and
                  Fundamentals of Active Directory, Configuring an Active
                  Directory Lab.</p>
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Initial Attack Vectors, Exploitation, and Persistence in an
                  Active Directory Environment.</p>
            </div> 
           
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '> Wrapping up the workshop with an understanding of
                    Incident Response and Planning (NIST Framework).</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Quiz and assesment.</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Certificate distribution.</p>
            </div>
           
          </div>
        </div>
      </div>

      
  
     
      </div>

   
    </div>
  )
}

export default Features