import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import {BiPhoneCall , BiLogoFacebook,BiLogoLinkedin,BiLogoTwitter } from 'react-icons/bi';
import { HiChevronDoubleRight, HiLocationMarker } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Footers = () => {
  return (
    <>
    <div className="bg-black text-white p-4 ">
      <div className="md:flex md:flex-row flex-col justify-around items-center">
        {/* Left Side (Logo) */}
        <div className='md:ml-0 ml-20'>
          <img src="/assets/compus_beez_logo.png" alt="Logo" className="md:w-[14rem] md:h-[7rem] w-[11rem] h-[5rem] " />
        </div>
        
        {/* Right Side */}
        <div className="md:flex md:flex-row  lg:space-x-6 md:ml-0 ml-20 ">
          {/* Email Section */}
          <div className="flex md:border-r-2 lg:pr-3">
            <div className='flex'><AiOutlineMail size={20} className='mr-2 mt-[2px]'/></div>
            <a href="mailto:support@campusbeez.com" className="md:mb-6 hover:text-blue-700">support@campusbeez.com</a>
          </div>
          {/* Phone Number Section */}
          <div className='flex md:border-r-2 lg:pr-3 md:mt-0 mt-2'>
          <div className='flex'><BiPhoneCall size={20} className='mr-2 mt-[2px]'/></div>
          <a href="tel:0124-400-6882" className='hover:text-blue-700'>0124-400-6882</a>
          </div>
          <div className='flex md:border-r-2 lg:pr-3 md:mt-0 mt-2'>
            <div className='flex'><BiPhoneCall size={20} className='mr-2 mt-[2px]'/></div>
            <a href="tel:8860014004" className='hover:text-blue-700'>+91-8860014004</a>
          </div>
          <div className='flex md:mt-0 mt-2'>
            <div className='flex'><BiPhoneCall size={20} className='mr-2 mt-[2px]'/></div>
            <a href="tel:7827135297" className='hover:text-blue-700'>+91-7827135297</a>
          </div>
        </div>
      </div>
      <hr className='mx-20 mt-8 border-b-2 border-white' />

      <div className="lg:flex lg:flex-row md:flex-col justify-around space-x-10 mt-6 md:mx-24 mx-6">
        {/* Left Side (Address) */}
        <div className="lg:w-1/3 w-full mt-10 mr-4">
          <div className='flex flex-col items-center border px-4 pb-4'>
            <HiLocationMarker size={40} className=' rounded-full bg-blue-700 mt-[-1.5rem] '/>
            <p className='mt-4 font-opensans'>Livelake Education Pvt Ltd Ansal API Palam Corporate Plaza,
               TF-427, A-Block, Palam Vihar, Gurugram, Haryana 122017 (India)</p>
          </div>
        </div>

        {/* Right Side (Map) */}
        <div className="md:w-1/2 lg:mt-0 mt-4 w-full mb-5 md:ml-0 ml[-1rem]">
          <div className='ml-[-1.5rem]'>
             <iframe
             title='epic coorporation' 
             src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14025.660666282127!2d77.0210022!3d28.4971543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19f4900b04d7%3A0x799e41ef0ff8cad!2sEpic%20Corporations!5e0!3m2!1sen!2sin!4v1694166557776!5m2!1sen!2sin" className='md:w-[500px] md:h-[200px] w-[280px] hover:scale-110 transition duration-300 ' />
          </div>
          
        </div>
      </div>
      
        <div className="lg:flex lg:flex-row flex-col justify-around mt-4 md:ml-0 ml[-1rem]">
        <div className="text-center ml-[-2rem]">
          <h2 className="text-2xl font-semibold">Education ERP</h2>
       
          <div className='md:flex md:flex-row flex-col md:space-x-14 mt-4 md:ml-0 ml-20'>
            <div className='flex flex-col cursor-pointer'>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500 '/>Campus Management System</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Fees Management System</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Attendance Management System</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Library Management System</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Student Information System</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Learning Management System</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Choice Based Credit System</div>
            </div>
           
           
            <div className='flex flex-col mb-1 md:ml-0 ml[-1rem] cursor-pointer '>
              
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2  text-blue-500'/>University ERP Software</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Outcome Based Education</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>College ERP Software</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Online Assessment Software</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Higher Education ERP Software</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Accreditation Data Management</div>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>School ERP Software</div>
            </div>
           
          </div>
        </div>
        <div className="text-center md:ml-0 ml-[-2rem]">
          <h2 className="text-2xl font-semibold">Others</h2>
          <div className='lg:flex lg:flex-col md:flex md:space-x-2 mt-4 md:ml-0 ml-20 cursor-pointer'>
          <Link
                to="https://cbpro.campusbeez.com/"
                target='blank'
                className='flex mb-2 cursor-pointer'
              >
                <HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-2 text-blue-500'/>Resume Builder
              </Link>
              <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>
                <Link to="/faq">
                    FAQ
                </Link> 
              </div>
              <div className='flex mb-2 cursor-pointer'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>Certifications</div>
           
            </div>
        </div>
        <div className="text-center md:ml-0 ml-[-2rem]">
          <h2 className="text-2xl font-semibold">Certified</h2>
             <div className='lg:flex lg:flex-col md:flex md:space-x-6 mt-4 md:ml-0 ml-20 cursor-pointer'>
                <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500'/>ISO 9001:2015 Certified</div>
                <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>STEM Certified</div>
                <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 text-blue-500'/>CMMI Level 3 Certified</div>
               
              </div>
              <div className='flex space-x-4 mt-10 md:ml-0 ml-20'>
                 <a href="https://twitter.com/CampusBeez/" target="_blank" rel="noopener noreferrer" className='hover:scale-125 transition duration-300'>
                  <BiLogoTwitter size={32} className='bg-blue-700' />
                </a>
                <a href="https://www.facebook.com/people/Campus-Beez/100084609357351/" target="_blank" rel="noopener noreferrer" className='hover:scale-125 transition duration-300'>
                   <BiLogoFacebook size={32}  className='bg-blue-700 '/>
                </a>
                <a href="https://www.instagram.com/livelakeeducation/" target="_blank" rel="noopener noreferrer" className='hover:scale-125 transition duration-300'>
                   <img src='/assets/instalogo.png' className='w-[35px] ' alt='insta logo'/>
                </a>
                <a href="https://www.linkedin.com/showcase/campusbeez" target="_blank" rel="noopener noreferrer" className='hover:scale-125 transition duration-300'>
                   <BiLogoLinkedin size={32}  className='bg-blue-700'/>
                </a>
         </div>
             
        </div>
      </div>
    

      

    </div>
    
    <div className='flex justify-evenly'>
        <p className='py-3 font-opensans font-semibold'>Copyright © 2023 <a href='https://livelakeeducation.com/' target='_blank' ><span className='text-blue-400'>Livelake Education</span></a>.All Rights Reserved</p>
        <Link to="/privacy-policy" className='py-3 font-opensans font-semibold hover:text-blue-500'>Privacy Policy</Link>
        <Link to="/terms-conditions" className='py-3 font-opensans font-semibold hover:text-blue-500'>Terms And Conditions</Link>
        {/* <Link to="/refund-policy" className='py-3 font-opensans font-semibold hover:text-blue-500'>Refund Policy</Link> */}
    </div>
    </>
  );
};

export default Footers;
