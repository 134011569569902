import React, { useEffect, useState } from 'react'
import AboutResume from './aboutresume/AboutResume'
import {FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import {BiPhoneCall } from 'react-icons/bi';
import { Helmet } from 'react-helmet';

const Faq = () => {

  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
       <Helmet>
        
        <meta charset="utf-8" />
        <title>Education ERP FAQs - Basic FAQs of Educational ERP Solution</title>
        <meta name="description" content="Education ERP FAQs - Basic FAQs of Educational ERP Solution"/>
        <meta name="keywords" content="Education ERP FAQs - Basic FAQs of Educational ERP Solution"/>
        <meta name="robots" content="index,follow"/>
        <meta name="copyright" content="Campusbeez" />
        <meta name="email" content="support@campusbeez.com"/>
        <meta name="language" content="EN"/>
        <meta name="DC.Publisher" content="Campusbeez"/>
        <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
        <meta name="DC.Language" content="en"/>
        <meta name="Distribution" content="Global"/>
        <meta name="geo.region" content="IN"/>
        <link rel="canonical" href="https://campusbeez.com/"/>
        <meta name="geo.placename" content="India"/>
        <meta name="geo.position" content="Gurgaon"/>
        <meta name="geo.region" content="usa"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>

    </Helmet>
      <div className="fixed md:left-0 right-0 top-10 flex flex-col justify-end h-screen text-white p-4 pb-[3rem] ">
      <div className="mb-4 hover:scale-125 transition duration-300">
        <a href="https://wa.me/+918860014004" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={isMobileDevice ? 30 : 35}  className='bg-green-500'/>
        </a>
      </div>
      <div className="mb-4 hover:scale-125 transition duration-300">
        <a href="tel:8860014004">
          <BiPhoneCall size={isMobileDevice ? 30 : 35}  className='bg-blue-600'/>
        </a>
      </div>
      <div className=' hover:scale-125 transition duration-300'>
        <a href="mailto:support@campusbeez.com">
          <FaEnvelope size={isMobileDevice ? 30 : 35} className='bg-yellow-500 p-1'/>
        </a>
      </div>
    </div>
    <div className='md:mt-32'>

      <div
        className="bg-cover lg:bg-center bg-right h-80 md:mt-32"
        style={{ backgroundImage: 'url("/assets/faqq.png")' }}
      >
        <div className="flex flex-col justify-center items-center h-full  mr-4">
          <h2 className="text-5xl md:text-white text-black font-semibold mb-4 md:ml-[11rem] ml-10">Frequently Ask Questions</h2>
          <p className='md:text-white text-black font-opensans font-semibold text-xl  md:ml-20 ml-10'>Have Any Question? Here's a List of Frequently <br /> Asked Questions...</p>
          <p className='md:text-white text-black font-opensans font-semibold text-xl  md:ml-7 ml-9 mt-2'>We are here to help you with your query.</p>
        </div>
      </div>
      <AboutResume />
      </div>

      </>
  )
}

export default Faq