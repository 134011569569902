import React from 'react'

const Hiring = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-slate-200 ">
    <div className="text-7xl font-semibold text-yellow-500 text-center">OOPS ! Currently no Hiring is going on....</div>
  </div>
  )
}

export default Hiring