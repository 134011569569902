import React from 'react';
import { Link } from 'react-router-dom';

const CourseCard = () => {
  return (
    <div className="md:flex md:flex-col w-full flex-wrap gap-6 md:mx-16 mx-4 mb-8 lg:mt-[10rem] md:mt-4 mt-[-2rem]">

      <div className='flex justify-center flex-col items-center md:mb-16 mb-6 md:mt-4'>
            <h1 className="text-3xl font-semibold md:text-4xl mb-2 text-center font-opensans">PROGRAMS</h1>
            <img src='/assets/cap.png' alt='cap image' className='w-1/2 md:w-1/5' />
      </div>
      <div className="lg:w-[32%] md:w-[38%] w-[91%] mb-8 shadow-2xl">
        <div className="bg-white rounded-lg p-4 shadow-md hover:bg-blue-200 hover:scale-105 transition duration-300">
          <img
            src="/assets/feature/decision.png"
            alt="Course Image"
            className="w-[8rem] h-32 object-cover mx-auto rounded-md mb-4"
          />
          <h3 className="text-3xl font-semibold mb-8 text-center">Cyber Security & Ethical Hacking Workshop</h3>
          <p className="text-gray-600 font-opensans mb-8 text-justify font-semibold">CampusBeez (A LiveLake Education Brand) presents the opportunity for every student and faculty to get in hand knowledge of Cyber Security and Ethical Hacking one of the most demanding skills in the market as well as for their own Cyber safety.</p>
          
          <div className="text-center">
          <Link to="/cybersecurity" >
            <button className="bg-blue-500 text-white font-opensans px-4 py-2 rounded-full hover:bg-yellow-500 transition duration-300">
              Learn More
            </button>
          </Link>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
