import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MaterialReactTable from 'material-react-table';
import { AiFillDelete } from 'react-icons/ai'
import { FaEye} from 'react-icons/fa'
import Swal from 'sweetalert2'


import { toast, ToastContainer } from 'react-toastify';


const ExamCategory = () => {
   
    
    const [data,setData] = useState('')
    // const loginToken = JSON.parse(localStorage.getItem('LoginToken'))
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false); 
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [imageFile , setImageFile] = useState('')

    const [editCategoryData, setEditCategoryData] = useState({});

    const [editCategoryId, setEditCategoryId] = useState(null);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setNewCategory(''); 
    };


    const handleAddQualification = async () => {
        // try {
        //   const formData = new FormData();
        //   formData.append('name', newCategory); 
        //   const response = await axios.postForm(
        //     `${process.env.REACT_APP_RESUME_URL}/qualification/store`,
        //     formData,
        //     {
        //       headers: {
        //          'Authorization':`Bearer  ${loginToken}`,
        //         'Accept': 'application/json'
        //       }
        //     }
        //   );
    
        //   if (response?.data?.code === 200) {
        //     // Update your data state with the new course data
        //     setData([...data, response.data.data]); 
        //     toast.success('Qualification Added Successfull')
    
        //     // Close the popup
        //     handleClosePopup();
        //   }
        // } catch (error) {
        //   console.error('Error adding Qualification:', error);
        // }
      };


	

  
    const getQualification = async()=>{
        // const getTemplate = await axios.get(`${process.env.REACT_APP_RESUME_URL}/qualifications`,{
        //     headers:{
        //         'Authorization':`Bearer  ${loginToken}`,
        //         'Accept':"application/json"
        //     }
        // })
        // const response = await getTemplate?.data
        // console.log('response',response)
        // if(response?.data){
        //     setData(response?.data)
        // }
    }
    useEffect(()=>{
        getQualification()
    },[])

    const handleOpenEditPopup = (cell) => {
      console.log('row' ,cell?.row?.original?.id);
      setEditCategoryId(cell?.row?.original?.id);
      setIsEditPopupOpen(true);
      setEditCategoryData(cell?.row?.original);
    };
  
    // Add a function to close the edit popup form
    const handleCloseEditPopup = () => {
      setIsEditPopupOpen(false);
      setEditCategoryData({});
    };
   

    const handleDelete = (cell)=>{
//       console.log("Dealer",cell?.row?.original)
//       Swal.fire({
//           title: 'Are you sure?',
//           text: "You won't be able to revert this!",
//           icon: 'warning',
//           showCancelButton: true,
//           confirmButtonColor: '#3085d6',
//           cancelButtonColor: '#d33',
//           confirmButtonText: 'Yes, delete it!'
//         }).then(async(result) => {
//           if (result.isConfirmed) {
//               try {
//                   const deleteUser = await fetch(`${process.env.REACT_APP_RESUME_URL}/qualification/delete/${cell?.row?.original?.id}`,{
//                     method:"post",  
//                     headers:{
//                           'Authorization':`Bearer  ${loginToken}`,
//                           'Accept':"application/json"
//                       }
//                   })
//                   const response = await deleteUser?.json()
//                   // console.log("response",response)
//                   toast.success(`${response?.message}`)
//                   getQualification()
//                   // Swal.fire(
//                   //   'Deleted!',
//                   //   'Your file has been deleted.',
//                   //   'success'handleEditQualification
//                   // )          
//               } catch (error) {
//                   if(error?.response?.data?.errors)
//                   error?.response?.data?.errors?.map((x)=>(
//                       toast.error(`${x}`)
//                   ))
//               }
      
//   }})
    }
    
    const handleEditQualification = async () => {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_RESUME_URL}/qualification/update/${editCategoryId}`, // Use the course ID in the API URL
    //       editCategoryData, // Use the updated course data
    //       {
    //         headers: {
    //           'Authorization': `Bearer  ${loginToken}`,
    //           'Accept': 'application/json'
    //         }
    //       }
    //     );
  
    //     if (response?.data?.code === 200) {
    //       // Find the index of the edited course in the data state
    //       const editedCourseIndex = data.findIndex((course) => course.id === editCategoryId);
  
    //       if (editedCourseIndex !== -1) {
    //         // Update the course data in the state
    //         const updatedData = [...data];
    //         updatedData[editedCourseIndex] = response.data.data;
    //         setData(updatedData);
    //         toast.success('Course Updated');
    //       }
  
    //       // Close the edit popup
    //       handleCloseEditPopup();
    //       getQualification();
    //     }
    //   } catch (error) {
    //     console.error('Error editing course:', error);
    //   }
    };
  
    
    const heading = [
        {
            accessorKey: 's_no',
            header: "S.No",
            size: 110,
            Cell: ({ cell, row }) => <div>{parseInt(row?.id) + 1}</div>,
           
        },
        {
            accessorKey: 'name',
            header: 'Category',
            size: 150,
        },
        {
            accessorKey: 'description',
            header: 'Description',
            size: 150,
        },

        {
            header:"Action",
            size: 100,
            Cell:({cell})=>
              <>
              {/* <button onClick={(e)=>handleProfileView(e,cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'><FaEye size={20}/></button> */}
              <button onClick={() => handleOpenEditPopup(cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'>
                <FaEye size={20} />
              </button>
              <button onClick={()=>handleDelete(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><AiFillDelete size={20}/></button>
              </>  
        },
       
    ]



  return (

  <div className='w-full'>
   <ToastContainer 
      position='top-center'
      autoClose={3000}/>
   
    <div className='mx-2'>
      <div className='flex justify-between items-center'>
        <div>
          <h2 className='text-2xl font-bold capitalize'>Category </h2>
        </div>
        <div>
          <button onClick={handleOpenPopup} className="bg-black text-white py-2 px-4 rounded">
            Add Category
          </button>
        </div>
      </div>
      <div className='w-full mt-4 relative'>
        <MaterialReactTable enableFullScreenToggle={false} columns={heading} data={data} />
        {isPopupOpen && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-white p-4 rounded shadow-md mt-[9rem] " style={{ width:'50%', zIndex: 10 }}>
                <div className='flex justify-end' onClick={handleClosePopup}>
                  <span  className='text-neutral-50  hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
                </div>
                <h3 className="text-2xl font-bold mb-2 -mt-8">Category</h3>
              
              <form>
                    <div className='text-left mt-3 px-1 py-1 flex-col flex flex-1 justify-center'>
                        <label className='block font-opensans mb-2 font-semibold'>Category<span className='text-red-500'>*</span></label>
                        <input required placeholder='Exam Title' value={newCategory} onChange={(e)=>setNewCategory(e.target.value)} className="border w-full border-neutral-800 p-2 mt-1" />
                               
                    </div>
                    <div className='text-left px-1 mt-3 py-1 flex-col flex flex-1 justify-center'>
                        <label className='block font-opensans mb-2 font-semibold'>Description<span className='text-red-500'>*</span></label>
                        <input required placeholder='Exam Title' value={newDescription} onChange={(e)=>setNewDescription(e.target.value)} className="border w-full border-neutral-800 p-2 mt-1" />
                               
                    </div>
                    <div className='flex flex-col mt-3 justify-center p-1 '>
                    <label className='block font-opensans mb-2 font-semibold'>Category Image<span className='text-red-500'>*</span></label>
                    <input required id="dropzone-file" accept="image/jpeg, image/jpg, image/png" type="file" className="border file:text-neutral-50 w-full file:bg-neutral-800 border-neutral-800 mt-1" onChange={(e)=>setImageFile(e.target.files[0])} />  
                    <p className="text-sm text-neutral-800 font-opensans mt-2">Choose your JPEG/JPG or PNG Image. </p>
                </div>
                  {/* <div className={`bg-white my-4 p-1 flex border border-gray-200 rounded`}>
                    <input
                      name='addqualification'
                      placeholder='Add Qualification'
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}
                    />
                  </div> */}
                  <button
                    type='button'
                    onClick={handleAddQualification}
                    className="bg-blue-500 mt-4 text-white py-2 px-4 rounded"
                  >
                    Add
                  </button>
                  <button
                    type='button'
                    onClick={handleClosePopup}
                    className="bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded"
                  >
                    Cancel
                  </button>
                </form>
            </div>
          </div>
        )}
        {isEditPopupOpen && (
          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
            <div className='bg-white p-4 rounded shadow-md' style={{ width: '40%', marginTop: '-90px', zIndex: 10 }}>
              <h3 className='text-xl font-semibold mb-2'>Edit Category</h3>
              <form>
              
                {/* Add form fields to edit the course data */}
                <div className={`bg-white my-4 p-1 flex border border-gray-200 rounded`}>
                  <input
                    name='editqualification'
                    placeholder='Edit Qualification'
                    value={editCategoryData.name || ''}
                    onChange={(e) => setEditCategoryData({ ...editCategoryData, name: e.target.value })}
                    className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}
                  />
                </div>
                <button
                  type='button'
                  onClick={handleEditQualification}
                  className='bg-green-600 text-white py-2 px-4 rounded'
                >
                  Update
                </button>
                <button
                  type='button'
                  onClick={handleCloseEditPopup}
                  className='bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded'
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  )
}

export default ExamCategory