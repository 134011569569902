import React, { useState } from 'react';
import  "./equraiyform.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const EquraiyForm = () => {

  // Use a different name for the state variable
  const [enqFormVisible, setEnqFormVisible] = useState(0);
  const [buttonText, setButtonText] = useState('Get In Touch');

  const [EquraiyFormm, setEquraiyFormm] = useState({
    gname: "",
    contact: "",
    gemail: "",
    Regarding: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEquraiyFormm({ ...EquraiyFormm, [name]: value });
  };
  
  const buttonSubmitte = async (e) => {
    e.preventDefault();
    const Enquridata = {
      gname: EquraiyFormm.gname,
      contact: EquraiyFormm.contact,
      gemail: EquraiyFormm.gemail,
      Regarding: EquraiyFormm.Regarding,
    };

    const jsonData = JSON.stringify(Enquridata);
    try {
      const response = await fetch('https://erp.campusbeez.com/api/webservice/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
       
      });
      console.log("data response ",response)

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.status === "1") {
        // toast.success('Thank you!'); // Success notification
        setEquraiyFormm({
          gname: "",
          contact: "",
          gemail: "",
          Regarding: "",
        });
        setEnqFormVisible(0)
        setButtonText((prevText) => (prevText === 'Get In Touch' ? 'Closed Form !' : 'Get In Touch'));
      } 
      else {
        toast.error('Something went wrong'); // Error notification
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };
    // Use a different name for the function
  const handleEnqFormToggle = () => {
    setButtonText((prevText) => (prevText === 'Get In Touch' ? 'Closed Form !' : 'Get In Touch'));
    setEnqFormVisible(!enqFormVisible);
  };

  const buttonStyles = {
    left: enqFormVisible ? '0' : '-320px',
    padding: '10px 0px'
   
  };
  return (
    <>
    <ToastContainer/>
     <div className="cart-icon in-box" id="callback" 
       style={buttonStyles}
     >

<a className="mico" onClick={ handleEnqFormToggle} >{buttonText}</a>

<div className='enqform'>

  <form action="" method="post" id="sendmailforml">

    <div className="row">

      <div className="col-md-12 pb-4" >
   
        <h3 className="mcolor1 left-align m0 fw-900  font-bold">Get Call Back</h3>
        
        {/* <div className=' text-left mb-1'>Name</div> */}
        
        <input type="text" name="gname" id="gname"  onChange={handleChange} value={EquraiyFormm.gname} placeholder="Name *" className='fromname mb-4' required=""/>
        <input type="text" name="gemail" id="gemail"  onChange={handleChange} value={EquraiyFormm.gemail} placeholder="Email *" className='fromname mb-3' required=""/>
        <input type="text" name="Regarding" id="Regarding"  onChange={handleChange}  value={EquraiyFormm.Regarding} placeholder="Regarding *" className='fromname' required=""/>
        
        </div>
        
        <input type="tel" name="contact" id="contactno"  onChange={handleChange}  value={EquraiyFormm.contact} placeholder="Contact Number *" maxlength="10" className='gromname' data-length="10" pattern="^\d{10}$" required=""/></div>
         <div className=' pt-5'>
        <div className="form-group center">
        <button type="button" className='pt-1' onClick={buttonSubmitte}>Submit</button></div>
         </div>
  </form>
</div>
</div>
    </>
  );
};

export default EquraiyForm;
