import React ,{useState} from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const OnlineExamAddForm = () => {

    const [descriptionData, setDescriptionData] = useState('');
    const [examTitle, setExamTitle] = useState('');
    const [duration, setDuration] = useState('');
    const [attempt, setAttempt] = useState('');
    const [passPercent, setPassPercent] = useState('');

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setDescriptionData(data);
      };

      const handlesubmit = () =>{
        console.log(descriptionData)
     }

  return (
    <>
        <h3 className="text-2xl font-bold mb-2 -mt-8">Exam</h3>
        <form >
            <div className='mt-4'>
                <input type="checkbox" id="myCheckbox" className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                <label for="myCheckbox" className='font-opensans font-semibold ml-3'>Quiz</label>
                <p className='font-opensans text-base'>In quiz result will be display to student immediately just after exam submission (descriptive question type will be disabled).</p>
            </div>
                <div className='flex space-x-2 mt-4 '>
                            <div className='text-left px-1 py-1 flex-col flex flex-1 justify-center'>
                                <label className='block font-opensans font-semibold'>Exam Title<span className='text-red-500'>*</span></label>
                                <input required placeholder='Exam Title' value={examTitle} onChange={(e)=>setExamTitle(e.target.value)} className="border w-full border-neutral-800 p-2 mt-1" />
                               
                            </div>
                            <div className='text-left px-1 py-1 flex flex-col flex-1 justify-center'>
                                <label className='block font-opensans font-semibold'>Duration<span className='text-red-500'>*</span></label>
                                <input required placeholder='Duration' value={duration} onChange={(e)=>setDuration(e.target.value)} className="border w-full border-neutral-800 p-2 mt-1" />
                            </div>
                </div>
                <div className='flex space-x-2 mt-4'>
                            <div className='text-left px-1 py-1 flex flex-col flex-1 justify-center'>
                                <label className='font-opensans font-semibold'>Attempt<span className='text-red-500'>*</span></label>
                                <input required placeholder='Attempt' value={attempt} onChange={(e)=>setAttempt(e.target.value)} className="border w-full border-neutral-800 p-2 mt-1" />
                               
                            </div>
                            <div className='text-left px-1 py-1 flex flex-col flex-1 justify-center'>
                                <label className='block font-opensans font-semibold'>Passing Percentage<span className='text-red-500'>*</span></label>
                                <input required placeholder='Passing Percentage' value={passPercent} onChange={(e)=>setPassPercent(e.target.value)} className="border w-full border-neutral-800 p-2 mt-1" />
                            </div>
                </div>

                <div className='mt-4 flex space-x-4 items-center'>
                    
                    <input type="checkbox" id="publishexam" className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                    <label for="publishexam" className='font-opensans font-semibold ml-2'>Publish Exam</label>
                    
                    <input type="checkbox" id="publishresult" className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                    <label for="publishresult" className='font-opensans font-semibold ml-2'>publish Result</label>
                    
                    <input type="checkbox" id="negative" className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                    <label for="negative" className='font-opensans font-semibold ml-2'>Negative Marking</label>
                    
                    <input type="checkbox" id="random" className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                    <label for="random" className='font-opensans font-semibold ml-2'>Random Questioon Order</label>
                    
                    <input type="checkbox" id="display" className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
                    <label for="display" className='font-opensans font-semibold ml-2'>Display Marks in Exam</label>
                   
                </div>
      
              <div className="mt-3 text-left px-1 py-2 flex flex-col justify-center">
                  <label className="block font-opensans font-semibold mb-2">Description<span className="text-red-500">*</span></label>
                 <CKEditor
                    editor={ClassicEditor}
                    data={descriptionData}
                    onChange={handleEditorChange}
                    />
              </div>
              <button
               type='button'
               onClick={handlesubmit}
               className="bg-blue-500 mt-4 text-white py-2 px-4 rounded"
             >
               submit
             </button>
       </form>

    </>
   
  )
}

export default OnlineExamAddForm