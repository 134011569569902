import React from 'react';
import { BsDatabaseFill, BsFillShieldLockFill } from 'react-icons/bs';
import { FaLaptop, FaDesktop, FaChartPie, FaCogs } from 'react-icons/fa';


const Offer = () => {
  return (
    <div className=''  style={{ backgroundColor: '#85afc3' }}>
      <div className="p-4 mx-2 md:mx-12">
        <h1 className="text-2xl font-semibold md:text-3xl mb-2 text-center text-white font-opensans mt-4 md:mt-12">
          CampusBeez Educational ERP Offers
        </h1>
        <img src='/assets/cap.png' alt='cap image' className='md:w-1/3 mx-auto mb-8' />
        <p className="text-white mb-2 mx-2 md:mx-0 text-xl font-opensans font-semibold mt-6 text-justify ">
        Our cutting-edge campus management solutions are designed to revolutionize institutional operations, driving heightened productivity, enhanced efficiency, and a more favorable outcome ratio. By seamlessly optimizing workflows and facilitating a transition to a paperless environment, we empower educational institutions to embrace a future of streamlined and eco-friendly administration.</p>

        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mb-8 md:mb-12 mt-8">
          
          <div className=" bg-yellow-600 p-4 rounded-lg hover:shadow-2xl hover:scale-105 transition duration-300" style={{ backgroundImage: 'url("/assets/database.jpg")', backgroundSize: 'cover', backgroundPosition: 'center'  }}>
            <div className='flex justify-center items-center flex-col mt-2'>
            <BsDatabaseFill size={40} style={{ color: '	white' , backgroundColor:'black' , padding: '6px' , borderRadius:'50%' }} />
            <h2 className="text-2xl font-bold mt-4 text-black ">Centralized Database</h2>
            <hr className="border-1" />
            <p className='mb-2 mx-4 md:mx-0 font-opensans font-bold mt-6 text-black text-justify '>A single unified platform empowers individual users to efficiently oversee their specific tasks within an institution by consolidating data management into one centralized hub.</p>
            </div>
          </div>

          
          <div className="bg-yellow-600 p-4 rounded-lg hover:shadow-2xl hover:scale-105 transition duration-300" style={{ backgroundImage: 'url("/assets/support.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='flex justify-center items-center flex-col mt-2'>
            <FaLaptop size={40} style={{ color: '	white' , backgroundColor:'black' , padding: '6px' , borderRadius:'50%' }} />
            <h2 className="text-2xl font-bold mt-4 text-black">Absolute Support</h2>
            <hr className="border-1" />
            <p className='mb-2 mx-4 md:mx-0 font-opensans font-bold mt-6 text-black text-justify'>Providing both onsite and online support services to all users, coupled with well-structured implementation roadmaps, accelerates the deployment process and encourages active engagement from all users.</p>
            </div>
          </div>

          
          <div className="bg-yellow-600 p-4 rounded-lg hover:shadow-2xl hover:scale-105 transition duration-300" style={{ backgroundImage: 'url("/assets/roi.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='flex justify-center items-center flex-col mt-2'>
            <FaDesktop size={40} style={{ color: '	white' , backgroundColor:'black' , padding: '6px' , borderRadius:'50%' }} />
            <h2 className="text-2xl font-bold mt-4 text-black">Quickest ROI</h2>
            <hr className="border-1" />
            <p className='mb-2 mx-4 md:mx-0 font-opensans font-bold mt-6 text-black text-justify'>Swift implementation, driven by streamlined processes and a cutting-edge technological foundation, guarantees the quickest return on investment (ROI) for your organization.</p>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mb-8 md:mb-12">
          
          <div className="bg-yellow-600  p-4 rounded-lg hover:shadow-xl hover:scale-105 transition duration-300" style={{ backgroundImage: 'url("/assets/data security2.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            
            <div className='flex justify-center items-center flex-col mt-2'>
            <BsFillShieldLockFill size={40} style={{ color: '	white' , backgroundColor:'black' , padding: '6px' , borderRadius:'50%' }} />
            <h2 className="text-2xl font-bold mt-4 text-black">Data Security</h2>
            <hr className="border-1" />
            <p className='mb-2 mx-4 md:mx-0 font-opensans font-bold mt-6 text-black text-justify'>Our cloud infrastructure is designed with utmost security in mind, featuring advanced access control based on user roles. We employ state-of-the-art encryption techniques to safeguard sensitive data, including both password and one-time-password (OTP) encryption.</p>
            </div>
          </div>

          
          <div className="bg-yellow-600 p-4 rounded-lg hover:shadow-2xl hover:scale-105 transition duration-300" style={{ backgroundImage: 'url("/assets/easy.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='flex justify-center items-center flex-col mt-2'>
            <FaCogs size={40} style={{ color: '	white' , backgroundColor:'black' , padding: '6px' , borderRadius:'50%' }} />
            <h2 className="text-2xl font-bold mt-4 text-black">Easy & Intuitive Solutions</h2>
            <hr className="border-1" />
            <p className='mb-2 mx-4 md:mx-0 font-opensans font-bold mt-6 text-black text-justify'>Design a user-friendly framework that seamlessly accommodates vernacular languages while implementing interactive tooltips to facilitate user onboarding and effortless navigation.</p>
            </div>
          </div>

          
          <div className="bg-yellow-600 p-4 rounded-lg hover:shadow-2xl hover:scale-105 transition duration-300" style={{ backgroundImage: 'url("/assets/dashboard.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='flex justify-center items-center flex-col mt-2'>
            <FaChartPie size={40} style={{ color: '	white' , backgroundColor:'black' , padding: '6px' , borderRadius:'50%' }}/>
            <h2 className="text-2xl lg:text-2xl md:xl font-bold mt-4 text-black">Dashboards & Reports</h2>
            <hr className="border-1 " />
            <p className='mb-2 mx-4 md:mx-0 font-opensans font-bold mt-6 text-black text-justify'>Develop dynamic dashboards enhanced by analytics, enabling users to effortlessly access real-time insights and generate comprehensive reports to drive informed decision-making and ensure regulatory compliance.</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Offer;



