import React from 'react';
import { BiSolidRightArrow } from 'react-icons/bi';
import './collegemodule.css';

const CollegeModule = () => {
  return (
    <div className="bg-gray-100 mt-12 p-10">
      <div className='flex justify-center flex-col items-center'>
      <h1 className="text-2xl font-semibold md:text-3xl mb-4 text-center">Our Modules for College Management System</h1>
      <img src='/assets/cap.png' alt='cap image' className='md:w-1/3  '/>
      </div>

      <div className="flex md:flex-row flex-wrap justify-center items-center my-6 space-y-4 md:space-x-8">
       
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col ">
            <img
              src="/assets/collegemodule/record.png"
              alt="Email"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Student Record Management</h1>
          </div>
          <div className="flip-card-back bg-blue-200 rounded-xl p-4">
          
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Attendance Management</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Centralized Data Storage</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Easy Data Search and Retrieve</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Student Performance Record</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Easy Communication among Departments</p>
            </div>
           
          </div>
        </div>
      </div>

    
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col">
            <img
              src="/assets/collegemodule/admission.png"
              alt="Mobile"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Admission Management</h1>
          </div>
          <div className="flip-card-back bg-yellow-300 rounded-xl p-4">
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Online Student Registration</p>
            </div> 
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Merit List Generation</p>
                
            
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Student Course Selection</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Live Tracking of Admission Status</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Online Fees Payment</p>
            </div>

          </div>
        </div>
      </div>

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col">
            <img
              src="/assets/collegemodule/exam.png"
              alt="Mobile"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Exams Management</h1>
          </div>
          <div className="flip-card-back bg-blue-200 rounded-xl p-4">
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Online Question Paper creation</p>
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Enables MCQ/Descriptive type Questions</p>
            </div> 
           
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Student Outcome Calculation</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Verification of Student’s Identity</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Runtime Student Monitoring</p>
            </div>
          </div>
        </div>
      </div>

      
  
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col">
            <img
              src="/assets/collegemodule/fees.png"
              alt="Location"
              className="w-[15rem] h-[15rem]"
            />
             <h1 className="text-xl font-semibold mb-2 text-center">Fees Management</h1>
          </div>
          <div className="flip-card-back bg-yellow-300 rounded-xl p-3">
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '> UPI, Credit/Debit card Payments</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>E-receipt Generation</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Safe Transactions</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Notifications for Pending Fees</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Fee Structure Allocation</p>
            </div>

          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col">
            <img
              src="/assets/collegemodule/attendance.png"
              alt="Mobile"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Attendance Management</h1>
          </div>
          <div className="flip-card-back bg-blue-200 rounded-xl p-4">
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Automatic Attendance Record</p>
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Laptop and Mobile Application</p>
            </div> 
           
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Integration with Biometric and RFID device</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Easy Report Generation</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Automatic email/SMS Notifications</p>
            </div>
          </div>
        </div>
      </div>

    
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col">
            <img
              src="/assets/collegemodule/library.png"
              alt="Mobile"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Library Management</h1>
          </div>
          <div className="flip-card-back bg-yellow-300 rounded-xl p-4">
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Automatic Email/SMS reminder</p>
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Books Tracking and Issuing</p>
            </div> 
           
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Maintenance of Vendor Information</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Book Search using Keywords</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Calculation of Late Fees</p>
            </div>
          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col">
            <img
              src="/assets/collegemodule/HRMS.png"
              alt="Mobile"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Human Resourse Management</h1>
          </div>
          <div className="flip-card-back bg-blue-200 rounded-xl p-4">
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Maintenance of Faculty Profile</p>
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Faculty Recruitment</p>
            </div> 
           
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Leave Management</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Service Books and Record Management’</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Maintenance of Faculty Profile</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front flex flex-col">
            <img
              src="/assets/collegemodule/payroll.png"
              alt="Mobile"
              className="w-[15rem] h-[15rem]"
            />
            <h1 className="text-xl font-semibold mb-2 text-center">Payroll Management</h1>
          </div>
          <div className="flip-card-back bg-yellow-300 rounded-xl p-4">
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '> Leave and Incentive Calculation</p>
            </div>
          <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Integration with HRMS</p>
            </div> 
           
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Increment Cycle Information</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>MIS Report Generation</p>
            </div>
            <div className='flex'>
                <span className="mr-2 rounded-full font-extrabold mt-3 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                <p className='mt-2 font-opensans font-semibold '>Automated Salary Credit</p>
            </div>
          </div>
        </div>
      </div>
      </div>

   
    </div>
  );
};

export default CollegeModule;
