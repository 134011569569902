import React from 'react';
import { BiSolidRightArrow } from 'react-icons/bi';

const Tools = () => {

  const cybercardData = [
    {
        title: 'VAPT assessment',
        description: 'We provide one-time basic VAPT assessments to educational institutes. Worth Rs. 50,000.00.',
        imageUrl: '/assets/Cybersecurity/cyberimage/vpat.png', 
      },
      {
        title: 'Free ERP access',
        description: 'ERP fee management and dependent module for 1 year. Worth Rs. 30,000.00.',
        imageUrl: '/assets/Cybersecurity/cyberimage/erp.png', 
      },
      {
        title: 'Revenue sharing',
        description: 'We work on a fee revenue-sharing model with the education institute. Depends upon the no of learners.',
        imageUrl: '/assets/Cybersecurity/cyberimage/revenue.png', 
      },
   ];

  return (
    <>
    <div className="flex justify-between items-center p-4 ">
    <div>
       <div className='flex justify-center flex-col items-center md:mb-16 mb-6 mt-4'>
            <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Tools</h1>
            <img src='/assets/cap.png' alt='cap image' className='w-1/2 md:w-1/5' />
      </div>
      <div className="lg:flex md:space-x-6 md:mx-16 mx-8 lg:mb-[-7rem] ">
        <div className="lg:w-1/2 flex justify-center">
          <img
            src="/assets/Cybersecurity/cyberimage/tools.png"
            alt="Cyber Tools"
            className="lg:max-w-[100%] md:max-w-[50%] max-w-[100%]  h-[70%] rounded-xl hover:scale-125 transition duration-300"
          />
        </div>
        <div className="lg:w-1/2 lg:mt-0 mt-8">
          <p className="text-gray-600 font-opensans font-semibold text-justify mb-3">
             Cyber tools are essential software and hardware solutions designed to protect, manage, and secure digital assets in an increasingly interconnected and vulnerable digital world.
          </p>
          <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Website assessment tools.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Database assessment.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Fishing understanding tools.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Nmap.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Crackmapexec.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Responder.py</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Ntlmrelayx.py</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Mimikatz.</p>
            </div>
          
       
        </div>
      </div>

    </div>
    
    
  </div>
   

  <div className="flex justify-between items-center p-4 bg-blue-100 ">
    <div>
       <div className='flex justify-center flex-col items-center md:mb-16 mb-6 mt-4'>
            <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Benefits for Learner's</h1>
            <img src='/assets/cap.png' alt='cap image' className='w-1/2 md:w-1/5' />
      </div>
      <div className="lg:flex md:space-x-6 md:mx-16 mx-8 flex-row-reverse ">
        <div className="lg:w-1/2 flex justify-center items-center">
          <img
            src="/assets/Cybersecurity/cyberimage/learner.png"
            alt="Cyber Tools"
            className="lg:max-w-[100%] md:max-w-[50%] max-w-[100%] h-[80%] rounded-xl hover:scale-125 transition duration-300"
          />
        </div>
        <div className="lg:w-1/2 lg:mt-0 mt-8">
          <p className="text-gray-600 font-opensans font-semibold text-justify mb-3">
          Learning cybersecurity provides individuals with the knowledge and skills to protect their digital identities and sensitive information, enhancing their online safety and privacy.
          </p>
          <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Cyber security understanding.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Ethical hacking.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Vulnerability assessment of web and network.</p>
            </div>
           
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Chance to start a career in the Cyber field.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Career Guidance.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Placement opportunity.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>CB Pro mobile app access.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Soft skill development.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Resume builder tool and LMS access.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Pocket money resource.</p>
            </div>

            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Corporate training.</p>
            </div>
            <div className='mt-2 flex'>
            <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
            <p className='font-opensans text-justify font-semibold text-gray-600'>Many mores....</p>
            </div>
       
        </div>
      </div>

    </div>
    
    
  </div>

  <div className="flex justify-between items-center p-4 ">
    <div>
       <div className='flex justify-center flex-col items-center md:mb-16 mb-6 mt-4'>
            <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Benefits for College / institution's</h1>
            <img src='/assets/cap.png' alt='cap image' className='w-1/2 md:w-1/5' />
      </div>
       
      <div className="md:flex w-full flex-wrap gap-6 md:mx-16 mx-4 mb-8">
        {cybercardData.map((card, index) => (
            <div key={index} className="lg:w-[29%] md:w-[38%] w-[91%] mb-8 shadow-orange-300 shadow-xl">
            <div className="bg-white rounded-lg p-4 shadow-md hover:bg-blue-100 hover:scale-110 transition duration-300 flex flex-col h-full">
                <img
                src={card.imageUrl}
                alt={card.title}
                className="w-[8rem] h-32 object-cover mx-auto rounded-md mb-4"
                />
                <div className="flex flex-col justify-between">
                <div>
                    <h3 className="text-xl font-semibold mb-2 text-center">{card.title}</h3>
                    <p className="text-gray-600 font-opensans font-semibold ">{card.description}</p>
                </div>
                </div>
            </div>
            </div>
        ))}
        </div>

    </div>
    
    
  </div>

  
  </>
  );
};

export default Tools;
