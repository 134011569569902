import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { HiChevronDoubleRight } from 'react-icons/hi';
import MobileNavMenu from './MobileNavMenu.jsx'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [companyMenuOpen, setCompanyMenuOpen] = useState(false);
  const [productsMenuOpen, setProductsMenuOpen] = useState(false);
  const [educationMenuOpen, setEducationMenuOpen] = useState(false);
  const [specialMenuOpen, setSpecialMenuOpen] = useState(false);
  const [resourseMenuOpen, setResourseMenuOpen] = useState(false);

  const navigate = useNavigate();


  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleResourseMenuOpen = () => {
    setResourseMenuOpen(true);
  };

  const handleResourseMenuClose = () => {
    setResourseMenuOpen(false);
  };

  const handleEducationMenuOpen = () => {
    setEducationMenuOpen(true);
  };

  const handleEducationMenuClose = () => {
    setEducationMenuOpen(false);
  };

  const handleSpecialMenuOpen = () => {
    setSpecialMenuOpen(true);
  };

  const handleSpecialMenuClose = () => {
    setSpecialMenuOpen(false);
  };


  const handleCompanyMenuOpen = () => {
    setCompanyMenuOpen(true);
  };

  const handleCompanyMenuClose = () => {
    setCompanyMenuOpen(false);
  };

  const handleProductsMenuOpen = () => {
    setProductsMenuOpen(true);
  };

  const handleProductsMenuClose = () => {
    setProductsMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
     <div className={`w-full z-10 ${isMobileMenuOpen ? '' : 'md:fixed'} top-12 md:mt-0 mt-0`}>
      <nav className="bg-white shadow">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center py-2">
            <div className="flex items-center space-x-2">
            <Link
                to="/">
              <img src="/assets/compus_beez_logo.png" alt="Logo" className="h-16" />  </Link>
            </div>
            <div className="hidden md:flex space-x-4">
              <Link
                to="/"
                className="text-gray-800 md:mt-[-4px] lg:text-xl font-semibold hover:bg-yellow-500 hover:text-white px-2 py-1 rounded"
              >
                Home
              </Link>
              <div
                className="text-gray-800 lg:text-xl font-semibold cursor-pointer relative group"
                onMouseEnter={handleCompanyMenuOpen}
                onMouseLeave={handleCompanyMenuClose}
              >
                Company
                {companyMenuOpen && (
                  <div className="absolute top-full left-0 w-[12rem] bg-white border border-t-yellow-400 border-t-8 border-blue-400 rounded shadow-lg opacity-0 group-hover:opacity-100">
                    <div className="p-2">
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        onClick={handleCompanyMenuClose}
                      >
                        About Us
                      </a>
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block pb-2 text-lg"
                        onClick={handleCompanyMenuClose}
                      >
                        Customers
                      </a>
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block pb-2 text-lg"
                        onClick={handleCompanyMenuClose}
                      >
                        Testimonials
                      </a>
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block pb-2 text-lg"
                        onClick={handleCompanyMenuClose}
                      >
                        Certification
                      </a>
                      
                    </div>
                  </div>
                )}
              </div>
              <div
                  className="relative group"
                  onMouseEnter={handleProductsMenuOpen}
                  onMouseLeave={handleProductsMenuClose}
                >
                  <div className="text-gray-800  lg:text-xl font-semibold cursor-pointer">
                    Products
                  </div>
                  {productsMenuOpen  && (
                    <div className="absolute top-full md:left-[-37rem]  md:w-[87rem] bg-white border border-t-yellow-400 border-t-8 border-blue-400 rounded shadow-lg opacity-0 group-hover:opacity-100">
                      <div className="md:flex space-x-2">
                        <div className="p-2">
                          <a
                            href=""
                            className="text-gray-800 hover:text-blue-500 block py-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                          <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 '/>Online Assestment Software</div>
                            
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                          <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>Accreditation Data Management (ADMS)</div>
                            
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                          <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>Digital Evaluation System</div>
                            
                          </a>
                        
                        </div>
                        <div className="p-2">
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block py-2 text-lg font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                           <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>Admission Management</div>
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                            <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>Exam Management</div>
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                           <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>ERP Campus</div>
                          </a>
                        
                        </div>
                        <div className="p-2">
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block py-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                            <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>Student Information System</div>
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                            <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>Technical Institute Management</div>
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                           <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>Fee Management System</div>
                          </a>
                        
                        </div>
                        <div className="p-2">
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block py-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                            <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>CSMS (School Management)</div>
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                            <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>CCMS (College Management)</div>
                          </a>
                          <a
                            href="#"
                            className="text-gray-800 hover:text-blue-500 block pb-2 text-lg  font-semibold"
                            onClick={handleProductsMenuClose}
                          >
                           <div className='flex mb-2'><HiChevronDoubleRight className='mt-[4px] mr-2 lg:ml-6 text-blue-500 text-lg'/>CUMS (University Management)</div>
                          </a>
                        
                        </div>
                    </div>
                    
                  </div>
                )}
              </div>
              <div
                className="text-gray-800 lg:text-xl font-semibold cursor-pointer relative group"
                onMouseEnter={handleEducationMenuOpen}
                  onMouseLeave={handleEducationMenuClose}
              >
                Educational ERP
                {educationMenuOpen && (
                  <div className="absolute top-full left-0 w-[16rem] bg-white border border-t-yellow-400 border-t-8 border-blue-400 rounded shadow-lg opacity-0 group-hover:opacity-100">
                    <div className="p-2">
                     
                      <Link
                        to="https://erp.campusbeez.com/" target='_blank'
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        
                      >
                        University ERP
                      </Link>
                      <Link
                        to="/college"
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        
                      >
                        College ERP
                      </Link>
                      <Link
                        to="https://erp.schoolsdunia.com/" target='_blank'
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        
                      >
                        School ERP
                      </Link>
                     
                     
                    </div>
                  </div>
                )}
              </div>
              <div
                className="text-gray-800  md:mt-[-1px] lg:text-xl font-semibold cursor-pointer relative group"
                onMouseEnter={handleSpecialMenuOpen}
                onMouseLeave={handleSpecialMenuClose}
              >
                Special Features
                {specialMenuOpen && (
                  <div className="absolute top-full left-0 w-[20rem] bg-white border border-t-yellow-400 border-t-8 border-blue-400 rounded shadow-lg opacity-0 group-hover:opacity-100">
                    <div className="p-2">
                    <Link
                        to="/comingsoon"
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        
                      >
                        CB App
                      </Link>
                     
                      <Link
                        to="https://cbpro.campusbeez.com/"
                        target='blank'
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                      >
                        Resume Builder
                      </Link>
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        onClick={handleSpecialMenuClose}
                      >
                        Learning Management System(LMS)
                      </a>
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        onClick={handleSpecialMenuClose}
                      >
                        Biometric & RFID Attendance
                      </a>
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        onClick={handleSpecialMenuClose}
                      >
                        Library Management
                      </a>
                      <a
                        href="#"
                        className="text-gray-800 hover:text-blue-500 block py-2 text-lg"
                        onClick={handleSpecialMenuClose}
                      >
                        HR Management
                      </a>
                     
                     
                    </div>
                  </div>
                )}
              </div>
             
              <Link
                to="/program"
                className="text-gray-800 md:mt-[-5px] lg:text-xl font-semibold hover:bg-yellow-500 hover:text-white px-2 py-1 rounded"
              >
                Program
              </Link>
              <Link
                to="https://cbpro.campusbeez.com/"
                target='blank'
                className="text-gray-800 md:mt-[-5px] lg:text-xl font-semibold hover:bg-yellow-500 hover:text-white px-2 py-1 rounded"
              >
                CB Pro
              </Link>
              <Link
                to="/contact"
                className="text-gray-800 md:mt-[-5px] lg:text-xl font-semibold hover:bg-yellow-500 hover:text-white px-2 py-1 rounded"
              >
                Contact Us
              </Link>
            </div>
            <div className="md:hidden">
              <FiMenu
                size={24}
                className="text-gray-800 cursor-pointer"
                onClick={toggleMobileMenu}
              />
            </div>
          </div>
          </div>
      </nav>
          {/* Mobile Menu */}
         
            {/* <MobileNavMenu isOpen={isMobileMenuOpen} handleCompanyMenuClose={handleCompanyMenuClose} /> */}
            <MobileNavMenu isOpen={isMobileMenuOpen} handleMenuClose={toggleMobileMenu} />
            
            
     
    </div>
  );
};

export default Navbar;