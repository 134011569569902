import React, { useState } from 'react';
import dataproduct from './dataproduct';
import { FaGraduationCap, FaSchool, FaUniversity, FaHandshake } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';

const Product = () => {
  const [selectedButton, setSelectedButton] = useState(1); 

  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  return (
    <div className="py-10 px-8">
      <div className="max-w-6xl lg:mx-auto md:mx-8">
           <div className='flex justify-center flex-col items-center'>
              <h1 className="text-2xl font-semibold md:text-4xl mb-2 text-center font-opensans">Educational ERP Products</h1>
              <img src='/assets/cap.png' alt='cap image' className='md:w-1/3  '/>
            </div>

            
            <p className="text-gray-600 mb-2 mx-4 md:mx-0 font-opensans font-semibold mt-6 text-justify">
            Campusbeez Education ERP Software is a cutting-edge cloud-based solution tailored for educational institutions, spanning from schools to colleges and universities. This advanced software is designed to streamline and automate the intricate web of processes within these institutions, fostering transparency and efficiency across the board.
            </p>
            <p className="text-gray-600 mb-2 mx-4 md:mx-0 font-opensans font-semibold text-justify">
            Within the Education ERP system, it's like having a master conductor orchestrating every facet of your institution's operations. It isn't just about managing academics; it's about optimizing the entire ecosystem. This comprehensive ERP solution seamlessly coordinates all departments, whether it's the administrative heart, financial hub, library operations, or human resources management.
            </p>
            <p className="text-gray-600 mb-2 mx-4 md:mx-0 font-opensans font-semibold text-justify">
            Think of Educational ERP Software as the backbone of your institution's digital transformation. It's an integrated web-based platform that empowers educational institutions to harness the full potential of their data. It functions as a robust Management Information System (MIS), automating the intricate machinery that drives an educational institute.
            </p>
            <p className="text-gray-600 mb-8 mx-4 md:mx-0 font-opensans font-semibold text-justify">
            At its core, Education ERP is a unifying force. It serves as a centralized database system that facilitates effortless information flow and communication across all corners of the institution. The primary objective of this system is to harmonize the finest educational technology modules, enabling seamless communication and optimizing the institution's return on investment. In essence, it's the digital nucleus that powers educational excellence and innovation.
            </p>
        
        <hr className="border-2 border-yellow-300" />

        <div className="flex flex-col mx-8 md:mx-0  md:flex-row md:flex-wrap space-y-4 md:space-y-0 md:space-x-8 mt-8">
                {dataproduct.map((item, index) => {
                  let IconComponent;

                  if (item.title === 'School ERP') {
                    IconComponent = FaSchool;
                  } else if (item.title === 'College ERP') {
                    IconComponent = FaGraduationCap;
                  } else if (item.title === 'University ERP') {
                    IconComponent = FaUniversity;
                  } else if (item.title === 'Trust Management') {
                    IconComponent = FaHandshake;
                  }

                  return (
                    <button
                      key={index}
                      className={`bg-blue-900 text-white px-4 py-2 rounded-lg transition duration-300 ${
                        selectedButton === index + 1 ? 'bg-yellow-600' : 'hover:bg-yellow-600'
                      }`}
                      onClick={() => handleButtonClick(index + 1)}
                    >
                      <div className="flex">
                        <IconComponent className={`mr-2 ${selectedButton === index + 1 ? 'mb-2 md:mb-0' : ''}`} size="24" />
                        <p className="font-opensans text-xl">{item.title}</p>
                      </div>
                    </button>
                  );
                })}
           </div>


      </div>
      {selectedButton !== null && (
        <>
        <div className="max-w-6xl  mx-4 lg:mx-auto md:mx-4 mt-10 flex flex-col lg:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <div className="md:w-full lg:w-1/2">
            <h2 className="text-2xl font-semibold mb-2 font-opensans">{dataproduct[selectedButton - 1].title}</h2>
            <p className='font-opensans mb-4 text-base text-justify'>{dataproduct[selectedButton - 1].description}</p>
            <ul className="list-none pl-4 space-y-2 font-opensans mt-4">
                {dataproduct[selectedButton - 1].points.map((point, index) => (
                  <li key={index} className="flex items-start">
                    <span className="mr-2 rounded-full font-extrabold mt-1 text-blue-500 font-opensans"><BiSolidRightArrow /></span>
                     {point}
                  </li>
                ))}
              </ul>
            
          </div>
          <div className="md:w-4/5 lg:w-1/2">
            <img
              src={dataproduct[selectedButton - 1].imageUrl}
              alt={dataproduct[selectedButton - 1].title}
              className="w-full h-auto rounded-lg hover:scale-105 transition duration-300 "
            />
          </div>
         
        </div>

        <div className='flex md:ml-[11rem] ml-[7rem]'>
          <button className="bg-yellow-500 text-white px-5 py-3 rounded-md text-xl font-semibold mt-4  hover:bg-blue-400 transition duration-300">
                  Read More
          </button>
        </div>

        
        </>
      )}
    </div>
    
  );
};

export default Product;





