import React from 'react';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import {BiPhoneCall } from 'react-icons/bi';
import { HiLocationMarker } from 'react-icons/hi';
import { Helmet } from 'react-helmet';

const Privacy = () => {
  return (
    <div className='md:mt-32 mb-12'>
       <Helmet>
    <meta charset="utf-8" />
    <title>Campusbeez |Campus Management Solutions,ERP Software,Resume Builder,Online Assesment software,University ERP,College ERP</title>
    <meta name="description" content="Elevate your institution's efficiency with CampusBeez,a comprehensive education management solution by LiveLake Education."/>
    <meta name="keywords" content="School Management,College Management,University Management,Education Technology,CampusBeez App,Resume Builder,Learning Management System,Biometric Attendance System,RFID Attendance System,Library Management,HR Management,LiveLake Education"/>
    <meta name="robots" content="index,follow"/>
    <meta name="copyright" content="Livelake Education"/>
    <meta name="email" content="support@campusbeez.com"/>
    <meta name="language" content="EN"/>
    <meta name="DC.Publisher" content="Campusbeez"/>
    <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
    <meta name="DC.Language" content="en"/>
    <meta name="Distribution" content="Global"/>
    <meta name="geo.region" content="IN"/>
    <link rel="canonical" href="https://campusbeez.com/"/>
    <meta name="geo.placename" content="India"/>
    <meta name="geo.position" content="Gurgaon"/>
    <meta name="geo.region" content="usa"/>
    
    {/* Asynchronous script loading */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>
       </Helmet>
      <h1 className='text-2xl md:text-4xl font-semibold mb-4 font-opensans lg:text-5xl text-center py-8'>Privacy Policy</h1>
      <div className='text-base lg:text-lg md:text-md md:px-24 px-12 text-justify'>
        <p className='mb-6 font-opensans'>Effective date: March 01, 2023</p>
        <p className='mb-6 font-opensans'>
        CampusBeez is a product of ( LiveLake Education Pvt Ltd) operates the <a href='https://erp.campusbeez.com/' target='_blank' className='text-blue-600'> erp.campusbeez.com</a> & <a href='https://campusbeez.com/' target='_blank' className='text-blue-600'>campusbeez.com</a> website (the "Education Software service").
        </p>
        <p className='mb-6 font-opensans'>
        LiveLake Education provides applications to parents, children and staff of education institutes to provide a seamless platform that covers various aspects of a student's learning cycle while at a particular Instutions. This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
        </p>
       <p className='mb-6 font-opensans'>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from  <a href='https://campusbeez.com/' target='_blank' className='text-blue-600'>campusbeez.com</a>.</p>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Information Collection And Use</h2>
        <p className='mb-6 font-opensans'>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Types of Data Collected</h2>
        <p className='mb-6 font-opensans'>Instutions Data and personal information for contact</p>
        <p className='font-opensans mb-4'>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
        <p className='font-opensans'>Email address</p>
        <p className='font-opensans'>First name and last name</p>
        <p className='font-opensans'>Phone number</p>
        <p className='font-opensans'>Address, State, Province, ZIP/Postal code, City</p>
        <p className='mb-6 font-opensans'>Cookies and Usage Data</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Usage Data</h2>
        <p className='mb-6 font-opensans'>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Tracking & Cookies Data</h2>
        <p className='mb-6 font-opensans'>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
        <p className='mb-6 font-opensans'>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
        <p className='mb-6 font-opensans'>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Use of Data</h2>
        <p className='mb-6 font-opensans'>LiveLake Education Pvt Ltd uses the collected data for various purposes:</p>
       
        <p className='font-opensans'>To provide and maintain the Service</p>
        <p className='font-opensans'> To notify you about changes to our Service</p>
        <p className='font-opensans'>To allow you to participate in interactive features of our Service when you choose to do so</p>
        <p className='font-opensans'>To provide customer care and support</p>
        <p className='mb-6 font-opensans'>To monitor the usage of the Service</p>
        <p className='mb-6 font-opensans'>To detect, prevent and address technical issues</p>
       </div>

       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Transfer Of Data</h2>
        <p className='mb-6 font-opensans'>Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
        <p className='mb-6 font-opensans'>If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.</p>
        <p className='mb-6 font-opensans'>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
        <p className='mb-6 font-opensans'>LiveLake Education will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
       </div>

       <h2 className='mb-4 text-3xl font-semibold font-opensans'>Disclosure Of Data</h2>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Legal Requirements</h2>
        <p className='mb-6 font-opensans'>LiveLake Education Pvt Ltd may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
       
        <p className='font-opensans'>To comply with a legal obligation</p>
        <p className='font-opensans'> To protect and defend the rights or property of LiveLake Education Pvt Ltd</p>
        <p className='font-opensans'>To prevent or investigate possible wrongdoing in connection with the Service</p>
        <p className='font-opensans'>To protect the personal safety of users of the Service or the public</p>
       
        <p className='mb-6 font-opensans'>To protect against legal liability</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Security Of Data</h2>
        <p className='mb-6 font-opensans'>The security of your data is important to us, we strive to use commercially acceptable means to protect your Personal Data and shall ensure all reasonable care in handling of the said data.</p>
       </div>
       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Links To Other Sites</h2>
        <p className='mb-6 font-opensans'>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p className='mb-6 font-opensans'>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
       </div>

       <div>
        <h2 className='mb-4 text-2xl font-semibold font-opensans'>Changes To This Privacy Policy</h2>
        <p className='mb-6 font-opensans'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p className='mb-6 font-opensans'>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
        <p className='mb-6 font-opensans'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        <p className='mb-6 font-opensans'>For any Kind of legal complications only valid in Gurgaon (HR), Juridiction.</p>
       </div>

       <div>
       <h2 className='mb-4 text-2xl font-semibold font-opensans'>Contact Us</h2>
       <p className='mb-6 font-opensans'>If you have any questions about this Privacy Policy, please contact us:</p>
       <div className='flex space-x-2 mb-2'>
       <HiLocationMarker size={35} className='bg-yellow-500'/>
       <p>Epic Corporations Pvt Ltd Ansal API Palam Corporate Plaza,
               TF-427, A-Block, Palam Vihar, Gurugram, Haryana 122017 (India)</p>
       </div>
       
       {/* <div className='flex space-x-2 mb-2'>
       <FaWhatsapp size={35}  className='bg-green-500'/>
       <p>+91 </p>
       </div> */}
       <div className='flex space-x-2 mb-2'>
       <BiPhoneCall size={35}  className='bg-blue-600'/>
       <p>+91 8860014004</p>
       </div>
       
       <div className='flex space-x-2 mb-2'>
       <FaEnvelope size={35} className='bg-yellow-500 p-1'/>
       <p>support@campusbeez.com</p>
       </div>
       
       </div>
     
      </div>
    </div>
  );
};

export default Privacy;
