import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MaterialReactTable from 'material-react-table';
import { AiFillDelete } from 'react-icons/ai'
import { FaEye} from 'react-icons/fa'
import { FaFileImport } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import Swal from 'sweetalert2'


import { toast, ToastContainer } from 'react-toastify';
import QuestionAddForm from './QuestionAddForm';
import OnlineExamAddForm from './OnlineExamAddForm';



const OnlineExam = () => {
   
    
    const [data,setData] = useState('')
    const loginToken = JSON.parse(localStorage.getItem('LoginToken'))
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false); 
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [showImportModal,setShowImportModal] = useState(false)
    const [file,setFile]= useState("")

    const [editQuestionData, setEditQuestionData] = useState({});

    const [editQuestionId, setEditQuestionId] = useState(null);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setNewQuestion(''); 
    };


    const handleAddQuestion = async () => {
        // try {
        //   const formData = new FormData();
        //   formData.append('name', newQuestion); 
        //   const response = await axios.postForm(
        //     `${process.env.REACT_APP_RESUME_URL}/certification/store`,
        //     formData,
        //     {
        //       headers: {
        //          'Authorization':`Bearer  ${loginToken}`,
        //         'Accept': 'application/json'
        //       }
        //     }
        //   );
    
        //   if (response?.data?.code === 200) {
        //     // Update your data state with the new certificate data
        //     setData([...data, response.data.data]); 
        //     toast.success('Certificate Added Successfull')
    
        //     // Close the popup
        //     handleClosePopup();
        //   }
        // } catch (error) {
        //   console.error('Error adding certificate:', error);
        // }
      };


	

  
    const getQuestion = async()=>{
        // const getTemplate = await axios.get(`${process.env.REACT_APP_RESUME_URL}/certification`,{
        //     headers:{
        //         'Authorization':`Bearer  ${loginToken}`,
        //         'Accept':"application/json"
        //     }
        // })
        // const response = await getTemplate?.data
        // console.log('response',response)
        // if(response?.data){
        //     setData(response?.data)
        // }
    }
    useEffect(()=>{
        getQuestion()
    },[])

    const handleOpenEditPopup = (cell) => {
    //   console.log('row' ,cell?.row?.original?.id);
    //   setEditQuestionId(cell?.row?.original?.id);
    //   setIsEditPopupOpen(true);
    //   setEditQuestionData(cell?.row?.original);
    };
  
    // Add a function to close the edit popup form
    const handleCloseEditPopup = () => {
      setIsEditPopupOpen(false);
      setEditQuestionData({});
    };
   

    const handleDelete = (cell)=>{
      console.log("Dealer",cell?.row?.original)
      Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async(result) => {
          if (result.isConfirmed) {
              try {
                  const deleteUser = await fetch(`${process.env.REACT_APP_RESUME_URL}/certification/delete/${cell?.row?.original?.id}`,{
                    method:"post",  
                    headers:{
                          'Authorization':`Bearer  ${loginToken}`,
                          'Accept':"application/json"
                      }
                  })
                  const response = await deleteUser?.json()
                  // console.log("response",response)
                  toast.success(`${response?.message}`)
                  getQuestion()
                  // Swal.fire(
                  //   'Deleted!',
                  //   'Your file has been deleted.',
                  //   'success'
                  // )          
              } catch (error) {
                  if(error?.response?.data?.errors)
                  error?.response?.data?.errors?.map((x)=>(
                      toast.error(`${x}`)
                  ))
              }
      
  }})
    }
    
    const handleEditQuestion = async () => {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_RESUME_URL}/certification/update/${editQuestionId}`, // Use the certificate ID in the API URL
    //       editQuestionData, // Use the updated certificate data
    //       {
    //         headers: {
    //           'Authorization': `Bearer  ${loginToken}`,
    //           'Accept': 'application/json'
    //         }
    //       }
    //     );
  
    //     if (response?.data?.code === 200) {
    //       // Find the index of the edited certificate in the data state
    //       const editedCourseIndex = data.findIndex((certificate) => certificate.id === editQuestionId);
        
    //       if (editedCourseIndex !== -1) {
    //         // Update the certificate data in the state
    //         const updatedData = [...data];
    //         updatedData[editedCourseIndex] = response.data.data;
    //         setData(updatedData);
    //         toast.success('Course Updated');
    //       }
  
    //       // Close the edit popup
    //       handleCloseEditPopup();
    //       getQuestion();
    //     }
    //   } catch (error) {
    //     console.error('Error editing certificate:', error);
    //   }
    };
  
    
    const heading = [
        // {
        //     accessorKey: 's_no',
        //     header: "S.No",
        //     size: 110,
        //     Cell: ({ cell, row }) => <div>{parseInt(row?.id) + 1}</div>,
           
        // },
        {
            accessorKey: 'title',
            header: 'Exam ',
            size: 80,
        },
        {
          accessorKey: 'quiz',
          header: 'Quiz',
          size: 80,
      },
      {
        accessorKey: 'questions',
        header: 'Questions',
        size: 100,
    },
        {
            accessorKey: 'duration',
            header: 'Duration',
            size: 80,
        },
        {
            accessorKey: 'attempt',
            header: 'Attempt',
            size: 60,
        },
        {
          accessorKey: 'exampublish',
          header: 'Exam Publish',
          size: 60,
      },
      {
        accessorKey: 'resultpublish',
        header: 'Result Publish',
        size: 60,
    },
     
      
       
        {
            header:"Action",
            size: 100,
            Cell:({cell})=>
              <>
              {/* <button onClick={(e)=>handleProfileView(e,cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'><FaEye size={20}/></button> */}
              <button onClick={() => handleOpenEditPopup(cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'>
                <FaEye size={20} />
              </button>
              <button onClick={()=>handleDelete(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><AiFillDelete size={20}/></button>
              </>  
        },
       
    ]



  return (

  <div className='w-full'>
   <ToastContainer 
      position='top-center'
      autoClose={3000}/>
      
    
   
    <div className='mx-2'>
      <div className='flex justify-between items-center'>
        <div>
          <h2 className='text-2xl font-bold capitalize'>Question Exam List</h2>
        </div>
        <div className='space-x-3 flex'>
         
          
          <button onClick={handleOpenPopup} className="flex bg-black text-white py-2 px-4 rounded">
            <span className='text-2xl text-white'>
				<IoMdAdd />	
			</span>
            Add Exam
          </button>
        </div>
      </div>
      <div className='w-full mt-4 relative'>
        <MaterialReactTable enableFullScreenToggle={false} columns={heading} data={data} />
        {isPopupOpen && (
            
          <div className="absolute top-24 left-0 w-full h-full flex justify-center items-center ">
            <div className="bg-white p-4 rounded shadow-md mt-[9rem] " style={{ width:'90%', zIndex: 10 }}>
                <div className='flex justify-end' onClick={handleClosePopup}>
                  <span  className='text-neutral-50  hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
                </div>
              <OnlineExamAddForm />
             
            </div>
          </div>
        )}
        {isEditPopupOpen && (
          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
            <div className='bg-white p-4 rounded shadow-md' style={{ width: '40%', marginTop: '-90px', zIndex: 10 }}>
              <h3 className='text-xl font-semibold mb-2'>Edit Certification</h3>
              <form>
                {/* Add form fields to edit the certificate data */}
                <div className={`bg-white my-4 p-1 flex border border-gray-200 rounded`}>
                  <input
                    name='editcertificate'
                    placeholder='Edit Course'
                    value={editQuestionData.name || ''}
                    onChange={(e) => setEditQuestionData({ ...editQuestionData, name: e.target.value })}
                    className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}
                  />
                </div>
                <button
                  type='button'
                  onClick={handleEditQuestion}
                  className='bg-green-600 text-white py-2 px-4 rounded'
                >
                  Update
                </button>
                <button
                  type='button'
                  onClick={handleCloseEditPopup}
                  className='bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded'
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  )
}

export default OnlineExam