import './App.css';
import Appbar from './components/appbar/Appbar';
import Navbar from './components/navbar/Navbar';
import Home from './pages/Home';
import Contact from './pages/contact/Contact';
import Footers from './components/footers/Footers';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Refund from './pages/Refund';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ScrollToTop from './components/scrolltop/ScrollToTop';
import University from './pages/university/University';
import College from './pages/college/College';
import School from './pages/school/School';
import MobileNavMenu from './components/navbar/MobileNavMenu';
import Comingsoon from './pages/comingsoon/Comingsoon';
import Hiring from './pages/hiring/Hiring';
import AdminPanel from './pages/adminpanel/AdminPanel';
import Program from './pages/program/Program';
import Cybersecurity from './pages/cybersecurity/Cybersecurity';
import Faq from './pages/faq/Faq';
// import NextPage from './NextPage';
import Cybersecurityfrom from './pages/cybersecurity/cybersecurityfrom';
import Equraiyform from './components/Equraiyform/equraiyform';


function App() {
  const location = useLocation();

  // Check if the current location is /adminpanel
  const isAdminPanelRoute = location.pathname.startsWith('/adminpanel');
 

  return (
    <div className='overflow-x-hidden'>
      {!isAdminPanelRoute && <Appbar />}
      {!isAdminPanelRoute && <Navbar />}
      <MobileNavMenu />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/university" element={<University />} />
        <Route path="/college" element={<College />} />
        <Route path="/school" element={<School />} />
        <Route path="/comingsoon" element={<Comingsoon />} />
        <Route path="/hiring" element={<Hiring />} />
        <Route path="/program" element={<Program />} />
        <Route path="/cybersecurity" element={<Cybersecurity />} />
        <Route path="/faq" element={<Faq />} />
        <Route exact path="/adminpanel/*" element={<AdminPanel />} />
        <Route path="/cybersecurityfrom" element={<Cybersecurityfrom/>} />
        
      </Routes>
      {/* <Equraiyform/> */}
      {!isAdminPanelRoute && <Equraiyform/>}
      {!isAdminPanelRoute && <Footers />}
    </div>
  )
}

export default function AppWithRouter() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
