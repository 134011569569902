import React from 'react'
import {Helmet} from "react-helmet";

const Refund = () => {
  return (
    <div className='md:mt-32 mb-12'>
     <Helmet>
        
        <meta charset="utf-8" />
        <title>Campusbeez |Campus Management Solutions</title>
        <meta name="description" content="Elevate your institution's efficiency with CampusBeez,a comprehensive education management solution by LiveLake Education."/>
        <meta name="keywords" content="School Management,College Management,University Management,Education Technology,CampusBeez App,Resume Builder,Learning Management System,Biometric Attendance System,RFID Attendance System,Library Management,HR Management,LiveLake Education"/>
        <meta name="robots" content="index,follow"/>
        <meta name="copyright" content="Livelake Education"/>
        <meta name="email" content="support@campusbeez.com"/>
        <meta name="language" content="EN"/>
        <meta name="DC.Publisher" content="Campusbeez"/>
        <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
        <meta name="DC.Language" content="en"/>
        <meta name="Distribution" content="Global"/>
        <meta name="geo.region" content="IN"/>
        <link rel="canonical" href="https://campusbeez.com/"/>
        <meta name="geo.placename" content="India"/>
        <meta name="geo.position" content="Gurgaon"/>
        <meta name="geo.region" content="usa"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>

    </Helmet>
    <h1 className='text-2xl md:text-4xl font-semibold mb-4 font-opensans lg:text-5xl text-center py-8'>Refund Policy</h1>
    <div className='text-base lg:text-lg md:text-md md:px-24 px-12  text-justify'>
    </div>
    </div>
  )
}

export default Refund