import React from 'react'
import Herosection from '../components/herosection/Herosection' ;
import Client from '../components/client/Client' ;
// import Rating from '../components/rating/Rating' ;
import Stastics from '../components/stats/Stastics' ;
import Product from '../components/product/Product' ;
import Offer from '../components/offer/Offer';
import Automate from '../components/automate/Automate';
import Leadership from '../components/leadership/Leadership'; 
import Implement from '../components/implement/Implement';
import {Helmet} from "react-helmet";

const Home = ({title}) => {
  return (
    <div >
   <Helmet>
    <meta charset="utf-8" />
    <title>Campusbeez |Campus Management Solutions,ERP Software,Resume Builder,Online Assesment software,University ERP,College ERP</title>
    <meta name="description" content="Elevate your institution's efficiency with CampusBeez,a comprehensive education management solution by LiveLake Education."/>
    <meta name="keywords" content="School Management,College Management,University Management,Education Technology,CampusBeez App,Resume Builder,Learning Management System,Biometric Attendance System,RFID Attendance System,Library Management,HR Management,LiveLake Education"/>
    <meta name="robots" content="index,follow"/>
    <meta name="copyright" content="Livelake Education"/>
    <meta name="email" content="support@campusbeez.com"/>
    <meta name="language" content="EN"/>
    <meta name="DC.Publisher" content="Campusbeez"/>
    <meta name="DC.Description" content="Campusbeez provides all in one solution of campus management"/>
    <meta name="DC.Language" content="en"/>
    <meta name="Distribution" content="Global"/>
    <meta name="geo.region" content="IN"/>
    <link rel="canonical" href="https://campusbeez.com/"/>
    <meta name="geo.placename" content="India"/>
    <meta name="geo.position" content="Gurgaon"/>
    <meta name="geo.region" content="usa"/>
    
    {/* Asynchronous script loading */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1X33E8KSP"></script>
    <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1X33E8KSP');
        `}
    </script>
</Helmet>

      <Herosection />
      {/* <Rating /> */}
      <Stastics />
      <Product />
      <Offer />
      <Automate />
      <Leadership />
      <Client />
      <Implement />
    </div>
  )
}

export default Home